import { SITEMAP_GETTER_API_KEY } from './env/server';
import config from './config';

const metadata = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      home: {
        /**
         * The page title used in the <title> tag, this provides SEO keywords for a given page
         * and is used in google analytics data provided through the GTMService.
         */
        title: undefined as unknown as string, // should be overridden by site
        /**
         * This is used as a 'description' in a meta tag in the head and is also used in google analytics
         * data provided through the GTMService. The description is not placed within the UI of the page but
         * is instead used to allow crawlers to identify the topics of the page.
         */
        description: undefined as unknown as string, // should be overridden by site
        /**
         * The URL of the site favicon.
         */
        faviconURL: undefined as unknown as string // should be overridden by site
      },

      about: {
        /**
         * The page title used in the <title> tag, this provides SEO keywords for a given page
         * and is used in google analytics data provided through the GTMService.
         */
        title: undefined as unknown as string, // should be overridden by site
        /**
         * This is used as a 'description' in a meta tag in the head and is also used in google analytics
         * data provided through the GTMService. The description is not placed within the UI of the page but
         * is instead used to allow crawlers to identify the topics of the page.
         */
        description: undefined as unknown as string, // should be overridden by site
        /**
         * The URL of the site favicon.
         */
        faviconURL: undefined as unknown as string // should be overridden by site
      },

      /** Settings related to the sitemap. */
      sitemap: {
        /**
         * The base URL of the API Gateway the Sitemap Getter is deployed on.
         */
        apiGatewayURL: 'https://36xt094zoe.execute-api.us-west-2.amazonaws.com',

        /** The version of the API to use. */
        apiGatewayVersion: 'prod',

        /** The endpoint Sitemap Getter is deployed on. */
        apiGatewayEndpoint: 'sitemap-getter',

        /** The API key to use with the Sitemap Getter API. */
        apiKey: SITEMAP_GETTER_API_KEY as string,

        /** The value to send on the `Cache-Control` header. */
        cacheControlHeaderValue:
          'public, max-age=86400, s-maxage=86400, stale-while-revalidate=86400'
      }
    }
  },
  'SANUK-US': {
    default: {
      home: {
        title: 'Sidewalk Surfers®, Sandals, Shoes, and More! | Sanuk®',
        description:
          'Get a head start on the journey to your happy place with Sanuk® sidewalker surfers, sandals, shoes, yoga slings, and flip flops!',
        faviconURL:
          'https://www.sanuk.com/on/demandware.static/Sites-SANUK-US-Site/-/default/dw29a0abd1/images/favicon.ico'
      }
    }
  },
  'AHNU-US': {
    default: {
      home: {
        title: 'AHNU® | Always Forward | Performance Footwear',
        description:
          'Discover AHNU®. Always forward. Performance footwear blending uncompromised design with precise engineering. Made for those who never slow down.'
      },
      about: {
        title: 'Discover AHNU: About Us | AHNU®',
        description:
          'AHNU®, from the minds of UGG and HOKA, offers footwear that combines performance with style, focusing on comfort, quality, and style.'
      }
    }
  },
  'TEVA-US': {
    default: {
      home: {
        title:
          'Fashionable Active Sandals, River Shoes, Boots, & More | Teva®',
        description:
          'Pioneered in 1984, Teva® is your guide for adventure from the everyday to the epic. Visit the Teva® Official Site for shoes, active sandals, hikers, boots, and more.'
      },
      about: {
        title: 'Discover TEVA: About Us | TEVA®',
        description:
          'TEVA®, from the minds of UGG and HOKA, offers footwear that combines performance with style, focusing on comfort, quality, and style.'
      }
    }
  },
  'UGG-COLLAB-US': {
    default: {
      home: {
        title:
          'UGG® Official | Boots, Slippers & Shoes | Free Shipping & Returns',
        description: `It's always UGG® season. Find the perfect boots, slippers, sneakers, and sandals to complete your look - from statement fluffy platforms to cozy house shoes, we have you covered.`
      },
      about: {
        title: 'Discover UGG: About Us | UGG®',
        description: `It's always UGG® season. Find the perfect boots, slippers, sneakers, and sandals to complete your look - from statement fluffy platforms to cozy house shoes, we have you covered.`
      }
    }
  },
  'UGG-US': {
    default: {
      home: {
        title:
          'UGG® Official | Boots, Slippers & Shoes | Free Shipping & Returns',
        description: `It's always UGG® season. Find the perfect boots, slippers, sneakers, and sandals to complete your look - from statement fluffy platforms to cozy house shoes, we have you covered.`
      },
      about: {
        title: 'Discover UGG: About Us | UGG®',
        description: `It's always UGG® season. Find the perfect boots, slippers, sneakers, and sandals to complete your look - from statement fluffy platforms to cozy house shoes, we have you covered.`
      }
    }
  },
  'KOOLABURRA-US': {
    default: {
      home: {
        title: 'Koolaburra by UGG® Official Site | Koolaburra.com',
        description: `Shop Koolaburra by UGG® for women's and kids' boots, shoes, slippers, and more in a variety of silhouettes, heights, and colors.`
      },
      about: {
        title: 'Koolaburra by UGG® Official Site | Koolaburra.com',
        description: `Shop Koolaburra by UGG® for women's and kids' boots, shoes, slippers, and more in a variety of silhouettes, heights, and colors.`
      }
    }
  },
  'HOKA-US': {
    default: {
      home: {
        title: 'Cushioned Running Shoes & Performance Wear | HOKA®',
        description: `Shop HOKA® for maximum cushion and minimal weight in our fitness, trail, hiking, and road running shoes. Finish the look in our performance wear. FLY HUMAN FLY™.`
      },
      about: {
        title: 'About the HOKA Brand | HOKA®',
        description: `HOKA's purpose is to inspire people to move. We create innovative and unexpected new solutions for athletes of all types using an &quot;always-on&quot; approach.`
      }
    }
  },
  'CONDUCTOR-US': {
    default: {
      home: {
        title: 'Welcome',
        description:
          'A Deckers internal only tool to generate an aggregate view of in-flight serverless operations & configure the various deployed builds.'
      }
    }
  }
});

export default metadata;
