import config from '../config';

/** Configuration for the builder.io integration service. */
const builderio = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** Is builder.io enabled for the current brand. */
      enabled: false,
      /** The brand specific public key for the Builder.io service. */
      builderPublicKey: undefined as unknown as string
    }
  },
  'AHNU-US': {
    default: {
      enabled: true,
      builderPublicKey: 'd6c45f14bc704bf490dba300da775f7c'
    }
  },
  'TEVA-US': {
    default: {
      enabled: true,
      builderPublicKey: 'd300f4d01af44c52b30e3621e314787e'
    }
  }
});

export default builderio;
