import LoggerService from '@/services/isomorphic/LoggerService';
import { exhaustiveFallback } from '@/utils/function-utils';
import type { FC } from 'react';
import type { ITopError } from '../ITopError';
import { RenderError } from '../RenderError';
import { RenderErrorAlert } from '../RenderErrorAlert';

interface ITopErrorAlertProps {
  /**
   * The top-level error to display an alert for.
   */
  topError: ITopError;
}

/**
 * Renders an error alert for a top-level error.
 * If the error type isn't supported, then nothing is rendered.
 */
export const TopErrorAlert: FC<ITopErrorAlertProps> = ({ topError }) => {
  const { error } = topError;

  if (error instanceof RenderError) {
    return <RenderErrorAlert topError={topError as ITopError<RenderError>} />;
  }

  return exhaustiveFallback(error, () => {
    LoggerService.error(`Encountered unsupported top error type.`);
    return null;
  });
};
