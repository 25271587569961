import { Locale } from '@/constructs/Locale';
import { DTO } from '@/type-utils';
import { AddressModel } from '../../Address';
import Model from '../../Model';
import IOrderShippingInfo from './IOrderShippingInfo';

/**
 * Represents the shipping information for an order.
 */
export default class OrderShippingInfoModel
  extends Model<DTO<IOrderShippingInfo>>
  implements IOrderShippingInfo
{
  /** @inheritdoc */
  public readonly firstName: string;
  /** @inheritdoc */
  public readonly lastName: string;
  /** @inheritdoc */
  public readonly email: string;
  /** @inheritdoc */
  public readonly phoneNumber: string;
  /** @inheritdoc */
  public readonly shippingMethod: string;
  /** @inheritdoc */
  public readonly country: Locale;
  /** @inheritdoc */
  public readonly address: AddressModel;

  /**
   * Creates a new order shipping info model.
   * @param data - The data for the model.
   */
  public constructor(data: DTO<IOrderShippingInfo> | IOrderShippingInfo) {
    super(data);

    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.phoneNumber = data.phoneNumber;
    this.shippingMethod = data.shippingMethod;
    this.country = data.country;
    this.address = AddressModel.from(data.address);
  }

  /** @inheritdoc */
  public override toDTO(): DTO<IOrderShippingInfo> {
    const dto: DTO<IOrderShippingInfo> = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phoneNumber: this.phoneNumber,
      shippingMethod: this.shippingMethod,
      country: this.country,
      address: this.address.toDTO()
    };

    return dto;
  }
}
