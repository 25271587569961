import { mocked } from '@/configs';

import { Constructor } from '@/type-utils';
import Service from '@/services/Service';

import MockService, { MockState, ServiceMock } from '../MockService';
import type { PersonalizationService } from './PersonalizationService';

const initialState = {};

/**
 * Mock implementation of the PersonalizationService class.
 */
class PersonalizationServiceMock extends ServiceMock<PersonalizationService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): PersonalizationService {
    return MockService.getMockOf(
      this.service
    ) as unknown as PersonalizationService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<PersonalizationService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(service: Service): void {
    const mockEnabled: boolean = mocked.PersonalizationService;

    MockService.mockService(
      mockEnabled,
      service,
      {
        getExperienceForCurrentUser: async () => {
          return Promise.resolve({
            decisionID: 'decision-testExperience-1',
            props: {}
          });
        },
        getExperiencesForCurrentUser: async () => {
          return Promise.resolve({
            decisionID: 'decision-testExperience-1',
            props: {}
          });
        }
      },
      {},
      this.state
    );
  }
}

export default PersonalizationServiceMock;
