'use client';

import { FC, PropsWithChildren, useCallback } from 'react';

import { useRouter } from 'next/router';
import { CommonErrorBoundary } from './CommonErrorBoundary';

/**
 * The root error boundary for the application. In production, it handles any rendering
 * errors not caught by descendant error boundaries by redirecting the user to the 503 page.
 */
export const RootErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
  const { pathname, push } = useRouter();

  const handleError = useCallback(() => {
    if (pathname !== '/503') {
      push('/503');
    }
  }, [push, pathname]);

  return (
    /**
     * We specify the pathname as the key to ensure that the fallback
     * is cleared once we navigate to the 503 page.
     */
    <CommonErrorBoundary key={pathname} onError={handleError} fallback={null}>
      {children}
    </CommonErrorBoundary>
  );
};
