import TaxClass from '@/services/models/TaxClass';
import { DTO } from '@/type-utils';
import { ICart } from '../../models/Cart';
import { ILineItem, LineItemType } from '../../models/Cart/LineItem';
import { Currency } from '../../models/Money';

export const MOCK_CART_ITEMS: Array<DTO<ILineItem>> = [
  {
    cartID: '2c21dd09-0a39-4fde-b133-d8867f455628',
    type: LineItemType.Product,
    upc: '191142311052',
    uuid: '0012ea21-0af8-4a95-9457-10aa6adb823b',
    sku: '194715361577',
    name: 'Rio Slide',
    quantity: 2,
    unitPrice: {
      retailPrice: {
        amount: '100',
        currency: Currency.USD
      }
    },
    subtotal: {
      amount: '100',
      currency: Currency.USD
    },
    tax: {
      amount: '10',
      currency: Currency.USD
    },
    taxRate: 0.1,
    total: {
      amount: '110',
      currency: Currency.USD
    },
    netTotal: {
      amount: '100',
      currency: Currency.USD
    },
    promotions: [],
    taxClass: TaxClass.ShoesAndLaces
  },
  {
    uuid: '0012ea21-0af8-4a95-9457-10aa6adb823b',
    upc: '197634237862',
    subtotal: {
      amount: '100',
      currency: Currency.USD
    },
    cartID: '2c21dd09-0a39-4fde-b133-d8867f455628',
    type: LineItemType.Product,
    promotions: [],
    sku: '197634237114',
    name: 'Yoga Salty Shimmer Metallic',
    quantity: 2,
    unitPrice: {
      retailPrice: {
        amount: '100',
        currency: Currency.USD
      }
    },
    tax: {
      amount: '100',
      currency: Currency.USD
    },
    taxRate: 0,
    total: {
      amount: '100',
      currency: Currency.USD
    },
    netTotal: {
      amount: '100',
      currency: Currency.USD
    },
    taxClass: TaxClass.ShoesAndLaces
  }
];

export const MOCK_CARTMODEL = {
  uuid: '2c21dd09-0a39-4fde-b133-d8867f455628',
  ownerID: '3e130a60-3c0c-4852-a23f-77884f22bae9',
  items: MOCK_CART_ITEMS,
  subtotal: {
    amount: '100',
    currency: Currency.USD
  },
  cartDiscount: {
    amount: '0',
    currency: Currency.USD
  },
  linesDiscount: {
    amount: '0',
    currency: Currency.USD
  },
  discount: {
    amount: '0',
    currency: Currency.USD
  },
  giftCards: [],
  coupons: [],
  tax: {
    amount: '10',
    currency: Currency.USD
  },
  total: {
    amount: '110',
    currency: Currency.USD
  },
  promotions: [],
  shippingCost: {
    amount: '0',
    currency: Currency.USD
  },
  selectedShippingMethod: {
    id: 'STG',
    uid: 't9ahyc85zyxa2vxixvzc9aug',
    country: 'US',
    brand: 'AHNU',
    name: 'Standard Shipping',
    description: 'Standard Shipping',
    shippingCost: {
      amount: '0',
      currency: Currency.USD
    },
    tax: {
      amount: '0',
      currency: Currency.USD
    },
    taxRate: 0,
    cutoffTime: ''
  },
  shipToAddress: null
} as DTO<ICart>;
