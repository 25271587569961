/**
 * When adding a new polyfill, make sure to also add it to the `polyfills`
 * array in '.eslintrc.js' so that ESLint doesn't complain about it.
 */
import 'core-js/stable/array/at'; // adds Array.prototype.at
import 'core-js/stable/array/find-last'; // adds Array.prototype.findLast
import 'core-js/stable/array/find-last-index'; // adds Array.prototype.findLastIndex
import 'core-js/stable/error/constructor'; // adds support for Error.cause
import 'core-js/stable/object/has-own'; // adds Object.hasOwn

