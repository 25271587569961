import { Nullable } from '@/type-utils';
import Service from '../../Service';

import { IPage, IProductListMetaData } from '../../models/Page';

/**
 * A page service that holds page meta data and is updated by the PageProvider.
 */
class PageService extends Service {
  private _page: Nullable<IPage>;

  /**
   * Gets the current page the user is on.
   * @returns A page object representing the current page.
   */
  public get page(): Nullable<IPage> {
    return this._page;
  }

  /**
   * Sets the page on the page service. It represents what page the user is on.
   * It should only be set once per page load.
   */
  public set page(value: Nullable<IPage>) {
    this._page = value;
  }

  /**
   * This allows the editing of metadata on the client side. Search
   * page meta data is not available until the search is made on the client.
   * @param metaData - The meta data supplied with the page, eg: Coveo
   * product data that is correlated with product tiles.
   */
  public updateMetaData(metaData: IProductListMetaData): void {
    if (this._page) {
      if (!this._page.productListMetadata) {
        this._page.productListMetadata = { coveoData: {} };
      }
      // TODO: When more third party metadata is added,
      // create system for manipulating individual objects.
      this._page.productListMetadata.coveoData = metaData.coveoData;
    }
  }
}

export default new PageService();
