import { siteCached } from '@/services/utils/siteCached/siteCached';
import axios, { AxiosInstance } from 'axios';

import { Nullable } from '@/type-utils';
import MemoryCache from '@/utils/MemoryCache';
import { encode } from 'querystring';
import Service from '../../Service';
import { DTOOfModel } from '../../models/Model';

import {
  ProductQuestionsModel,
  ReviewModel,
  ReviewsModel
} from '../../models/ReviewModel';
import { IRatings } from '../../models/ReviewsModel';
import type {
  IReviewsService,
  IReviewsServiceAuthorIdentity,
  ReviewsServiceCreateReviewObject,
  ReviewsServiceRetrieveReviewsOptions,
  ReviewsServiceVote
} from './IReviewsService';

/**
 * Client-side `ReviewsService`.
 * Sends requests to correct API routes to access to interact with the `ServerReviewsService`.
 */
export class ClientReviewsService extends Service implements IReviewsService {
  private client: AxiosInstance;
  private ratingCache = new MemoryCache<IRatings>();

  /** Constructs a new `ReviewsService`. */
  public constructor() {
    super();

    // We're working with `/api/reviews` and `/api/questions`
    this.client = axios.create({
      baseURL: '/api'
    });
  }

  /** @inheritdoc */
  public async createReview(
    sku: string,
    review: ReviewsServiceCreateReviewObject,
    author: IReviewsServiceAuthorIdentity
  ): Promise<void> {
    await this.client.post(`/reviews/${sku}`, {
      review,
      author
    });
  }

  /** @inheritdoc */
  public async retrieveReviews(
    sku: string,
    options?: ReviewsServiceRetrieveReviewsOptions
  ): Promise<ReviewsModel> {
    const encoded = encode({
      ...options
    });
    const query = encoded.length > 0 ? `?${encoded}` : '';

    const response = await this.client.get<DTOOfModel<ReviewsModel>>(
      `/reviews/${sku}${query}`
    );

    return ReviewsModel.from(response.data);
  }

  /** @inheritdoc */
  public async getReviewsForProduct(sku: string): Promise<ReviewsModel> {
    return this.retrieveReviews(sku);
  }

  /** @inheritdoc */
  public async voteOnReview(
    reviewID: ReviewModel['id'],
    vote: ReviewsServiceVote,
    shouldUndo = false
  ): Promise<void> {
    await this.client.post(`/reviews/vote`, {
      reviewID,
      vote,
      shouldUndo
    });
  }

  /** @inheritdoc */
  public async createQuestion(
    sku: string,
    question: string,
    author: IReviewsServiceAuthorIdentity
  ): Promise<void> {
    await this.client.post(`/questions/${sku}`, {
      question,
      author
    });
  }

  /** @inheritdoc */
  public async getQNAForProduct(sku: string): Promise<ProductQuestionsModel> {
    const response = await this.client.get<DTOOfModel<ProductQuestionsModel>>(
      `/questions/${sku}`
    );

    return ProductQuestionsModel.from(response.data);
  }

  /** @inheritdoc */
  public async createAnswer(
    questionID: number,
    answer: string,
    isPrivate?: boolean | undefined
  ): Promise<void> {
    await this.client.post(`/questions/create-answer`, {
      questionID,
      answer,
      isPrivate
    });
  }

  /** @inheritdoc */
  public async voteOnAnswer(
    answerID: number,
    vote: ReviewsServiceVote,
    shouldUndo = false
  ): Promise<void> {
    await this.client.post(`/questions/vote-on-answer`, {
      answerID,
      vote,
      shouldUndo
    });
  }

  /** @inheritdoc */
  @siteCached
  public async getRatingForProduct(
    modelId: string
  ): Promise<Nullable<IRatings>> {
    if (this.ratingCache.has(modelId)) {
      return this.ratingCache.get(modelId);
    }

    const { data } = await this.client.get<{ ratings: IRatings }>(
      `/ratings/${modelId}`
    );

    this.ratingCache.add(modelId, data.ratings);

    return data.ratings;
  }
}
