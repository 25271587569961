import config from './config';

/** General content configuration unrelated to the specific content service. */
const content = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** The main service used by the Content component. */
      service: 'builder',
      /** Whether preview is enabled or not. */
      enablePreview: { default: true, prod: false },
      /** 
       * The revalidation period for content, since this is used in static revalidation
       * using the pages router the value can never be 0. It would be false.
       */
      revalidate: { default: 1, prod: 7200 },
      /** 
       * The revalidation period for content used for a cache on the service. 
       * This has been made separate from the static revalidation since they have different
       * possible values. 
       * 
       * TODO: These should be combined as we move forward with the app router.
       */
      dynamicRevalidate: { default: 0, prod: 7200 },
      /** The image quality to use when setting content images. */
      imageQuality: 85,
      /** The video quality to use when setting content videos. */
      videoQuality: 90
    }
  }
});

export default content;
