import CartVM from '@/react/view-models/CartVM';
import { Nullable } from '@/type-utils';
import { UserModel } from '@/services/models/User';
import { createContext } from 'react';

/**
 * A global context that holds data that might be used by any page and could be shared
 * between components on any given page.
 */
export interface IGlobalContext {
  /**
   * The cart view model.
   */
  cart: CartVM;

  /**
   * The current user model since the last it was revalidated.
   * It will be null if the user has not yet stored a UserModel within this context.
   * Usually this means initial page load when first entering the site or on a full refresh.
   */
  user: Nullable<UserModel>;
}

export const GlobalContext = createContext<Nullable<IGlobalContext>>(null);
