import { Locale } from '@/constructs/Locale';
import { Province } from '@/constructs/provinces/Province';
import ISavedAddress from '../ISavedAddress';

export default {
  addressLine1: 'Bremen',
  firstName: 'User',
  lastName: 'McUserface',
  zipPostalCode: '1234',
  city: 'Bremen',
  stateProvince: 'Bremen' as unknown as Province,
  country: 'DE' as Locale,
  uuid: 'a4eaf16b-0f29-6ab5-5887-eaf68fb8298d',
  accountID: 'HKDEAQVCBPLYWM2023'
} as ISavedAddress;
