import { v4 as uuid } from 'uuid';

import { DTO, Nullable } from '@/type-utils';
import { TimeScale, dateFromTTL } from '@/utils/time-utils';

import {
  InvalidArgumentError,
  ResourceNotFoundError,
  ServerCodeInClientError
} from '@/utils/errors';
import { EnvironmentService } from '../../isomorphic/EnvironmentService';
import IMappable from '../../traits/IMappable';
import SessionEventCallback from './SessionEventCallback';

import Model from '../Model';

import ISessionData from './ISessionData';
import SessionDataRecord from './SessionDataRecord';

/** Represents an instance of a {@link ISessionData session data} object. */
export default class SessionDataModel
  extends Model<DTO<ISessionData>>
  implements ISessionData, IMappable<string>
{
  private eventListeners: Record<string, Record<string, SessionEventCallback>> =
    {};

  /** The actual data is stored in this underlying value. */
  private _data: SessionDataRecord;

  private _isSecure: boolean;

  /**
   * Constructs a new Session data model.
   * If secure is true, the data map becomes aware that the data it contains is
   * sensitive and relays that information to the {@link SessionService}.
   *
   * @param dto - The session data DTO to construct the model from.
   */
  public constructor(dto: DTO<ISessionData>) {
    super(dto);

    this._isSecure = dto.isSecure;
    this._data = dto.data;
  }

  /** @inheritdoc */
  public get isSecure(): boolean {
    return this._isSecure;
  }

  /** @inheritdoc */
  public update(dto: DTO<ISessionData>): void {
    if (this.isSecure !== dto.isSecure) {
      throw new InvalidArgumentError(
        'Cannot update a SessionDataModel using a DTO with a mismatching `isSecure` attribute.'
      );
    }

    // Preserve previous data to notify listeners.
    const previousData = this.data;

    this._data = dto.data;
    this.notifyListeners(previousData);
  }

  /**
   * Notify changes to subscribed event listeners.
   * @param previousData - The data record's state before the update.
   */
  private async notifyListeners(
    previousData: SessionDataRecord
  ): Promise<void> {
    for (const key of Object.keys(this.data)) {
      const previousValue = previousData[key]?.value;
      const newValue = this.data[key]?.value;

      // Notify subscribed event listeners of the change.
      if (this.eventListeners[key]) {
        for (const k of Object.keys(this.eventListeners[key])) {
          this.eventListeners[key][k](newValue, previousValue);
        }
      }
    }
  }

  /** Deletes expired items from the data record. */
  private validateData(): void {
    // For each key in the data record:
    for (const k of Object.keys(this._data)) {
      const { expiresOn } = this._data[k];

      // If the current time is greater than the expiration time of this entry...
      if (expiresOn && Date.now() >= expiresOn) {
        // ...delete it from the data record.
        delete this._data[k];
      }
    }
  }

  /**
   * Adds a listener that will run the supplied callback whenever the specified
   * key is changed.
   *
   * @param key - Key to listen changes for.
   * @param callback - Callback to run when changes occur.
   *
   * @returns A function that will delete the event listener when called.
   */
  public addEventListener(
    key: string,
    callback: SessionEventCallback
  ): () => void {
    if (!this.eventListeners[key]) {
      this.eventListeners[key] = {};
    }

    const listenerID = uuid();
    this.eventListeners[key][listenerID] = callback;

    return () => {
      delete this.eventListeners[key][listenerID];
    };
  }

  /** @inheritdoc */
  public get data(): SessionDataRecord {
    if (this.isSecure && !(typeof window === "undefined")) {
      throw new ServerCodeInClientError(
        'Acessing secure session data is not allowed in non-server environments.'
      );
    }

    // Always validate data before returning the undelying value.
    this.validateData();
    return this._data;
  }

  /** @inheritdoc */
  public has(key: string): boolean {
    // Has = key is present in the data record and has a value.
    return !!this.data[key]?.value;
  }

  /** @inheritdoc */
  public tryGet(key: string): Nullable<string> {
    return this.data[key]?.value ?? null;
  }

  /** @inheritdoc */
  public get(key: string): string {
    const value = this.data[key]?.value;

    if (value) {
      return value;
    }

    throw new ResourceNotFoundError(
      `Key "${key}" was not found in ${
        this.isSecure ? 'secure ' : ''
      }session data map.`
    );
  }

  /**
   * Sets session data. Cannot be called from the client side.
   *
   * @param key - The key to store the data at.
   * @param value - The value to store.
   * @param [ttl] - Time to live in seconds.
   *
   * @throws ServerCodeInClientError if called on a non-server environment.
   */
  public set(key: string, value: string, ttl = Infinity): void {
    if (!(typeof window === "undefined"))
      throw new ServerCodeInClientError(
        'Calling `SessionDataModel.set` is not allowed in non-server environments.'
      );

    const expirationDate = dateFromTTL(ttl, TimeScale.Seconds);

    this._data[key] = {
      value,
      expiresOn:
        expirationDate instanceof Date
          ? expirationDate.getTime()
          : expirationDate
    };
  }

  /**
   * Removes a value from session data.
   *
   * @param key - The key of the value to remove.
   * @throws A {@link ServerCodeInClientError} if called on a non-server environment.
   */
  public remove(key: string): void {
    if (!(typeof window === "undefined"))
      throw new ServerCodeInClientError(
        'Calling `SessionDataModel.set` is not allowed in non-server environments.'
      );

    delete this._data[key];
  }

  /** @inheritdoc */
  public toDTO(): DTO<ISessionData> {
    return {
      isSecure: this.isSecure,
      data: this.data
    };
  }

  /**
   * Creates an object that only contains the values of the stored data.
   * This means metadata such as expiration time would not be included.
   *
   * @returns A record containing only the data stored in this model.
   *
   * @example
   *
   * ```ts
   * // First, set some data.
   * sessionData.set("foo", "bar", 123456);
   *
   *
   * ```
   *
   * ```ts
   * // Calling `toDTO`:
   * sessionData.toDTO();
   *
   * // output:
   * ```
   * ```json
   * {
   *    "isSecure": false,
   *    "data": {
   *        "foo": {
   *            "value": "bar",
   *            "expiresOn": 123456
   *        }
   *  	}
   * }
   *
   * ```
   *
   * ```ts
   * // Calling `toKeyValuePairs`:
   * sessionData.toKeyValuePairs();
   *
   * // output:
   * ```
   * ```json
   * {
   *    "foo": "bar"
   * }
   *
   * ```
   */
  public toKeyValuePairs(): Record<string, string> {
    const dto: Record<string, string> = {};
    const { data } = this;

    for (const k of Object.keys(data)) {
      dto[k] = data[k].value;
    }

    return dto;
  }
}
