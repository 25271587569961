import { mocked } from '@/configs';

import { Constructor, DTO, Nullable } from '@/type-utils';

import { InvalidArgumentError, NotImplementedError } from '@/utils/errors';
import type { ICart } from '../../../models/Cart';
import type { ILineItem } from '../../../models/Cart/LineItem';
import type {
  ICartPromotionUpdates,
  IPromotion
} from '../../../models/Cart/Promotion';
import {
  ILineItemDiscount,
  ILineItemPromotion
} from '../../../models/Cart/Promotion/LineItem';
import MockService, { MockState, ServiceMock } from '../../MockService';

import type {
  ICartDiscount,
  ICartPromotion
} from '../../../models/Cart/Promotion/Cart';

import { CouponRejectionReason } from '../../../models/Cart/Coupon';

import { ICouponStatus, TalonOneCouponRejectionReason } from './Coupons';
import {
  ICustomerSessionCartItem,
  ICustomerSessionResponse,
  IRequestCustomerSession
} from './CustomerSession';
import { EffectType, IEffect } from './Effects';

import { TalonOneService } from './TalonOneService';

const initialState = {};

/**
 * Mock implementation of the TalonOneService class.
 */
class TalonOneServiceMock extends ServiceMock<TalonOneService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): TalonOneService {
    return MockService.getMockOf(TalonOneService) as TalonOneService;
  }

  /**
   * The constructor to initialize the mocks.
   */
  public constructor() {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(TalonOneService);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(
    service: Constructor<TalonOneService>
  ): void {
    const mockEnabled: boolean = mocked.TalonOneService;

    MockService.mockService(
      mockEnabled,
      service,
      {
        getCustomerSession: async (
          customerSessionId: string
        ): Promise<ICustomerSessionResponse> => {
          return {
            customerSession: {
              id: 375,
              created: '2023-04-06T20:51:42.354589Z',
              integrationId: '7eaea8ff-654a-4ccc-9fda-fc839e78dcb1',
              applicationId: 1,
              profileId: '',
              couponCodes: ['NEWYEAR20OFF', 'ZIGGY5', 'YOGAGORA2'],
              referralCode: '',
              state: 'open',
              cartItems: [
                {
                  name: 'Pair O Dice',
                  sku: '1013816-NAVY-06.5',
                  quantity: 1,
                  returnedQuantity: 0,
                  remainingQuantity: 1,
                  price: 45,
                  position: 0,
                  attributes: {
                    modelID: '1013816',
                    primaryCategory: ''
                  }
                },
                {
                  name: 'Ziggy',
                  sku: '1116734-TAN-09',
                  quantity: 1,
                  returnedQuantity: 0,
                  remainingQuantity: 1,
                  price: 35,
                  position: 1,
                  attributes: {
                    modelID: '1116734',
                    primaryCategory: ''
                  }
                },
                {
                  name: 'Yoga Gora',
                  sku: '1113694-PYT-05',
                  quantity: 2,
                  returnedQuantity: 0,
                  remainingQuantity: 2,
                  price: 35,
                  position: 2,
                  attributes: {
                    modelID: '1113694',
                    primaryCategory: ''
                  }
                }
              ] as Array<ICustomerSessionCartItem>,
              attributes: {},
              firstSession: true,
              total: 150,
              cartItemTotal: 0,
              additionalCostTotal: 0,
              updated: '2023-04-06T20:55:27.630338Z'
            },
            effects: [
              {
                campaignId: 2,
                rulesetId: 43,
                ruleIndex: 0,
                ruleName: 'Coupon Code',
                effectType: 'acceptCoupon' as EffectType,
                triggeredByCoupon: 7,
                props: {
                  value: 'NEWYEAR20OFF'
                }
              },
              {
                campaignId: 2,
                rulesetId: 43,
                ruleIndex: 0,
                ruleName: 'Coupon Code',
                effectType: 'setDiscount' as EffectType,
                triggeredByCoupon: 7,
                props: {
                  name: '$20 off\t',
                  value: 20
                }
              },
              {
                campaignId: 14,
                rulesetId: 67,
                ruleIndex: 0,
                ruleName: 'Ziggy 5',
                effectType: 'acceptCoupon' as EffectType,
                triggeredByCoupon: 10,
                props: {
                  value: 'ZIGGY5'
                }
              },
              {
                campaignId: 14,
                rulesetId: 67,
                ruleIndex: 0,
                ruleName: 'Ziggy 5',
                effectType: 'setDiscountPerItem' as EffectType,
                triggeredByCoupon: 10,
                props: {
                  name: '$5 off total when adding at least one Ziggy (code ZIGGY5)#1',
                  value: 5.0,
                  position: 1,
                  subPosition: 0,
                  totalDiscount: 5
                }
              },
              {
                campaignId: 14,
                rulesetId: 67,
                ruleIndex: 1,
                ruleName: 'Yoga Gora 2',
                effectType: 'acceptCoupon' as EffectType,
                triggeredByCoupon: 12,
                props: {
                  value: 'YOGAGORA2'
                }
              },
              {
                campaignId: 14,
                rulesetId: 67,
                ruleIndex: 1,
                ruleName: 'Yoga Gora 2',
                effectType: 'rejectCoupon' as EffectType,
                triggeredByCoupon: 10,
                props: {
                  value: 'ZIGGY5',
                  rejectionReason: 'CouponRejectedByCondition',
                  conditionIndex: 1
                }
              },
              {
                campaignId: 14,
                rulesetId: 67,
                ruleIndex: 0,
                ruleName: 'Ziggy 5',
                effectType: 'rejectCoupon' as EffectType,
                triggeredByCoupon: 12,
                props: {
                  value: 'YOGAGORA2',
                  rejectionReason: 'CouponRejectedByCondition',
                  conditionIndex: 1
                }
              },
              {
                campaignId: 14,
                rulesetId: 67,
                ruleIndex: 1,
                ruleName: 'Yoga Gora 2',
                effectType: 'setDiscountPerItem' as EffectType,
                triggeredByCoupon: 12,
                props: {
                  name: '$2 off each Yoga Gora (code YOGAGORA2)#2',
                  value: 2,
                  position: 2,
                  subPosition: 0
                }
              },
              {
                campaignId: 14,
                rulesetId: 67,
                ruleIndex: 1,
                ruleName: 'Yoga Gora 2',
                effectType: 'setDiscountPerItem' as EffectType,
                triggeredByCoupon: 12,
                props: {
                  name: '$2 off each Yoga Gora (code YOGAGORA2)#2',
                  value: 2,
                  position: 2,
                  subPosition: 1
                }
              }
            ]
          };
        },

        updateCustomerSession: async (
          customerSessionId: string,
          customerSession: IRequestCustomerSession,
          dry = false,
          includeCustomerSession = false
        ): Promise<ICustomerSessionResponse> => {
          return {
            createdCoupons: [],
            createdReferrals: [],
            effects: [
              {
                campaignId: 2,
                rulesetId: 43,
                ruleIndex: 0,
                ruleName: 'Coupon Code',
                effectType: 'acceptCoupon' as EffectType,
                triggeredByCoupon: 7,
                props: {
                  value: 'NEWYEAR20OFF'
                }
              },
              {
                campaignId: 2,
                rulesetId: 43,
                ruleIndex: 0,
                ruleName: 'Coupon Code',
                effectType: 'setDiscount' as EffectType,
                triggeredByCoupon: 7,
                props: {
                  name: '$20 off\t',
                  value: 20
                }
              },
              {
                campaignId: 14,
                rulesetId: 67,
                ruleIndex: 0,
                ruleName: 'Ziggy 5',
                effectType: 'acceptCoupon' as EffectType,
                triggeredByCoupon: 10,
                props: {
                  value: 'ZIGGY5'
                }
              },
              {
                campaignId: 14,
                rulesetId: 67,
                ruleIndex: 0,
                ruleName: 'Ziggy 5',
                effectType: 'setDiscountPerItem' as EffectType,
                triggeredByCoupon: 10,
                props: {
                  name: '$5 off total when adding at least one Ziggy (code ZIGGY5)#1',
                  value: 5,
                  position: 1,
                  subPosition: 0,
                  totalDiscount: 5
                }
              },
              {
                campaignId: 14,
                rulesetId: 67,
                ruleIndex: 1,
                ruleName: 'Yoga Gora 2',
                effectType: 'acceptCoupon' as EffectType,
                triggeredByCoupon: 12,
                props: {
                  value: 'YOGAGORA2'
                }
              },
              {
                campaignId: 14,
                rulesetId: 67,
                ruleIndex: 1,
                ruleName: 'Yoga Gora 2',
                effectType: 'rejectCoupon' as EffectType,
                triggeredByCoupon: 10,
                props: {
                  value: 'ZIGGY5',
                  rejectionReason: 'CouponRejectedByCondition',
                  conditionIndex: 1
                }
              },
              {
                campaignId: 14,
                rulesetId: 67,
                ruleIndex: 0,
                ruleName: 'Ziggy 5',
                effectType: 'rejectCoupon' as EffectType,
                triggeredByCoupon: 12,
                props: {
                  value: 'YOGAGORA2',
                  rejectionReason: 'CouponRejectedByCondition',
                  conditionIndex: 1
                }
              },
              {
                campaignId: 14,
                rulesetId: 67,
                ruleIndex: 1,
                ruleName: 'Yoga Gora 2',
                effectType: 'setDiscountPerItem' as EffectType,
                triggeredByCoupon: 12,
                props: {
                  name: '$2 off each Yoga Gora (code YOGAGORA2)#2',
                  value: 2,
                  position: 2,
                  subPosition: 0
                }
              },
              {
                campaignId: 14,
                rulesetId: 67,
                ruleIndex: 1,
                ruleName: 'Yoga Gora 2',
                effectType: 'setDiscountPerItem' as EffectType,
                triggeredByCoupon: 12,
                props: {
                  name: '$2 off each Yoga Gora (code YOGAGORA2)#2',
                  value: 2,
                  position: 2,
                  subPosition: 1
                }
              }
            ] as Array<IEffect>
          } as ICustomerSessionResponse;
        },

        lineItemToCustomerSessionItem: (
          item: ILineItem,
          options?: {
            overridePrice: boolean;
          }
        ): ICustomerSessionCartItem => {
          return {
            name: 'Yoga Gora',
            sku: '1113694-PYT-05',
            quantity: 2,
            price: 35,

            attributes: {
              modelID: '1113694',
              primaryCategory: ''
            }
          };
        },

        // customerSessionItemToLineItem: (
        //   item: ICustomerSessionCartItem,
        //   cartID: string
        // ): ILineItem => {
        //   return {
        //     uuid: 'bf61e21e-fd44-4fb6-8aee-4e3c21d14d61',
        //     cartID: '7eaea8ff-654a-4ccc-9fda-fc839e78dcb1',
        //     name: 'Yoga Gora',
        //     sku: '1113694-PYT-05',
        //     quantity: 2,
        //     type: 'Product',
        //     image: null,
        //     unitPrice: {
        //       retailPrice: {
        //         amount: '35',
        //         currency: 'USD'
        //       }
        //     },
        //     promotions: [],
        //     subtotal: {
        //       amount: '70',
        //       currency: 'USD'
        //     },
        //     total: {
        //       amount: '70',
        //       currency: 'USD'
        //     }
        //   } as ILineItem;
        // },

        cartToCustomerSession: (cart: DTO<ICart>): IRequestCustomerSession => {
          return {
            couponCodes: ['NEWYEAR20OFF', 'ZIGGY5', 'YOGAGORA2'],
            state: 'open',
            cartItems: [
              {
                name: 'Pair O Dice',
                sku: '1013816-NAVY-06.5',
                quantity: 1,
                price: 45,
                position: 0,

                attributes: {
                  modelID: '1013816',
                  primaryCategory: ''
                }
              },
              {
                name: 'Ziggy',
                sku: '1116734-TAN-09',
                quantity: 3,
                price: 35,
                position: 1,

                attributes: {
                  modelID: '1116734',
                  primaryCategory: ''
                }
              },
              {
                name: 'Yoga Gora',
                sku: '1113694-PYT-05',
                quantity: 2,
                price: 35,
                position: 2,

                attributes: {
                  modelID: '1113694',
                  primaryCategory: ''
                }
              }
            ]
          };
        },

        cartUpdatesFromCustomerSessionResponse: (
          response: ICustomerSessionResponse,
          cartItems?: Array<ICustomerSessionCartItem>
        ): ICartPromotionUpdates => {
          return {
            coupons: [
              {
                code: 'NEWYEAR20OFF'
              },
              {
                code: 'ZIGGY5'
              },
              { code: 'YOGAGORA2', id: '3' }
            ],
            cartPromotions: [
              {
                target: 0,
                type: 1,
                label: '$20 off\t',
                visible: true,
                applyTo: 'total',
                mode: 0,
                value: 20
              } as ICartDiscount
            ],
            lineItemPromotions: {
              '1013816-NAVY-06.5': [],
              '1116734-TAN-09': [
                {
                  target: 1,
                  type: 0,
                  label:
                    '$5 off total when adding at least one Ziggy (code ZIGGY5)',
                  visible: true,
                  apply: 'once',
                  mode: 0,
                  value: 1.67
                } as ILineItemDiscount,
                {
                  target: 1,
                  type: 0,
                  label:
                    '$5 off total when adding at least one Ziggy (code ZIGGY5)',
                  visible: true,
                  apply: 'once',
                  mode: 0,
                  value: 1.67
                } as ILineItemDiscount,
                {
                  target: 1,
                  type: 0,
                  label:
                    '$5 off total when adding at least one Ziggy (code ZIGGY5)',
                  visible: true,
                  apply: 'once',
                  mode: 0,
                  value: 1.66
                } as ILineItemDiscount
              ],
              '1113694-PYT-05': [
                {
                  target: 1,
                  type: 0,
                  label: '$2 off each Yoga Gora (code YOGAGORA2)',
                  visible: true,
                  apply: 'once',
                  mode: 0,
                  value: 2
                } as ILineItemDiscount,
                {
                  target: 1,
                  type: 0,
                  label: '$2 off each Yoga Gora (code YOGAGORA2)',
                  visible: true,
                  apply: 'once',
                  mode: 0,
                  value: 2
                } as ILineItemDiscount
              ]
            }
          } as ICartPromotionUpdates;
        },

        cartPromosFromCustomerSessionResponse: (
          response: ICustomerSessionResponse
        ): Array<ICartPromotion> => {
          return [
            {
              name: '$20 off',
              id: '0',
              campaignID: '0',
              target: 0,
              type: 1,
              label: '$20 off\t',
              visible: true,
              applyTo: 'total',
              mode: 0,
              value: 20
            } as ICartPromotion
          ];
        },

        lineItemPromosFromCustomerSessionResponse: (
          response: ICustomerSessionResponse,
          cartItems?: Array<ICustomerSessionCartItem>
        ): Record<string, Array<ILineItemPromotion>> => {
          return {
            '1013816-NAVY-06.5': [],
            '1116734-TAN-09': [
              {
                target: 1,
                type: 0,
                label:
                  '$5 off total when adding at least one Ziggy (code ZIGGY5)',
                visible: true,
                apply: 'once',
                mode: 0,
                value: 1.67
              },
              {
                target: 1,
                type: 0,
                label:
                  '$5 off total when adding at least one Ziggy (code ZIGGY5)',
                visible: true,
                apply: 'once',
                mode: 0,
                value: 1.67
              },
              {
                target: 1,
                type: 0,
                label:
                  '$5 off total when adding at least one Ziggy (code ZIGGY5)',
                visible: true,
                apply: 'once',
                mode: 0,
                value: 1.66
              }
            ] as Array<ILineItemDiscount>,
            '1113694-PYT-05': [
              {
                target: 1,
                type: 0,
                label: '$2 off each Yoga Gora (code YOGAGORA2)',
                visible: true,
                apply: 'once',
                mode: 0,
                value: 2
              },
              {
                target: 1,
                type: 0,
                label: '$2 off each Yoga Gora (code YOGAGORA2)',
                visible: true,
                apply: 'once',
                mode: 0,
                value: 2
              }
            ] as Array<ILineItemDiscount>
          } as Record<string, Array<ILineItemPromotion>>;
        },

        couponStatusFromCustomerSessionResponse: (
          response: ICustomerSessionResponse
        ): ICouponStatus => {
          return {
            rejectedCoupons: [],
            acceptedCoupons: [
              {
                code: 'NEWYEAR20OFF'
              },
              {
                code: 'ZIGGY5'
              },
              {
                code: 'YOGAGORA2'
              }
            ]
          };
        },

        // @ts-expect-error -- It's complaining due to the method overloads, but this signature is correct.
        effectToPromotion: <T extends EffectType>(
          effect: IEffect<T>,
          response: ICustomerSessionResponse
        ): Nullable<IPromotion> => {
          if (effect?.effectType === EffectType.SetDiscountPerItem) {
            return {
              target: 1,
              type: 0,
              label:
                '$5 off total when adding at least one Ziggy (code ZIGGY5)',
              visible: true,
              apply: 'once',
              mode: 0,
              value: 2.5
            } as ILineItemDiscount;
          }

          return {
            target: 0,
            type: 1,
            label: '$20 off\t',
            visible: true,
            applyTo: 'total',
            mode: 0,
            value: 20
          } as ICartDiscount;
        },

        getCouponRejectionReason: (
          talonRejectionReason: TalonOneCouponRejectionReason
        ): CouponRejectionReason => {
          switch (talonRejectionReason) {
            case TalonOneCouponRejectionReason.CouponExpired:
              return CouponRejectionReason.CouponExpired;

            case TalonOneCouponRejectionReason.CouponLimitReached:
              return CouponRejectionReason.CouponLimitReached;

            case TalonOneCouponRejectionReason.CouponNotFound:
              return CouponRejectionReason.CouponNotFound;

            case TalonOneCouponRejectionReason.CouponRecipientDoesNotMatch:
            case TalonOneCouponRejectionReason.CouponRejectedByCondition:
            case TalonOneCouponRejectionReason.EffectCouldNotBeApplied:
            case TalonOneCouponRejectionReason.ProfileLimitReached:
            case TalonOneCouponRejectionReason.CouponPartOfNotTriggeredCampaign:
            case TalonOneCouponRejectionReason.CouponReservationRequired:
            case TalonOneCouponRejectionReason.ProfileRequired:
              return CouponRejectionReason.CouponNotApplicable;

            case TalonOneCouponRejectionReason.CouponPartOfNotRunningCampaign:
            case TalonOneCouponRejectionReason.CouponStartDateInFuture:
              return CouponRejectionReason.CouponNotActive;

            default: {
              if (
                Object.values(TalonOneCouponRejectionReason).includes(
                  talonRejectionReason
                )
              ) {
                // This is a valid Talon rejection reason, but it isn't mapped to
                // a first party rejection reason.
                throw new NotImplementedError(
                  `No first-party coupon rejection reason matches Talon.one reason "${talonRejectionReason}".`
                );
              }

              throw new InvalidArgumentError(
                `Cannot get coupon rejection reason: Invalid Talon.one reason "${talonRejectionReason}" provided.`
              );
            }
          }
        }
      },
      {},
      this.state
    );
  }
}

export default TalonOneServiceMock;
