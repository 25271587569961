/**
 * @file Contains some helper functions for working with arrays.
 */
import { Primitive } from '@/type-utils';
import { InvalidArgumentError } from './errors';

/**
 * Checks if two primitive arrays are equal.
 * ONLY USED FOR PRIMITIVES!
 *
 * @param arr1 - Array 1.
 * @param arr2 - Array 2.
 * @returns A boolean indicating if the two arrays are identical.
 */
export const arrayEquals = (
  arr1: ReadonlyArray<Primitive>,
  arr2: ReadonlyArray<Primitive>
): boolean => {
  // If both reference the same array.
  if (arr1 === arr2) {
    return true;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every((value, index) => arr2[index] === value);
};

/**
 * Zips two arrays together.
 * @param arr1 - The first array to zip.
 * @param arr2 - The second array to zip.
 * @param strict - If true, the zipped array will be the length of the shortest array.
 * @returns An array of tuples, where each tuple contains the corresponding elements from the two arrays.
 *
 * Partially stolen from {@link https://gist.github.com/dev-thalizao/affaac253be5b5305e0faec3b650ba27 dev-thalizao on GitHub Gist}.
 */
export function zip<T1, T2>(
  arr1: ReadonlyArray<T1>,
  arr2: ReadonlyArray<T2>,
  strict: boolean = false
): Array<[T1, T2]> {
  const length = strict
    ? Math.min(arr1.length, arr2.length)
    : Math.max(arr1.length, arr2.length);
  const zipped: Array<[T1, T2]> = [];

  for (let index = 0; index < length; index++) {
    zipped.push([arr1[index], arr2[index]]);
  }

  return zipped;
}

/**
 * Given an array, split it into several arrays (chunks) of the specified
 * size.
 *
 * @param array - The array to split.
 * @param chunkSize - The size of each resulting chunk.
 * If the original array's length is not divisible by the specified chunk
 * size, the last chunk will only contain the remaining items.
 *
 * @returns An array with all the chunks split from the original array.
 * @throws An {@link InvalidArgumentError} if an invalid chunk size is provided.
 */
export const chunkify = <T>(
  array: ReadonlyArray<T>,
  chunkSize: number
): Array<Array<T>> => {
  if (chunkSize === 0) {
    throw new InvalidArgumentError('Cannot chunkify array with chunkSize = 0.');
  }

  const chunks: Array<Array<T>> = [];

  // Kudos to https://stackoverflow.com/questions/8495687/split-array-into-chunks
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }

  return chunks;
};

/**
 * Given an iterable, return a new array with only the unique elements.
 * The first occurrence of each element is kept, meaning that the relative order
 * of the elements is preserved in the result.
 *
 * Value equality is determined by the "SameValueZero" algorithm.
 *
 * @param iter - The iterable to filter duplicates from.
 * @returns A new array with only the unique elements.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Equality_comparisons_and_sameness#same-value-zero_equality SameValueZero algorithm}
 * @example
 * unique([1, 2, 3, 1, 2, 4, 4, 5, 6]) // [1, 2, 3, 4, 5, 6, 7]
 */
export const unique = <T>(iter: Iterable<T>): Array<T> => {
  return Array.from(new Set(iter));
};

/**
 * Given an iterable, return a new array with only the unique (by value) elements.
 * The first occurrence of each element is kept, meaning that the relative order
 * of the elements is preserved in the result.
 *
 * By default, the uniqueness of an element is determined by its intrinsic value.
 * However, you can provide a custom function to compute the value for an element.
 * Value equality is determined by the "SameValueZero" algorithm.
 *
 * @param iter - The iterable to filter duplicates from.
 * @param [getValue] - A function that returns the value to use for comparison.
 * If not provided, the element itself is used.
 * @returns A new array with only the unique elements.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Equality_comparisons_and_sameness#same-value-zero_equality SameValueZero algorithm}
 * @example
 * uniqueBy([6, 5, 4, 3, 2, 1], (x) => x % 3) // [6, 5, 4]
 */
export const uniqueBy = <T>(
  iter: Iterable<T>,
  getValue?: (elt: T) => unknown
): Array<T> => {
  if (!getValue) {
    return Array.from(new Set(iter));
  }

  const result = new Array<T>();
  const set = new Set<unknown>();
  for (const elt of iter) {
    const value = getValue(elt);
    if (!set.has(value)) {
      set.add(value);
      result.push(elt);
    }
  }

  return result;
};

/**
 * Determines if two arrays have the same members, regardless of order.
 *
 * Value equality is determined by the "SameValueZero" algorithm.
 *
 * @param arr1 - The first array.
 * @param arr2 - The second array.
 * @returns A boolean indicating if the two arrays have the same members.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Equality_comparisons_and_sameness#same-value-zero_equality SameValueZero algorithm}
 * @example sameMembers([1, 2, 3], [3, 1, 2]) // true
 */
export const sameMembers = <T>(
  arr1: ReadonlyArray<T>,
  arr2: ReadonlyArray<T>
): boolean => {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);
  return (
    arr1.every((item) => set2.has(item)) && arr2.every((item) => set1.has(item))
  );
};

/**
 * Computes the difference between two arrays, regardless of order.
 *
 * Elements are deduplicated in the result by their value.
 * Value equality is determined by the "SameValueZero" algorithm.
 *
 * @param arr1 - The first array.
 * @param arr2 - The second array.
 * @returns A 3-tuple where the first element contains the items
 * that are in `arr1` but not in `arr2`, and the second element
 * contains the items that are in `arr2` but not in `arr1`, and
 * the third element contains the items that are in both arrays.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Equality_comparisons_and_sameness#same-value-zero_equality SameValueZero algorithm}
 * @example difference([1, 2, 3], [3, 4, 5]) // [[1, 2], [4, 5], [3]]
 */
export const difference = <T>(
  arr1: ReadonlyArray<T>,
  arr2: ReadonlyArray<T>
): [Array<T>, Array<T>, Array<T>] => {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  arr1 = Array.from(set1);
  arr2 = Array.from(set2);

  const diff1 = arr1.filter((item) => !set2.has(item));
  const diff2 = arr2.filter((item) => !set1.has(item));
  const common = arr1.filter((item) => set2.has(item));

  return [diff1, diff2, common];
};

/**
 * A function which returns a separator to insert between elements in an array.
 * @param index - The index of the previous element in the given array.
 * @param newIndex - The index of the separator in the new array.
 * @param arr - A reference to the original array.
 */
export type SeparatorFn<T, U> = (
  index: number,
  newIndex: number,
  arr: Array<T>
) => U;

/**
 * Inserts a separator between each element in the array.
 * @param arr - The array to insert the separator into.
 * @param separatorFn - A function which returns the separator to insert between elements.
 * @returns A new array with the separator inserted between each element.
 */
export const insertBetween = <T, U>(
  arr: Array<T>,
  separatorFn: SeparatorFn<T, U>
): Array<T | U> => {
  return arr.reduce<Array<T | U>>((acc, curr, index) => {
    acc.push(curr);

    if (index !== arr.length - 1) {
      acc.push(separatorFn(index, acc.length, arr));
    }

    return acc;
  }, []);
};

/**
 * Convenience function to pick a specific key from each object in an array.
 *
 * @param arr - The array of objects.
 * @param key - The key to pick from each object.
 * @returns An array of the values of the specified key from each object.
 * @example
 * const arr = [{ id: 1, name: 'Alice' }, { id: 2, name: 'Bob' }];
 * pickMap(arr, 'name') // ['Alice', 'Bob']
 */
export const pickMap = <T extends object, K extends keyof T>(
  arr: ReadonlyArray<T>,
  key: K
): Array<T[K]> => {
  return arr.map((item) => item[key]);
};
