import { DTO, Nullable } from '@/type-utils';
import { Brand } from '../../isomorphic/EnvironmentService';
import { LangLocale } from '../../isomorphic/I18NService';
import type { IPageEncryption } from '../../serverless/integrations/SafetechService';

import { AddressModel } from '../Address';
import { ICartPromotion } from '../Cart/Promotion/Cart';
import Model from '../Model';
import { MoneyModel } from '../Money';
import { IShippingMethod } from '../ShippingMethod';
import { ICustomer } from './ICustomer';
import { IOrderID } from './IOrderID';
import { IOrderLine } from './IOrderLine';
import type { IPlacedOrder } from './IPlacedOrder';

/**
 * Represents a recently placed order; effectively
 * a summary of the order just placed.
 *
 * This model should not contain user-sensitive or
 * order-preparatory information, such as payment details
 * or fraud responses.
 */
export default class PlacedOrderModel
  extends Model<DTO<IPlacedOrder>>
  implements IPlacedOrder
{
  /** @inheritDoc */
  public readonly orderID: IOrderID;
  /** @inheritDoc */
  public readonly orderToken: string;
  /** @inheritDoc */
  public readonly amount: MoneyModel;
  /** @inheritDoc */
  public readonly subtotal: MoneyModel;
  /** @inheritDoc */
  public readonly tax: MoneyModel;
  /** @inheritDoc */
  public readonly shippingCost: MoneyModel;
  /** @inheritDoc */
  public readonly discount: MoneyModel;
  /** @inheritDoc */
  public readonly locale: LangLocale;
  /** @inheritDoc */
  public readonly shippingAddress: AddressModel;
  /** @inheritDoc */
  public readonly billingAddress: AddressModel;
  /** @inheritDoc */
  public readonly lines: ReadonlyArray<IOrderLine>;
  /** @inheritDoc */
  public readonly promotions: ReadonlyArray<ICartPromotion>;
  /** @inheritDoc */
  public readonly shippingMethod: IShippingMethod;
  /** @inheritDoc */
  public readonly brand: Brand;
  /** @inheritDoc */
  public readonly pageEncryption: Nullable<IPageEncryption>;
  /** @inheritDoc */
  public readonly customer: ICustomer;

  /**
   * Builds an Order Model from an Order representation.
   * @param order - A Order Representation.
   */
  public constructor(order: DTO<IPlacedOrder>) {
    super(order);
    this.orderID = order.orderID;
    this.orderToken = order.orderToken;
    this.amount = MoneyModel.from(order.amount);
    this.subtotal = MoneyModel.from(order.subtotal);
    this.shippingCost = MoneyModel.from(order.shippingCost);
    this.tax = MoneyModel.from(order.tax);
    this.discount = MoneyModel.from(order.discount);
    this.locale = order.locale;

    this.shippingAddress = AddressModel.from(order.shippingAddress);
    this.billingAddress = AddressModel.from(order.billingAddress);
    this.lines = order.lines;
    this.promotions = order.promotions;

    this.shippingMethod = order.shippingMethod;
    this.brand = order.brand;
    this.customer = order.customer;
  }

  /**
   * Gets the shipping code off the shipments array. When placing an order
   * there should only ever be one shipping method, but in other post place
   * statuses this may change.
   *
   * @returns A string representing the shipping code.
   * @throws When there is no shipment at all.
   */
  public get shippingCode(): string {
    return this.shippingMethod.id;
  }

  /**
   * Creates a DTO representation of this Model.
   * @returns A DTO representation of this Order Model.
   */
  public toDTO(): DTO<IPlacedOrder> {
    const dto: DTO<IPlacedOrder> = {
      orderID: this.orderID ?? null,
      orderToken: this.orderToken ?? null,
      amount: this.amount.toDTO(),
      subtotal: this.subtotal.toDTO(),
      shippingCost: this.shippingCost.toDTO(),
      tax: this.tax.toDTO(),
      discount: this.discount.toDTO(),
      locale: this.locale,

      shippingAddress: this.shippingAddress.toDTO(),
      billingAddress: this.billingAddress.toDTO(),
      lines: this.lines,
      promotions: this.promotions,

      shippingMethod: this.shippingMethod,
      brand: this.brand,
      customer: this.customer
    };

    return dto;
  }
}
