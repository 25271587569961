import { CookieSerializeOptions, serialize } from 'cookie';

import { timeStampableToDate } from '@/utils/time-utils';

import { DTO } from '@/type-utils';
import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';
import { EnvironmentService } from '../../isomorphic/EnvironmentService';

import Model from '../Model';
import { ICookie, SameSite } from './ICookie';

/**
 * @inheritdoc
 *
 * A model for manipulating single cookie instances.
 */
export default class CookieModel
  extends Model<DTO<ICookie>>
  implements ICookie
{
  /** @inheritdoc */
  public key: string;

  /** @inheritdoc */
  public value: string;

  /** @inheritdoc */
  public path?: string;

  /** @inheritdoc */
  public domain?: string;

  /** @inheritdoc */
  public expires?: Date;

  /** @inheritdoc */
  public maxAge?: number;

  /** @inheritdoc */
  public secure?: boolean;

  /** @inheritdoc */
  public httpOnly?: boolean;

  /** @inheritdoc */
  public sameSite?: SameSite;

  /**
   * @inheritdoc
   * @param cookie - An {@link ICookie} DTO.
   */
  public constructor(cookie: DTO<ICookie>) {
    super(cookie);

    this.key = cookie.key;
    this.value = cookie.value;
    this.path = cookie.path;
    this.domain = cookie.domain;
    this.maxAge = cookie.maxAge;
    this.secure = cookie.secure;
    this.httpOnly = cookie.httpOnly;
    this.sameSite = cookie.sameSite;

    if (cookie.expires) this.expires = new Date(cookie.expires);

    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    // Cookies with SameSite=None must now also specify the Secure attribute (they require a secure context/HTTPS).
    if (this.sameSite === SameSite.None && !this.secure) {
      throw new InvalidArgumentError(
        'Cookies with SameSite=None must now also explicitly specify the Secure attribute.'
      );
    }
  }

  /** @inheritdoc */
  public toString(): string {
    return this.value;
  }

  /** @inheritdoc */
  public toRawString(): string {
    // Key, value, and all the cookie attributes passed with a DTO.
    return serialize(this.key, this.value, this.toSerializableDTO());
  }

  /** @inheritdoc */
  public asNumber(): number {
    const number = Number(this.value);
    if (Number.isNaN(number)) {
      throw new Error(
        `CookieModel.asNumber() called on a non-numeric cookie.${
          (process.env.NEXT_PUBLIC_APP_ENV === "dev") ? ` Cookie: ${this.value}` : ''
        }`
      );
    }

    return number;
  }

  /** @inheritdoc */
  public asBoolean(): boolean {
    if (this.value === 'true') {
      return true;
    }

    if (this.value === 'false') {
      return false;
    }

    throw new Error(
      `CookieModel.asBoolean() called on a non-boolean cookie. Boolean cookies can only be 'true' or 'false'.${
        (process.env.NEXT_PUBLIC_APP_ENV === "dev") ? ` Cookie: ${this.value}` : ''
      }`
    );
  }

  /** @inheritdoc */
  public toDTO(): DTO<ICookie> {
    return {
      key: this.key,
      value: this.value,
      path: this.path,
      domain: this.domain,
      maxAge: this.maxAge,
      secure: this.secure,
      httpOnly: this.httpOnly,
      sameSite: this.sameSite,

      ...(this.expires && { expires: this.expires.toISOString() })
    };
  }

  /** @returns A special DTO meant to be used as a {@link CookieSerializeOptions}.  */
  public toSerializableDTO(): CookieSerializeOptions {
    return {
      path: this.path,
      domain: this.domain,
      maxAge: this.maxAge,
      secure: this.secure,
      httpOnly: this.httpOnly,
      sameSite: this.sameSite,

      ...(this.expires && { expires: timeStampableToDate(this.expires) })
    } as CookieSerializeOptions;
  }
}

/**
 * Represents a simplified {@link CookieModel} with only the key and value.
 * This is useful for representing cookies from an isomorphic context.
 */
export type SimpleCookieModel = Omit<
  CookieModel,
  'path' | 'domain' | 'expires' | 'maxAge' | 'secure' | 'httpOnly' | 'sameSite'
>;
