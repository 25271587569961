'use client';

import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import {
  createContext,
  useContext,
  type FC,
  type PropsWithChildren
} from 'react';
import { useIsMounted } from '../core-ui/hooks/useIsMounted';

/**
 * A context to track if the application is in the hydration phase.
 */
const HydrationContext = createContext<boolean>(false);

/**
 * A component which provides a context that indicates if the application is in
 * the hydration phase. It **MUST** only be used at the root of the application.
 */
export const HydrationProvider: FC<PropsWithChildren> = ({ children }) => {
  const isMounted = useIsMounted();
  const isHydrating = (typeof window !== "undefined") && !isMounted;

  return (
    <HydrationContext.Provider value={isHydrating}>
      {children}
    </HydrationContext.Provider>
  );
};

/**
 * A hook for determining if the component is being hydrated on the client.
 *
 * **DO NOT** use this hook to conditionally render content on the client, as it
 * will cause hydration mismatches. This hook is only intended to be used alongside
 * client-side side-effects, such as error reporting.
 *
 * @returns `true` if the component is being hydrated, `false` otherwise.
 */
export function useIsHydrating(): boolean {
  return useContext(HydrationContext);
}
