import { ResourceNotFoundError } from './errors/ResourceNotFoundError';
import { dateFromTTL, TimeScale } from './time-utils';

/**
 * Specifies whether the TTL is applied to each item from when they were added or the entire cache.
 */
export enum CacheInvalidationStrategy {
  /** All items will have a TTL related to when the cache was last invalidated or first created. */
  Synchronized,

  /** Each item in the cache will live for the TTL time from when it was added. */
  PerItem
}

/** Specifies the approach to use to retrieve data via the memory cache's `get` method. */
export enum GetMethod {
  /** The `get` method should use a synchronous callback as proxy when data is not found. */
  Sync,

  /** The `get` method should use an asynchronous callback as proxy when data is not found. */
  Async,

  /** The `get` method should return the data as a `StaleWhileRevalidate` object. */
  SWR
}

/**
 * Represents metadata related to an item stored in the cache.
 */
export interface ICacheItemMetaData<T> {
  /** The date after which the cached item should be considered invalid or Infinity. */
  expiresOn: Date | typeof Infinity;

  /** The key by which this item is accessed in the cache. */
  key: T;
}

/**
 * Checks whether a date occurs in the past. Used to determine is a cached item is expired
 * and invalid.
 * @param expiresOnDate - The date after which the item is considered expired.
 * @returns Whether the cache item is expired and invalid.
 * @throws An exception is the function is passed a number and that number is anything other
 * than Infinity.
 */
const isCacheItemExpired = (expiresOnDate: Date | typeof Infinity): boolean => {
  if (typeof expiresOnDate === 'number') {
    // Caches set to Infinity never expire.
    if (expiresOnDate === Infinity) {
      return false;
    }

    // Numbers other than Infinity are never allowed.
    throw new Error(
      `\`isCachedItemExpired\` was passed a number other than Infinity. Value was "${expiresOnDate}"`
    );
  }

  // If the value was not Infinity, check dates.
  return expiresOnDate <= new Date();
};

/** `Cache` is used for memoizing data with an optional expiry TTL set for the items it stores. */
class MemoryCache<T, Key = string> {
  protected itemsMetaData = new Map<T, ICacheItemMetaData<Key>>();
  protected itemsMap = new Map<Key, T>();

  // TODO: Add functionality for determining cache hit/miss rates.
  private cacheHits = 0;
  private cacheMisses = 0;

  /** The last time the entire cache was invalidated (or when the cache was created). */
  private lastInvalidation = new Date();

  /** @returns The number of items currently stored in the cache. */
  public get length(): number {
    return this.itemsMap.size;
  }

  /**
   * @param ttl - The number of units of time an item in the cache is allowed to be considered valid.
   * Default is Infinity, making the cache never expire.
   * @param [timeScale] - The unit of time to be used for `ttl`. Defaults to "minutes".
   * @param [invalidationStrategy] - The strategy to be used for invalidation. Whether items have a
   * lifetime based on when they were added to the cache or if all items in the cache should be
   * synchronized. Defaults to a per-item basis.
   * @inheritDoc
   */
  public constructor(
    private ttl: number = Infinity,
    private timeScale: TimeScale = TimeScale.Minutes,
    private invalidationStrategy: CacheInvalidationStrategy = CacheInvalidationStrategy.PerItem
  ) {}

  /**
   * Either removes a specific expired invalid item from the cache or clears the whole
   * cache, depending on the caching strategy.
   * @param key - The key corresponding to a specific item in the cache which should
   * potentially be removed.
   */
  private invalidateItemOrEntireCache(key: Key): void {
    // If the cache invalidation strategy is on a per item basis, remove that item.
    if (this.invalidationStrategy === CacheInvalidationStrategy.PerItem) {
      this.remove(key);

      // If the cache invalidation strategy is for the whole cache, clear it now.
    } else {
      this.clear();
    }
  }

  /**
   * Checks if a valid (unexpired) item for the given key exists in the cache. If an
   * item was found, but was expired, it will purge that item from the cache (or the
   * entire cache, depending on the caching strategy).
   * @param key - The key corresponding to a possible item in the cache.
   * @returns Whether a valid item was found in the cache.
   */
  private hasAndValidate(key: Key): boolean {
    if (this.itemsMap.has(key)) {
      // Get the expiry date from the meta data for the value associated with this key.
      const { expiresOn } = this.itemsMetaData.get(this.itemsMap.get(key)!)!;

      // If the item is expired, remove it from the cache and act as if it did not exist.
      if (isCacheItemExpired(expiresOn)) {
        this.invalidateItemOrEntireCache(key);
        return false;
      }

      // The item was found and was not expired.
      return true;
    }

    // The item was not found at all.
    return false;
  }

  /**
   * Checks if a valid (unexpired) item  exists in the cache. If an item was found, but was
   * expired, it will purge that item from the cache (or the entire cache, depending on the
   * caching strategy).
   * @param item - An item to check for in the cache.
   * @returns Whether a valid item was found in the cache.
   */
  private hasValueAndValidate(item: T): boolean {
    if (this.itemsMetaData.has(item)) {
      // Get the expiry date and key from the meta data for the provided value.
      const { expiresOn, key } = this.itemsMetaData.get(item)!;

      // If the item is expired, remove it from the cache and act as if it did not exist.
      if (isCacheItemExpired(expiresOn)) {
        this.invalidateItemOrEntireCache(key);
        return false;
      }

      // The item was found and was not expired.
      return true;
    }

    // The item was not found at all.
    return false;
  }

  /**
   * Adds an item to the cache.
   * @param key - The key by which this item can later be accessed.
   * @param item - The item to store in the cache for the given key.
   * @param [ttl] - A custom TTL for this item. By default it will use the cache's TTL.
   */
  public add(key: Key, item: T, ttl: number = this.ttl): void {
    // If the cache is synchronized, but already expired, reset it now.
    if (
      this.invalidationStrategy === CacheInvalidationStrategy.Synchronized &&
      this.lastInvalidation <= new Date()
    ) {
      this.clear();
    }

    // cleanup previous cached item
    this.remove(key);

    // Add the item to the cache and calculate the expiry date.
    this.itemsMap.set(key, item);
    this.itemsMetaData.set(item, {
      expiresOn: dateFromTTL(
        ttl,
        this.timeScale,

        // If the exipiration date for items in the cache are synchronized,
        // use the last invalidation date. Otherwise, let the function use the
        // current date and time.
        this.invalidationStrategy === CacheInvalidationStrategy.Synchronized
          ? this.lastInvalidation
          : undefined
      ),
      key
    });
  }

  /**
   * Retrieves an item from the cache for a given key.
   * @param key - The key used to access the item in the cache.
   * @returns The item found in the cache for the provided key.
   * @throws An exception if the item cannot be located in the cache.
   */
  public get(key: Key): T;

  /**
   * Uses the cache as a proxy. It will either retrieve the value from the
   * cache or it will use the `data` callback to retrieve the necessary data.
   * Calls which require calling the `data` callback will store the item in
   * the cache for subsequent retrieval.
   * @param key - The key used to access the item in the cache.
   * @param data - An synchronous callback function used to provide the.
   * @param method - Specifies the approach to use to retrieve data.
   * necessary data. Must be `GetMethod.Sync` for synchronous callbacks.
   * @returns The item found in the cache for the provided key.
   */
  public get(key: Key, data: () => T, method: GetMethod.Sync): T;

  /**
   * Uses the cache as a proxy. It will either retrieve the value from the
   * cache or it will use the `data` callback to retrieve the necessary data.
   * Calls which require calling the `data` callback will store the item in
   * the cache for subsequent retrieval.
   * @param key - The key used to access the item in the cache.
   * @param data - An asynchronous callback function used to provide the.
   * @param [method] - Specifies the approach to use to retrieve data.
   * necessary data. Must be `GetMethod.Async` for asynchronous callbacks.
   * @returns The item found in the cache for the provided key.
   */
  public async get(
    key: Key,
    data: () => Promise<T>,
    method?: GetMethod.Async
  ): Promise<T>;

  /**
   * If a `data` function is provided it will use the cache as a proxy for
   * retrieving the required data. Otherwise, it will retrieve an item from
   * the cache for a given key.
   * @param key - The key used to access the item in the cache.
   * @param [data] - An optional asynchronous callback function used to
   * provide the necessary data.
   * @param [method] - Specifies the approach to use to retrieve data.
   * @returns The item found in the cache for the provided key.
   * @throws An exception if the item cannot be located in the cache and a
   * data callback function was not provided.
   */
  public get(
    key: Key,
    data?: () => Promise<T> | T,
    method: GetMethod = GetMethod.Async
  ): Promise<T> | T {
    // If the item exists and has not expired.
    if (this.hasAndValidate(key)) {
      // If the method is `Get.Async`, this is async version of the method,
      // so return a promise of the data.
      if (method === GetMethod.Async && data) {
        return Promise.resolve(this.itemsMap.get(key)!);
      }

      // If the data callback is not provided, this is synchronous version of
      // the method, so directly return the value.
      return this.itemsMap.get(key)!;
    }

    // If the item was not found in the cache but a data callback function was
    // provided, then call that function to obtain, store, and return the item
    // as promise.
    if (data) {
      // If the return value from calling `data` proves that it is synchronous,
      // store the data and return the value immediately.
      const dataReturnValue = data();
      if (!(dataReturnValue instanceof Promise)) {
        this.add(key, dataReturnValue);
        return dataReturnValue;
      }

      // Otherwise, let the promise do its job and then store the data.
      return new Promise((resolve, reject) => {
        /* eslint-disable promise/prefer-await-to-then -- This is needed due to
        this function being either synchronous or asynchronous depending on usage.
        So we cannot use await here. */
        dataReturnValue
          .then((data) => {
            // Store the retrieved data as an item in the cache.
            this.add(key, data);

            // Resolve the promise so the caller can retrieve the item.
            resolve(data);
            return data;
          })
          .catch((err: Error) => reject(err));
        /* eslint-enable promise/prefer-await-to-then */
      });
    }

    // If the item was not found and a data callback function was not provided, then throw.
    throw new ResourceNotFoundError(
      `Key "${key}" is not found in the cache. Use \`has\` method to check cache before calling \`get\` or use the asynchronous version of \`get\` to obtain the data.`
    );
  }

  /**
   * Checks whether an item in the cache exists for a given key.
   * @param key - The key used to check for an item in the cache.
   * @returns Whether an item was located in the cache for the provided key.
   */
  public has(key: Key): boolean {
    return this.hasAndValidate(key);
  }

  /**
   * Checks whether an item exists in the cache.
   * @param item - An item to check for in the cache.
   * @returns Whether the provided item was located in the cache.
   */
  public hasValue(item: T): boolean {
    return this.hasValueAndValidate(item);
  }

  /**
   * Removes specific items from the cache.
   * @param key - The key corresponding to the item in the cache which should be removed.
   */
  public remove(key: Key): void {
    if (this.itemsMap.has(key)) {
      const item = this.itemsMap.get(key);
      this.itemsMetaData.delete(item!);
      this.itemsMap.delete(key);
    }
  }

  /**
   * Invalidates and empties the cache.
   */
  public clear(): void {
    this.itemsMap = new Map();
    this.itemsMetaData = new Map();
    this.lastInvalidation = new Date();
  }

  /**
   * Transforms the cache into a map.
   * @returns A map with all the cache entries.
   */
  public toMap(): Map<Key, T> {
    return new Map(this.itemsMap);
  }

  /**
   * Returns an iterator for the entries in the cache, and
   * filters out any expired entries.
   * @returns An iterator for the entries in the cache.
   * @example
   * const cache = new MemoryCache<string, number>();
   * cache.add('one', 1);
   * cache.add('two', 2);
   * cache.add('three', 3);
   * const copy = new Map(cache);
   */
  public [Symbol.iterator](): IterableIterator<[Key, T]> {
    return this.entries();
  }

  /**
   * Returns an iterator for the entries in the cache, and
   * filters out any expired entries.
   * @returns An iterator for the entries in the cache.
   * @yields The entries in the cache.
   */
  public *entries(): IterableIterator<[Key, T]> {
    for (const [key, item] of this.itemsMap.entries()) {
      if (this.hasValueAndValidate(item)) {
        yield [key, item];
      }
    }
  }

  /**
   * Returns an iterator for the keys in the cache, and
   * filters out any expired keys.
   * @returns An iterator for the keys in the cache.
   * @yields The keys in the cache.
   */
  public *keys(): IterableIterator<Key> {
    for (const [key, _] of this.entries()) {
      yield key;
    }
  }

  /**
   * Returns an iterator for the items in the cache, and
   * filters out any expired items.
   * @returns An iterator for the items in the cache.
   * @yields The items in the cache.
   */
  public *values(): IterableIterator<T> {
    for (const [_, item] of this.entries()) {
      yield item;
    }
  }
}

export default MemoryCache;
