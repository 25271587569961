/* eslint-disable @typescript-eslint/no-explicit-any -- `any`s to match Promise interface. */
/* eslint-disable promise/prefer-await-to-then -- Can't use `await`s in the constructor */

import { Nullable } from '@/type-utils';
import { computed, makeObservable, observable, runInAction } from 'mobx';

/** Corresponds to the TS type for the `resolve` function. */
type Resolve<T> = (value: T | PromiseLike<T>) => void;

/** Corresponds to the TS type for the `reject` function. */
type Reject = (reason?: any) => void;

/** Corresponds to the TS type for the `executor` function. */
type Executor<T> = (resolve: Resolve<T>, reject: Reject) => void;

/**
 * `StaleWhileRevalidate` is used to represent a value which we can get immediately,
 * but may require an update. It can be used just like a promise with `.then` or `await`.
 * The `value` property may represent the initial "stale" value or the "fresh" value
 * that was retrieved. The `value` property is observable and can be referenced in
 * React components directly.
 */
class StaleWhileRevalidate<T> extends Promise<T> {
  // @ts-expect-error - The constructor signature will enforce that T is `Nullable` if no "stale" value is passed.
  @observable private _value: T;
  @observable private _pending: boolean = false;

  /** @returns The current value. May be the "stale" or "fresh" value. */
  @computed public get value(): T {
    return this._value;
  }

  /** @returns Whether the async operation to fetch the "fresh" data has completed. */
  @computed public get pending(): boolean {
    return this._pending;
  }

  /**
   * @param executor - A function which accepts a `resolve` and `reject` function
   * which can be used to resolve or reject the promise.
   *
   * If this overload is used, the `this.value` will always be `undefined` and
   * `this.pending` will always be `false`, regardless if the "promise" has resolved.
   * @inheritdoc
   */
  public constructor(executor: Executor<Nullable<T>>);

  /**
   * Creates a `StaleWhileRevalidate` that immediately resolves
   * to the "stale" value and never enters a "pending" state.
   * @param staleValue - The "stale" value.
   * @inheritdoc
   */
  public constructor(staleValue: T);

  /**
   * @param staleValue - The "stale" value.
   * @param futureValue - A promise which returns a promise
   * which resolves to the "fresh" updated value.
   * @inheritdoc
   */
  public constructor(staleValue: T, futureValue: Promise<T>);

  /**
   * @param staleValue - The known "stale" value.
   * @param futureValue - A function which returns a promise
   * which resolves to the "fresh" updated value.
   * @inheritdoc
   */
  public constructor(staleValue: T, futureValue: () => Promise<T>);

  /**
   * @param staleValue - The known "stale" value.
   * @param futureValue - Either a promise or a function which returns a promise
   * which resolves to the "fresh" updated value.
   * @inheritdoc
   */
  public constructor(
    staleValue: T | Executor<T>,
    futureValue?: (() => Promise<T>) | Promise<T>
  ) {
    let res: Resolve<T>;
    let rej: Reject;

    // Here be dragons
    super(
      // An odd implementation detail to promises is that they might get
      // reconstructed by the JS engine. In this event, this class to needs
      // to secretly accept its first argument as a promise "executor"
      // function. If the first arg is a function and no second arg was passed
      // we know we should allow it to be the executor to the super class.
      // Further reading: https://tc39.es/ecma262/#sec-newpromisecapability
      staleValue instanceof Function
        ? (staleValue as Executor<T>)
        : (resolve, reject) => {
            res = resolve;
            rej = reject;
          }
    );

    if (!(staleValue instanceof Function)) {
      this._value = staleValue as T; // Set the initial value

      // Extract the promise for the future value.
      const promise =
        futureValue instanceof Promise ? futureValue : futureValue?.();

      if (promise) {
        this._pending = true;
        promise
          .then((value) =>
            runInAction(() => {
              this._value = value;
              this._pending = false;
              res(value);
            })
          )
          // If the promise rejects, then we ourselves should reject.
          // However, it's also important the the pending state remains `true`
          // so that the UI can indicate the value is still stale.
          .catch((e) => rej(e));
      } else {
        res!(staleValue as T);
      }
    }

    makeObservable(this);
  }

  /**
   * Creates a `StaleWhileRevalidate` that is always pending and never resolves.
   *
   * **DO NOT** call `await` on this or it will hang forever and potentially create
   * a memory leak. Instead, only access the value through the `.value` property.
   *
   * @param value - The value to create a `StaleWhileRevalidate` from.
   * @returns A `StaleWhileRevalidate` that is always pending and never resolves.
   */
  public static forever<T>(value: T): StaleWhileRevalidate<T> {
    return new StaleWhileRevalidate(value, new Promise(() => {}));
  }
}

export default StaleWhileRevalidate;
