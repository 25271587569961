import { NumericString } from '@/type-utils';
import { InvalidArgumentError } from './errors/InvalidArgumentError';

/**
 * Replaces the variables that exist within the provided string using and object
 * with keys that stand in for the variable names.
 * @param string - The base string with replaceable variables.
 * @param variables - The key value pairs representing the variable name and its value.
 * @returns A string with its variables replaced using an object provided.
 */
export const replaceStringVariables = (
  string: string,
  variables: Record<string, string>
): string => {
  let newString = string;

  const keys = Object.keys(variables);
  keys?.forEach((key) => {
    if (newString.includes(`{${key}}`)) {
      newString = newString.replaceAll(`{${key}}`, variables[key]);
    }
  });

  return newString;
};

/**
 * Kebab cases a string. Takes a string and replaces all spaces, underscores, and camel case with dashes.
 * @example
 * - 'This is a test' -> 'this-is-a-test'
 * - 'this_is_a_test' -> 'this-is-a-test'
 * - 'This, '
 * @param string - The string to kebab case.
 * @returns A kebab cased string.
 */
export const kebabCase = (string: string): string => {
  const words = string.split(' ');

  return string
    .replace(/([a-z0-9]|(?=[A-Z])[a-z0-9])([A-Z])/g, '$1-$2') // insert hyphen between camelCase
    .replace(/[_\s]+/g, '-') // replaces underscores and spaces with hyphen
    .replace(/^-+|-+$/g, '') // removes leading and trailing hyphen
    .replace(/[^-\w\s]/g, '') // removes punctuation (except hyphens)
    .toLowerCase();
};

/**
 * Given a string, mask some or all of its characters.
 *
 * Useful for hiding PII in such a way that it is identifiable by
 * the customer, but not by any other party.
 *
 * @param string - The string to mask.
 * @param [options] - Masking options.
 *
 * @returns The masked string.
 *
 * @example
 *
 * `hello world` (no masking)
 * `***********` (all characters masked)
 * `he*********` (2 leading characters)
 * `he*******ld` (2 leading characters + 2 trailing characters)
 * `.........ld` ('.' as mask character + 2 trailing characters)
 */
export const maskString = (
  string: string,
  options?: {
    /**
     * The amount of characters to leave unmasked at the beginning
     * of the string.
     *
     * Defaults to `0`.
     *
     * @example
     * `hello world` (no masking)
     * `***********` (0 leading characters)
     * `he*********` (2 leading characters)
     * `hello******` (5 leading characters)
     */
    leadingChars?: number;

    /**
     * The amount of characters to leave unmasked at the end
     * of the string.
     *
     * Defaults to `0`.
     *
     * @example
     * `hello world` (no masking)
     * `***********` (0 trailing characters)
     * `*********ld` (2 trailing characters)
     * `******world` (5 trailing characters)
     */
    trailingChars?: number;

    /**
     * The character (or string) that will replace every
     * masked character.
     *
     * Defaults to `*`.
     */
    maskChar?: string;
  }
): string => {
  const { leadingChars = 0, trailingChars = 0, maskChar = '*' } = options ?? {};

  const charsInBetween = string.length - leadingChars - trailingChars;
  const leadingSegment = string.slice(0, leadingChars);
  const trailingSegment = string.slice(
    charsInBetween + leadingChars,
    string.length
  );

  return leadingSegment + maskChar.repeat(charsInBetween) + trailingSegment;
};

/**
 * Mask a provided email string.
 *
 * @see The {@link maskString} util.
 *
 * Masking rules are as follows:
 * - The username part will be masked with 2 leading characters.
 * - The first level domain part will be left unmasked.
 * - The second and higher level domains will be completely masked.
 *
 * @param email - The email to mask.
 * @returns The masked email.
 *
 * @example `john.wick@deckers.com` -> `jo*******@*******.com`
 */
export const maskEmail = (email: string): string => {
  const [user, domain] = email.split('@');
  const splitDomains = domain.split('.');

  const maskedUser = maskString(user, {
    leadingChars: 2
  });

  const tld = splitDomains.pop();
  const restOfDomains = splitDomains.join('.');

  const maskedDomain = maskString(restOfDomains) + '.' + tld;

  return `${maskedUser}@${maskedDomain}`;
};

/**
 * Checks if a given string represents a valid zip code.
 *
 * @param string - The string to check.
 *
 * @returns A `true` value if the string is indeed a valid
 * zip code, and `false` otherwise.
 */
export const isValidZipCode = (string: string): boolean => {
  // Kudos to https://stackoverflow.com/a/2577239
  return /^\d{5}(?:[-\s]\d{4})?$/.test(string);
};

/**
 * Checks if a given string represents a valid email address.
 *
 * @param string - The string to check.
 *
 * @returns A `true` value if the string is indeed a valid
 * email address, and `false` otherwise.
 */
export const isValidEmail = (string: string): boolean => {
  return /[^@]+@[^@]+\.[^@]+/.test(string);
};

/**
 * Checks that the supplied string is numeric.
 * @param str - The string to check.
 * @returns A boolean indicating whether the string is numeric.
 */
export function isNumericString(str: string): str is NumericString {
  str = str.trim(); // we use trim() to ensure that the string is not empty
  if (str === '') return false;

  // we use Number() instead of Number.parseFloat() because the former
  // will reject strings that contain any invalid characters, while the
  // latter will parse as much as it can and ignore the rest.
  const amountAsNumber = Number(str);

  // checks for `Infinity` or `NaN`
  if (!Number.isFinite(amountAsNumber)) return false;

  return true;
}

/**
 * Converts a string to title case.
 * @param str - The string to convert.
 * @returns The title cased string.
 * @example
 * - 'hello world' -> 'Hello World'
 * - 'hello-world' -> 'Hello-World'
 * - 'hello_world' -> 'Hello_world' // note this may not be what you want
 * - 'helloWorld' -> 'HelloWorld'
 * - 'HELLO WORLD' -> 'HELLO WORLD'
 */
export function toTitleCase(str: string): string {
  return str.replace(/\b\w/g, (match) => match.toUpperCase());
}

/**
 * Given an address line, check if it represents a PO Box.
 *
 * @param str - The address line to check.
 * @returns A value of `true` if the line is indeed a PO Box, and `false`
 * otherwise.
 */
export function isPOBoxLine(str: string): boolean {
  // Monster PO Box regex, based on a heavily modified version of
  // https://stackoverflow.com/questions/5159535/po-box-validation
  //
  // See https://regexr.com/7s9dm for explanation and tests
  const poBoxRegex =
    /^\s*(P((O|0)(ST(AL)?))?(\.|-|\/|\\|\s)*((O|0)F+(ICE)?)+)|^\s*(P((O|0)(ST(AL)?))?(\.|-|\/|\\|\s)*((O|0)F*(ICE)?)?(\.|-|\/|\\|\s)*(B(IN|(O|0)X?|\.)+)+)|^\s*((P((O|0)(ST(AL)?))?(\.|-|\/|\\|\s)*((O|0)F*(ICE)?)+|(P(\.|-|\/|\\|\s)*(O|0)?)?(\.|-|\/|\\|\s)*(B(IN|(O|0)?X?)*|(ST(AL)?))+)(\.|-|\/|\\|\s)*(#|\s|-|n(o|um(ber)?)?)*(\d{2,5}))|^\s*(#|\s|-|n(o|um(ber)?)?)+(\d{2,5})/i;

  return poBoxRegex.test(str);
}

/**
 * Given a string, split it into several strings (chunks) of the specified
 * size.
 *
 * @param string - The string to split.
 * @param chunkSize - The size of each resulting chunk.
 * If the original string's length is not divisible by the specified chunk
 * size, the last chunk will only contain the remaining items.
 *
 * @returns An array with all the chunks split from the original array.
 * @throws An {@link InvalidArgumentError} if an invalid chunk size is provided.
 */
export const chunkifyString = <T>(
  string: string,
  chunkSize: number
): Array<string> => {
  if (chunkSize === 0) {
    throw new InvalidArgumentError(
      'Cannot chunkify string with chunkSize = 0.'
    );
  }

  const chunks: Array<string> = [];

  for (let i = 0; i < string.length; i += chunkSize) {
    chunks.push(string.substring(i, Math.min(i + chunkSize, string.length)));
  }

  return chunks;
};
