import axios from 'axios';

import { DTO } from '@/type-utils';
import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';
import MemoryCache from '@/utils/MemoryCache';
import { TimeScale } from '@/utils/time-utils';

import { exhaustiveGuard } from '@/utils/function-utils';
import LazyValue from '@/utils/LazyValue';
import {
  IInventory,
  InventoryModel,
  ProductAvailabilityState
} from '../../models/Inventory';

import ServerInventoryService from '../../serverless/ServerInventoryService';
import Service from '../../Service';

import ConfigurationService from '../ConfigurationService';
import { EnvironmentService } from '../EnvironmentService';
import { msg } from '../I18NService';
import InventoryServiceMock from './InventoryServiceMock';
import { product_details_inStock } from "@/lang/__generated__/ahnu/product_details_inStock";
import { product_details_backorder } from "@/lang/__generated__/ahnu/product_details_backorder";
import { product_details_outOfStock } from "@/lang/__generated__/ahnu/product_details_outOfStock";
import { product_details_preorder } from "@/lang/__generated__/ahnu/product_details_preorder";
import { product_details_unavailable } from "@/lang/__generated__/ahnu/product_details_unavailable";

/**
 * Abstracts inventory-related operations.
 *
 * @see {@link ServerInventoryService} for the server-only service.
 */
export class InventoryService extends Service {
  private client = axios.create({ baseURL: '/api/inventory' });

  // Use lazy values here to prevent "Could not determine the locale" errors.
  private _inventoryCache = new LazyValue<MemoryCache<InventoryModel>>(() => {
    const inventoryConfig = ConfigurationService.getConfig('inventory');

    return new MemoryCache<InventoryModel>(
      inventoryConfig.getSetting('isomorphicInventoryCacheTTL').value,
      TimeScale.Seconds
    );
  });

  private _staleInventoryCache = new LazyValue<MemoryCache<InventoryModel>>(
    () => {
      const inventoryConfig = ConfigurationService.getConfig('inventory');

      return new MemoryCache<InventoryModel>(
        inventoryConfig.getSetting('isomorphicStaleInventoryCacheTTL').value,
        TimeScale.Seconds
      );
    }
  );

  /**
   * A memory cache for inventory data. The cache is shared between single and
   * bulk inventory GET calls.
   *
   * On bulk calls, the service will only attempt to retrieve any inventory
   * records that were not previously cached.
   *
   * @returns A {@link MemoryCache} instance.
   */
  private get inventoryCache(): MemoryCache<InventoryModel> {
    return this._inventoryCache.value;
  }

  /**
   * A secondary, longer-lived cache for inventory data. Useful for storing
   * inventory data that is known to be stale without disrupting the primary
   * cache.
   *
   * @returns A {@link MemoryCache} instance.
   */
  private get staleInventoryCache(): MemoryCache<InventoryModel> {
    return this._staleInventoryCache.value;
  }

  /**
   * Retrieve inventory for a product.
   *
   * @param upc - The product's {@link IProduct.upc UPC}.
   * @returns An {@link InventoryModel}.
   */
  public async getProductInventory(upc: string): Promise<InventoryModel> {
    // Return cached value if available.
    if (this.inventoryCache.has(upc)) {
      return this.inventoryCache.get(upc);
    }

    if ((typeof window === "undefined")) {
      const dto = await ServerInventoryService.getProductInventory(upc);
      return new InventoryModel(dto);
    }

    const { data: dto } = await this.client.get<DTO<IInventory>>(`/${upc}`);

    const model = new InventoryModel(dto);
    this.inventoryCache.add(upc, model);

    return model;
  }

  /**
   * Retrieve inventory for multiple products.
   *
   * @param upcList - The {@link IProduct.upc UPCs} of the products to fetch inventory for.
   *
   * @returns A map with the passed UPCs as keys and their respective inventory
   * records (in {@link InventoryModel} form) as values.
   */
  public async bulkGetProductInventory(
    ...upcList: ReadonlyArray<string>
  ): Promise<Record<string, InventoryModel>> {
    // Return cached values if available.
    const cachedUPCs = [];
    const nonCachedUPCs = [];

    for (const upc of upcList) {
      if (this.inventoryCache.has(upc)) {
        cachedUPCs.push(upc);
      } else {
        nonCachedUPCs.push(upc);
      }
    }

    const cachedModels = Object.fromEntries(
      cachedUPCs.map((upc) => [upc, this.inventoryCache.get(upc)])
    );

    if ((typeof window === "undefined")) {
      const fetchedData =
        nonCachedUPCs.length > 0
          ? await ServerInventoryService.bulkGetProductInventory(
              ...nonCachedUPCs
            )
          : {};

      const fetchedModels = Object.fromEntries(
        Object.keys(fetchedData).map((upc) => [
          upc,
          InventoryModel.from(fetchedData[upc])
        ])
      );

      return {
        ...cachedModels,
        ...fetchedModels
      };
    }

    if (nonCachedUPCs.length === 0) {
      return cachedModels;
    }

    const { data } = await this.client.get<Record<string, DTO<IInventory>>>(
      `/${upcList.join(',')}`
    );

    const fetchedModels = Object.fromEntries(
      Object.keys(data).map((upc) => {
        const model = new InventoryModel(data[upc]);
        this.inventoryCache.add(upc, model);
        return [upc, model];
      })
    );

    return {
      ...cachedModels,
      ...fetchedModels
    };
  }

  /**
   * Imports inventory data to the stale inventory cache. Used to share
   * server-fetched inventory data with the client-side via page props.
   *
   * @param staleInventory - The stale inventory data to import. Must be a
   * record of product UPCs as keys and {@link IInventory} DTOs as values.
   */
  public importStaleInventoryToCache(
    staleInventory: Record<string, IInventory | DTO<IInventory>>
  ): void {
    for (const upc of Object.keys(staleInventory)) {
      this.staleInventoryCache.add(
        upc,
        InventoryModel.from(staleInventory[upc])
      );
    }
  }

  /**
   * Synchronously retrieves _stale_ inventory data for a product.
   *
   * @param upc - The product's {@link IProduct.upc UPC}.
   * @returns An {@link InventoryModel}.
   */
  public getStaleProductInventory(upc: string): InventoryModel | null {
    if (this.inventoryCache.has(upc)) {
      return this.inventoryCache.get(upc);
    }

    if (this.staleInventoryCache.has(upc)) {
      return this.staleInventoryCache.get(upc);
    }

    return null;
  }

  /**
   * Synchronously retrieves _stale_ inventory data for multiple products.
   *
   * @param upcList - The {@link IProduct.upc UPCs} of the products to fetch inventory for.
   *
   * @returns A map with the passed UPCs as keys and their respective inventory
   * records (in {@link InventoryModel} form) as values.
   */
  public bulkGetStaleProductInventory(
    ...upcList: Array<string>
  ): Record<string, InventoryModel> | null {
    const staleInventoryMap: Record<string, InventoryModel> = {};

    for (const upc of upcList) {
      const staleInventory = this.getStaleProductInventory(upc);

      if (!staleInventory) {
        // Return `null` if any of the UPCs doesn't have stale inventory.
        return null;
      }

      staleInventoryMap[upc] = staleInventory;
    }

    return staleInventoryMap;
  }

  /**
   * Given an {@link ProductAvailabilityState availability state value}, retrieves the internationalized
   * message that describes it in a UI-friendly way.
   *
   * @param availabilityState - Availability state.
   * @returns An string with the description message.
   *
   * @throws An {@link InvalidArgumentError} if the provided availability state is invalid.
   */
  // TODO: consider removing as this is no longer used in practice
  public getAvailabilityIntlMessage(
    availabilityState: Exclude<
      ProductAvailabilityState,
      ProductAvailabilityState.Unknown | ProductAvailabilityState.Pending
    >
  ): string {
    const inventoryConfig = ConfigurationService.getConfig('inventory');

    const allowBackorder = inventoryConfig.getSetting('allowBackorder').value;
    const allowPreorder = inventoryConfig.getSetting('allowPreorder').value;

    switch (availabilityState) {
      case ProductAvailabilityState.InStock:
        return msg(product_details_inStock);

      case ProductAvailabilityState.Backorder:
        return allowBackorder
          ? msg(product_details_backorder)
          : msg(product_details_outOfStock);

      case ProductAvailabilityState.Preorder:
        return allowPreorder
          ? msg(product_details_preorder)
          : msg(product_details_outOfStock);

      case ProductAvailabilityState.OutOfStock:
        return msg(product_details_outOfStock);

      case ProductAvailabilityState.Unavailable:
        return msg(product_details_unavailable);

      default:
        return exhaustiveGuard(
          availabilityState,
          new InvalidArgumentError(
            `Cannot get Availability Text for invalid state "${availabilityState}".`
          )
        );
    }
  }
}

export default InventoryService.withMock(
  new InventoryServiceMock(InventoryService)
) as unknown as InventoryService;
