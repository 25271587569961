import Service from '@/services/Service';
import type { CartModel } from '@/services/models/Cart';
import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import type { ApplePayPaymentBundle } from '.';
import { ApplePayService } from './ApplePayService';
import { type IExpressPayment } from './IPaymentService';

export const { AbortPaymentError } = errorFactory('AbortPaymentError');

/** Error to use when a payment method is aborted. */
export type AbortPaymentError = InstanceTypeOf<typeof AbortPaymentError>;

/**
 * Service to handle payment Request using third party APIs like Apple Pay and Google Pay.
 */
export class PaymentRequestService extends Service {
  private inRequest: boolean = false;

  /**
   * Initiates a payment request using Apple Pay.
   * @param cart - The cart to be ordered.
   * @returns A promise that resolves to an express payment object.
   */
  public async requestApplePayPayment(
    cart: CartModel
  ): Promise<IExpressPayment<ApplePayPaymentBundle>> {
    if (this.inRequest) {
      throw new Error('Payment request already in progress.');
    }

    this.inRequest = true;

    return new Promise<IExpressPayment<ApplePayPaymentBundle>>(
      (resolve, reject) => {
        try {
          new ApplePayService().spawnSession(cart, resolve, (type) => {
            reject(new AbortPaymentError(type));
          });
        } catch (error) {
          reject(error);
        }
      }
    ).finally(() => {
      this.inRequest = false;
    });
  }
}

export default new PaymentRequestService();
