'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { msg } from '@/services/isomorphic/I18NService';
import { AnimatePresence, motion } from 'framer-motion';
import { useState, type FC } from 'react';
import type { RenderError } from '../../RenderError';
import { ComponentChain } from '../ComponentChain';
import S from './styles.module.scss';
import { general_errors_renderAlerts_seeDetails } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_seeDetails";
import { general_errors_renderAlerts_timestampTooltip } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_timestampTooltip";
import { general_errors_renderAlerts_timestamp } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_timestamp";
import { general_errors_renderAlerts_errorName } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_errorName";
import { general_errors_renderAlerts_errorMsg } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_errorMsg";
import { general_errors_renderAlerts_componentStack } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_componentStack";
import { general_errors_renderAlerts_hoverToReveal } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_hoverToReveal";
import { general_errors_renderAlerts_stackTrace } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_stackTrace";

export interface IRenderErrorDetailsAccordionProps {
  /**
   * The render error to display details for.
   */
  renderError: RenderError;
}

/**
 * An accordion that displays details about a render error.
 */
export const RenderErrorDetailsAccordion: FC<
  IRenderErrorDetailsAccordionProps
> = ({ renderError }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { componentStack, timestamp, stack, name, message } = renderError;
  const formattedTimestamp = timestamp.toFixed(0) + 'ms';

  return (
    <div role="group" className={S.accordion}>
      <button
        type="button"
        className={S.accordionHeader}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={classes(S.accordionTitle, isOpen ? S.open : null)}>
          {msg(general_errors_renderAlerts_seeDetails)}
        </div>
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            className={S.accordionContent}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 }
            }}
          >
            <div
              className={S.section}
              title={msg(general_errors_renderAlerts_timestampTooltip)}
            >
              <span className={S.sectionTitle}>
                {msg(general_errors_renderAlerts_timestamp)}:
              </span>
              {formattedTimestamp}
            </div>

            <div className={S.section}>
              <span className={S.sectionTitle}>
                {msg(general_errors_renderAlerts_errorName)}:
              </span>
              {name}
            </div>

            {message && (
              <div className={S.section}>
                <span className={S.sectionTitle}>
                  {msg(general_errors_renderAlerts_errorMsg)}:
                </span>
                {message}
              </div>
            )}

            {componentStack && (
              <div className={S.section}>
                <div
                  role="menuitem"
                  className={classes(S.componentChainClamp)}
                  // this is selectable in order to make the reveal
                  // work with keyboard navigation
                  tabIndex={0}
                >
                  <div>
                    <span className={S.sectionTitle}>
                      {msg(general_errors_renderAlerts_componentStack)}
                    </span>
                    <span className={S.hoverHint}>
                      ({msg(general_errors_renderAlerts_hoverToReveal)})
                    </span>
                  </div>
                  <ComponentChain chain={componentStack} />
                </div>
              </div>
            )}

            {stack && (
              <div className={S.section}>
                <span className={S.sectionTitle}>
                  {msg(general_errors_renderAlerts_stackTrace)}
                </span>
                <div className={S.stackTraceWrapper}>
                  <pre className={S.stackTrace}>{stack}</pre>
                </div>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
