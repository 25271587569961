/**
 * Polyfills for legacy browser support.
 * This should always be at the top of the entry file.
 */
import '@/polyfills';

import { SpeedInsights } from '@vercel/speed-insights/react';
import { enableStaticRendering } from 'mobx-react-lite';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import { FC } from 'react';

import {
  ReactHooksWrapper,
  setHook
} from '@/services/utils/react-utils/hook-utils';

// Page-level styles cannot be imported from files other than the page file itself.
// Please copy and paste the commented import statement below into corresponding
// page file of the frontend.

// import '@/styles/global.scss';
import {
  headerLoadingBarColor,
  headerLoadingBarHeight
} from '@/styles/variables.module.scss';

// Added to resolve Logger error when using in the browser: https://github.com/winstonjs/winston/issues/1354#issuecomment-546271943
import { Datadog } from '@/react/components/Datadog';
import { RootErrorBoundary } from '@/react/components/errors/boundaries/RootErrorBoundary';
import { TopErrorAlertList } from '@/react/components/errors/devtools/TopErrorAlertList';
import { TopErrorProvider } from '@/react/components/errors/devtools/TopErrorProvider';
import Forter from '@/react/components/fraud/Forter';
import DynamicYield from '@/react/components/personalization/DynamicYield';
import GTM from '@/react/components/user-action/GTM';
import { GlobalProvider } from '@/react/providers/global/GlobalProvider';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';

import { GlobalErrorAlertProvider } from '@/react/components/core-ui/ErrorAlert/GlobalErrorAlertProvider';
import { HydrationProvider } from '@/react/components/utils/HydrationProvider';
import 'setimmediate';

// Prevent MobX from leaking memory when rendering server-side
enableStaticRendering((typeof window === "undefined"));

/**
 * A custom `App` component implementation for Next JS.
 * @see https://nextjs.org/docs/advanced-features/custom-app
 */
const App: FC<AppProps<Record<string, unknown>>> = ({
  Component,
  pageProps
}) => {
  // This line allows us the use the `useRouter` hook outside of components in a safe way.
  // We use the hook rather than the `Router` singleton because of the reasons described
  // here: https://github.com/vercel/next.js/discussions/18522#discussioncomment-836362.
  setHook('Router', useRouter);

  return (
    <HydrationProvider>
      <ReactHooksWrapper />
      <TopErrorProvider>
        <TopErrorAlertList />
        <RootErrorBoundary>
          <GlobalErrorAlertProvider>
            <GTM />
            <Forter />
            <Datadog />
            <DynamicYield />
            <GlobalProvider>
              <Component {...pageProps} />
            </GlobalProvider>
          </GlobalErrorAlertProvider>
        </RootErrorBoundary>
      </TopErrorProvider>
      <NextNProgress
        color={headerLoadingBarColor}
        height={parseInt(headerLoadingBarHeight, 10)}
        options={{
          showSpinner: false
        }}
        stopDelayMs={300}
        // In some pages, we want the URL to change as a side effect.
        // However, some of the these URL changes should not require running prop functions
        // (say, getServerSideProps) again. This can be achieved with
        // [Shallow Routing](https://nextjs.org/docs/routing/shallow-routing).
        //
        // The PDP is a good example of shallow routing; where clicking a different color
        // or size should change the URL, but because all the data is already available in
        // memory, calling `getServerSideProps` again would only be additional overhead with
        // no real benefit.
        //
        // In an interesting discovery, we noticed that despite the PDP updating its state
        // almost instantly with Shallow Routing, the page still felt a bit slow because the
        // loading bar was showing on top for around half a second every time an attribute
        // was selected. So we opted to disable it when loading shallow routes.
        showOnShallow={false}
      />
      <SpeedInsights />
    </HydrationProvider>
  );
};

export default App;
