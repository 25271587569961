import {
  spacingBase,
  spacingLg,
  spacingMd,
  spacingSm,
  spacingXl,
  spacingXs,
  spacingXxl,
  spacingXxs,
  spacingXxxl,
  spacingXxxs,
  spacingXxxxl,
  spacingXxxxs
} from '@/styles/variables.module.scss';
import { Nullable } from '@/type-utils';
import {
  AnyAcceptableCSSLength,
  CSSLength,
  CSSPixelLength,
  StyleSpacing
} from '@/type-utils/css';
import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';
import { isValidCSSLength } from './validation-utils';

/** Maps the string spacing keys to their SCSS equivalent values. */
const spacingsMap = new Map<StyleSpacing, string>([
  ['xxxxs', spacingXxxxs],
  ['xxxs', spacingXxxs],
  ['xxs', spacingXxs],
  ['xs', spacingXs],
  ['sm', spacingSm],
  ['base', spacingBase],
  ['md', spacingMd],
  ['lg', spacingLg],
  ['xl', spacingXl],
  ['xxl', spacingXxl],
  ['xxxl', spacingXxxl],
  ['xxxxl', spacingXxxxl]
] as const) as Map<StyleSpacing, CSSLength>;

// -----
// Classes Util
// Kudos to John for coming up with this one!

/** Defines valid arguments for the {@link classes} util function. */
type ClassesArgument =
  | string
  | Record<string, Nullable<boolean>>
  | null
  | undefined;

/**
 * Conditionally join CSS class names into a single string.
 *
 * This util is as powerful as [classnames](https://github.com/JedWatson/classnames)
 * while being comparable to [classix](https://github.com/alexnault/classix) in size.
 * Not to mention the added benefit of not needing to rely on an external library, which would add
 * more overhead.
 *
 * @example ```ts
 * classes('foo', 'bar'); // => 'foo bar'
 * classes(false ? 'foo' : 'bar'); // => 'bar'
 * classes('foo', { bar: true }); // => 'foo bar'
 * classes({ 'foo-bar': true }); // => 'foo-bar'
 * classes({ 'foo-bar': false }); // => ''
 * classes({ foo: true }, { bar: true }); // => 'foo bar'
 * classes({ foo: true, bar: true }); // => 'foo bar'
 * ```
 *
 * @param args - The expressions to evaluate.
 * @returns The joined class names.
 *
 */
export function classes(...args: Array<ClassesArgument>): string {
  let str = '';

  for (const arg of args) {
    if (arg) {
      if (str) str += ' ';

      if (typeof arg === 'string') {
        str += arg;
      } else if (typeof arg === 'object') {
        str += classes(
          ...Object.keys(arg).filter(
            (key) => (arg as Record<string, boolean>)[key]
          )
        );
      }
    }
  }
  return str;
}

// -----

/**
 * Returns whether a provided string is a valid "spacing" string, such as "xs", "sm",
 * "lg", etc.
 * @param value - The string value to determine whether it is a valid "spacing".
 * @returns Whether the provided string is a valid spacing.
 */
export const isValidSpacing = (value: string): value is StyleSpacing => {
  return spacingsMap.has(value as StyleSpacing);
};

/**
 * Given a valid "spacing" string, will return its CSS "length" value as defined in
 * the SCSS variables.
 * @param spacing - The spacing "key", such as "xs", "md", "sm", "lg", etc.
 * @returns The CSS "length" value for that spacing key as a string.
 * @throws {@link InvalidArgumentError} When the provide "spacing" is not a known
 * valid "spacing". See {@link spacingsMap} for details.
 * @example ```ts
 * const spaceXS = getSpacingValue('xs'); // Returns string "12px".
 * ```
 */
export const getSpacingValue = (spacing: StyleSpacing): CSSLength => {
  // If this spacing is invalid – throw.
  if (!spacingsMap.has(spacing)) {
    throw new InvalidArgumentError(
      `\`getSpacingValueInPixels\` was passed "${spacing}" which is not a valid spacing value. ` +
        `Check for whitespace and ensure \`spacing\` is a valid "spacing" value.`
    );
  }

  // If the spacings map has this spacing, return it.
  return spacingsMap.get(spacing)!;
};

/**
 * Returns a numeric value as a CSS Length in pixels.
 * @param length - The numeric length in pixels.
 * @returns The numeric length as a string, like "20px".
 * @example ```ts
 * const numberInPixels = numberToPixels(5); // Returns string "5px"
 * ```
 */
export const numberToPixels = <T extends number = number>(
  length: T
): CSSPixelLength<T> => `${length}px`;

/**
 * Given what is ostensibly a CSS "length"-like value (25, "25", "25px", "25%", "sm")
 * it will convert the value to ensure it is an actual CSS "length" value.
 * @param length - The CSS "length"-like value to convert to a CSS "length".
 * @returns The provided value normalized into a valid CSS "length".
 * @throws {@link InvalidArgumentError} When the `length` argument is not in any
 * known valid format.
 * @example ```ts
 * normalizeCSSLength(25); // Returns string "25px".
 * normalizeCSSLength('25'); // Returns string "25px".
 * normalizeCSSLength('25px'); // Returns string "25px".
 * normalizeCSSLength('25%'); // Returns string "25%".
 * normalizeCSSLength('sm'); // Returns string "16px".
 * ```
 */
export const normalizeCSSLength = <T extends number = number>(
  length: AnyAcceptableCSSLength<T> | number
): CSSLength<T> => {
  // If the length is a number, convert it to pixels. `20` becomes `"20px"`.
  if (typeof length === 'number') {
    return numberToPixels(length as number) as CSSLength<T>;
  }

  // If the length is a string, determine what the string represents.
  if (typeof length === 'string') {
    // First check if the string is a spacing value, such as "xs", "sm", "lg", etc.
    if (isValidSpacing(length)) {
      return getSpacingValue(length) as CSSLength<T>;
    }

    // Otherwise, check if it's a valid CSS "length" like "20px", "100vh", "50%", etc.
    if (isValidCSSLength(length)) {
      return length as CSSLength<T>;
    }

    // Finally, if nothing else, check if the string is a number-like string, like
    // "20", or "50". If so, convert it to pixels.
    if (/^\d+$/.test(length.trim())) {
      return numberToPixels(Number(length)) as CSSLength<T>;
    }
  }

  // If the passed CSS "length"-like value was indeed not "length"-like at all – throw.
  throw new InvalidArgumentError(
    `Argument \`length\` did not match any acceptable CSS "length"-like value. ` +
      `\`length\` was type "${typeof length}" and its value was "${length}". Acceptable values include: numbers, ` +
      `number-like strings (like "20"), CSS "length" values (like "20px" or "20%"), and "spacing" values, such as ` +
      `"xs", "sm", "lg", etc.`
  );
};
