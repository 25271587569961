import { InvalidStateError } from '@/utils/errors';
import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';

import { DTO } from '@/type-utils';
import AccountService from '../../../isomorphic/AccountService';
import UserService from '../../../isomorphic/UserService';

import { AccountModel } from '../../Account';

import type { IAuthenticatedUserTokens } from '.';
import UserModel from '../UserModel';
import UserType from '../UserType';
import IAuthenticatedUser from './IAuthenticatedUser';

/** Represents an {@link IAuthenticatedUser Authenticated User}. */
export default class AuthenticatedUserModel
  extends UserModel<DTO<IAuthenticatedUser>>
  implements IAuthenticatedUser
{
  private _tokens: IAuthenticatedUserTokens;
  private _account: AccountModel;

  /** @inheritdoc */
  public readonly type = UserType.Authenticated;

  /** @inheritdoc */
  public readonly email: string;

  /** @inheritdoc */
  public constructor(user: DTO<IAuthenticatedUser>) {
    super(user);

    if (!user.tokens) {
      throw new InvalidArgumentError(
        `Creating a AuthenticatedUserModel requires tokens.`
      );
    }

    if (!user.account) {
      throw new InvalidArgumentError(
        `Creating a AuthenticatedUserModel requires account.`
      );
    }

    this.email = user.email;
    this._tokens = user.tokens;
    this._account = AccountModel.from(user.account);
  }

  /** @inheritdoc */
  public get tokens(): IAuthenticatedUserTokens {
    return this._tokens;
  }

  /** @inheritdoc */
  public get account(): AccountModel {
    return this._account;
  }

  /**
   * Refresh the user's {@link IAuthSession tokens}. This method will
   * fail there is no active tokens. In that case, please use the `logIn`
   * method instead.
   *
   * @throws An {@link InvalidStateError} if the user doesn't have an
   * active tokens.
   */
  public async refreshTokens(): Promise<void> {
    this._tokens = await UserService.refreshTokens(
      this.email,
      this.tokens.refreshToken
    );

    await UserService.saveUserToSession(this);
  }

  /**
   * Gets fresh account data for this user.
   * Use this method after mutating account data.
   */
  public async updateAccountData(): Promise<void> {
    this._account = await AccountService.getUserAccount(
      this.account.id,
      this.tokens.accessToken
    );

    await UserService.saveUserToSession(this);
  }

  /** @inheritdoc */
  public toDTO(): DTO<IAuthenticatedUser> {
    return {
      ...super.toDTO(),

      email: this.email,
      tokens: this.tokens,
      account: this.account.toDTO()
    } as DTO<IAuthenticatedUser>;
  }
}
