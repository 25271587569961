'use client';

import { useEffect, useState } from 'react';

/**
 * Custom hook to check if a component is mounted after SSR.
 * @returns Returns a boolean indicating if the component is mounted or not.
 */
export function useIsMounted(): boolean {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted;
}
