import type { NextRouter } from 'next/router';

import {
  APP_ENV,
  BASE_URL,
  BUILD_ID,
  IS_DEVELOPMENT_MODE,
  REGION,
  SITE_BRAND
} from '@/configs/env/public';
import { Site } from '@/constructs/Site';
import { Brand } from '@/constructs/Brand';

import { BUILD_MODE_ENABLED, CI, VERCEL } from '@/configs/env/server';

import { Nullable } from '@/type-utils';
import { getHook } from '../../utils/react-utils/hook-utils';
import CurrentRequestService, {
  NoRequestAvailableError
} from '../CurrentRequestService';
import { SiteBrandMap } from './SiteBrandMap';

declare const __isolatedContext__: boolean;

/** Execution contexts in which the code could be running. */
export enum EnvExecutionContext {
  Browser = 'browser',
  Server = 'server'
}

/** Represents an environment, such as production, development, or QA. */
export enum Environment {
  Production = 'prod',
  Development = 'dev',
  QA = 'qa',
  UAT = 'uat'
}

export { Brand };

/** Represents a region such as North America or Europe. */
export enum Region {
  NorthAmerica = 'NA'
}

/**
 * The app build type. This is configured in {@link https://nextjs.org/docs/api-reference/next.config.js/introduction next.config.js} and comes from
 * the `dev` property of the NEXTJS config object. If `dev` is true
 * then it is running in development mode, if it is false, then it is
 * being run in build or production mode.
 */
export enum AppBuildType {
  ProductionMode = 'prod',
  DevelopmentMode = 'dev'
}

/**
 * Provides information relating to the environment in which the code is currently executing.
 *
 * **Note:** This service does not extend {@link Service} because it is used in {@link ConfigModel}
 * (a dependency for {@link ConfigurationService}, which in turn is a dependency for
 * {@link Service}) to determine the current site's brand.
 */
class EnvironmentService {
  private _isStatic: Nullable<boolean>;

  /** The application for which this site environment is configured.  */
  public readonly site: Site;

  /** The brand for which this site application is configured.  */
  public readonly brand: Brand;

  /** The current execution context in which the code is running. */
  public readonly executionContext: EnvExecutionContext;

  /** Whether the code is running in the browser. */
  public readonly inBrowser: boolean;

  /** Whether the code is running on the server. */
  public readonly onServer: boolean;

  /** The current environment, such as production, development, or QA. */
  public readonly environment: Environment;

  /** Whether the current environment is "production". */
  public readonly isProd: boolean;

  /** Whether the current environment is "development". */
  public readonly isDev: boolean;

  /** Whether the current environment is "QA". */
  public readonly isQA: boolean;

  /** Whether the current environment is "UAT". */
  public readonly isUAT: boolean;

  /**
   * Whether the app is currently building or not.
   *
   * In other words, whether this environment is the result
   * of running the `build` command.
   */
  public readonly isBuilding: boolean;

  /**
   * Is this a part of static page generation, ie: in a GetStaticProps or GetStaticPaths context.
   * This will tell us whether we have access to the full request object including, the URL or headers
   * like cookies.
   * @returns Whether the current environment is in static page generation.
   */
  public get isStatic(): boolean {
    if (typeof this._isStatic === 'boolean') return this._isStatic;

    const [req] = CurrentRequestService.tryGet();

    if (!req) {
      // This should not be accessed if from code where a request is not available.
      throw new NoRequestAvailableError(
        'Cannot determine if the current environment is static in the current state.'
      );
    }

    this._isStatic = req.isStatic;

    return this._isStatic;
  }

  /** The base URL for this site in this environment. */
  public readonly baseURL: string;

  /** The current region. */
  public readonly region: Region;

  /** Whether the environment is running in development or production mode. */
  public readonly appBuildType: AppBuildType;

  /** The current build id from the next.config context object. */
  public readonly buildID: string;

  /** Whether the application is running in a local development environment. */
  public readonly isLocalDevelopment: boolean;

  /**
   * Determines if the application is running in an isolated context where portions
   * of the application may not have access to other portions of the application, or
   * where data may not go through the typical expected flows , such as Storybook.
   * It's important to account for this scenarios to ensure that code is able to run
   * without necessarily relying on our framework.
   *
   * @returns Whether the code is currently executing in an isolated environment.
   */
  public get isInIsolatedContext(): boolean {
    return typeof __isolatedContext__ !== 'undefined';
  }

  /**
   * Gets the URL for the current page. If there is a window object, it will use the window location.
   * If it is on the server then it will use the CurrentRequestService.
   * @returns The URL for the current page.
   * @throws {Error} When the URL cannot be determined in the current environment.
   */
  public get url(): URL {
    const urlNotFoundMessage = `Could not determine the URL for the current request in this environment. ${
      // If on the server, give a hint about the potential root cause.
      typeof window === 'undefined'
        ? 'Did you forget to wrap the route in the `middleware` function?'
        : ''
    }`;
    // This getters attempts to ascertain the current URL heuristically in order of
    // which actions are most performant. It will return early when it finds the best
    // candidate. We don't normally cache this value because it may change dynamically.

    // If we're in the browser, use the URL on the `window` object.
    if (typeof window !== 'undefined') return new URL(window.location.href);

    // If we're on the server, get the URL from the current request.
    if (typeof window === 'undefined' && CurrentRequestService.inRequest) {
      const [req, res] = CurrentRequestService.get();
      if (req.url) {
        const { url } = req;
        if (url.startsWith('/')) {
          return new URL(`${this.baseURL}/${url}`.replace(/\/+/g, '/'));
        }

        // Otherwise, return the string URL.
        return new URL(url);
      }
    }

    // We might be in a React DOM rendering environment. Try to use React Router
    try {
      // Use Webpack's dead branch detection to remove this in the client-side.
      if (typeof window === 'undefined') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call -- module has no typed API.
        const Router = getHook<NextRouter>('Router');
        return new URL(`${this.baseURL}${Router.asPath}`);
      }
    } catch (e) {
      // Because this is the last possible action, we throw the same error as below,
      // but with an underlying cause of what exception was thrown while trying to use
      // `Router` attempt for getting the URL.
      throw new Error(urlNotFoundMessage, { cause: e as Error });
    }
    // If the URL could not be retrieved by any means, something ain't right.
    // We're likely in an environment different than those above. May be in a
    // build environment or an API route.
    throw new Error(urlNotFoundMessage);
  }

  /** @inheritdoc */
  public constructor() {
    // Get the application for this environment.
    this.site = SITE_BRAND as Site;

    // Get the brand affiliated with the application.
    this.brand = SiteBrandMap.get(this.site) as Brand;
    if (!SiteBrandMap.has(this.site)) {
      throw new Error(
        `The site ID "${this.site}" is either unknown or does not map to any brands.`
      );
    }

    // Get the current base URL
    this.baseURL = BASE_URL!;

    // Set the execution context based on whether we're on the browser or server
    // based on the presence of the "window" global.
    this.executionContext =
      typeof window === 'undefined'
        ? EnvExecutionContext.Server
        : EnvExecutionContext.Browser;

    this.inBrowser = typeof window !== 'undefined';
    this.onServer = typeof window === 'undefined';

    // Determine the current environment based on existing environment variables.
    this.isProd = false;
    this.isDev = false;
    this.isQA = false;
    this.isUAT = false;

    if (APP_ENV === 'prod') {
      this.environment = Environment.Production;
      this.isProd = true;
    } else if (APP_ENV === 'dev') {
      this.environment = Environment.Development;
      this.isDev = true;
    } else if (APP_ENV === 'qa') {
      this.environment = Environment.QA;
      this.isQA = true;
    } else if (APP_ENV === 'uat') {
      this.environment = Environment.UAT;
      this.isUAT = true;
    } else {
      throw new Error(
        `APP_ENV: '${APP_ENV}' is invalid, valid values: 'prod', 'dev', 'qa', 'uat'.`
      );
    }

    // Sets the region, right now there is only NA.
    if (REGION === 'NA') {
      this.region = Region.NorthAmerica;
    } else {
      this.region = Region.NorthAmerica;
    }

    this.appBuildType = IS_DEVELOPMENT_MODE
      ? AppBuildType.DevelopmentMode
      : AppBuildType.ProductionMode;
    this.buildID = BUILD_ID as string;

    this.isBuilding = BUILD_MODE_ENABLED === 'true' || CI === '1';

    // Determine if it's local development based on the VERCEL and CI environment variables,
    // or if it's running in the browser and the URL hostname is 'localhost' or includes '127.0.0'.
    // On unit tests the location will be unavailable, but there will be a window.
    this.isLocalDevelopment =
      (!VERCEL && !CI) ||
      (typeof window !== 'undefined' &&
        ((url: Nullable<URL>) =>
          url
            ? url.hostname === 'localhost' || url.hostname === '127.0.0'
            : false)(
          window.location?.href ? new URL(window.location?.href) : null
        ));
  }
}

// Normally services should be mocked, but because the environment can't be effectively
// mocked and work as expected, this service does not have a mocked version.
export default new EnvironmentService();
