import { DTO, NumberOrString, NumericString } from '@/type-utils';

import { InvalidArgumentError } from '@/utils/errors';

import Currency from '@/constructs/Currency';
import { Money } from '@dintero/money';

import { action, computed, makeObservable, observable } from 'mobx';
import I18NService from '../../isomorphic/I18NService';
import Model from '../Model';
import type IMoney from './IMoney';
import { MismatchingCurrenciesError } from './MismatchingCurrenciesError';
import Round from './Round';

/**
 * Options for {@link MoneyModel.toFixed}.
 * @default
 *  {
 *    roundingMode: Round.HalfAwayFromZero,
 *    roundingDecimalPlaces: currencyDecimals,
 *    displayDecimalPlaces: roundingDecimalPlaces
 *  }
 */
export interface IToFixedOptions {
  /**
   * The rounding mode to use. By default, this is {@link Round.HalfAwayFromZero}.
   */
  roundingMode: Round;

  /**
   * A nonnegative integer of decimal places to round to.
   * By default, this is equal to the number of decimal places
   * used by the currency.
   *
   * Currently, this value must not exceed {@link MoneyModel.PRECISION}.
   */
  roundingDecimalPlaces: number;

  /**
   * The final number of decimal places to display.
   * By default, this should be equal to {@link roundingDecimalPlaces}.
   * However, it __must not__ be less than {@link roundingDecimalPlaces} to
   * prevent unintentional loss of precision.
   */
  displayDecimalPlaces: number;
}

/**
 * Options for {@link MoneyModel.asUnsafeNumber}.
 * @default
 *  {
 *    roundingMode: Round.HalfAwayFromZero,
 *    roundingDecimalPlaces: currencyDecimals,
 *  }
 */
export type AsUnsafeNumberOptions = Omit<
  IToFixedOptions,
  'displayDecimalPlaces'
>;

/**
 * Options for {@link MoneyModel.toString}.
 * @default
 *  {
 *    roundingMode: Round.HalfAwayFromZero,
 *    roundingDecimalPlaces: currencyDecimals,
 *    displayDecimalPlaces: roundingDecimalPlaces
 *  }
 */
export type ToStringOptions = IToFixedOptions;

/**
 * TODO: Rather than have {@link MoneyModel.toFixed} return just an IMoney,
 * a future enhancement might be to have it return an "immutable MoneyModel".
 * This would preserve the immutable semantics of a rounded amount, while
 * still allowing the caller to use other helpful properties and methods
 * of the MoneyModel class, e.g., {@link MoneyModel.isZero}, {@link MoneyModel.isIntegral}, etc.
 * However, this would require refactoring MoneyModel to extend a base
 * Money class that doesn't "lock" the precision at 10 decimal places.
 *
 * An immutable {@link MoneyModel}.
 *
 * To convert an immutable `MoneyModel` to a regular instance,
 * use {@link MoneyModel.from}.
 */
type ReadonlyMoneyModel = Omit<
  MoneyModel,
  | 'addAmount'
  | 'subtractAmount'
  | 'multiplyBy'
  | 'divideBy'
  | 'negate'
  | 'update'
  | 'copy'
>;

/** A representation of money. */
export type MoneyRepresentation = IMoney | NumberOrString;

/**
 * A model for representing and manipulating money with "arbitrary" precision.
 *
 * The need for this model arises from the fact that JavaScript's
 * `Number` type is limited to 53 bits of precision, which is not
 * enough to represent arbitrary decimal values, even with a low
 * number of decimal places, e.g., 0.1 + 0.2 !== 0.3.
 *
 * "Arbitrary" here does not mean infinite precision, but rather
 * that the precision is not limited by the number of bits in a
 * `Number` value. The precision is *always* **10 decimal places**,
 * which is more than enough to represent any real monetary value.
 * Anything beyond 10 decimal places is automatically truncated.
 *
 * This class features a {@link https://en.wikipedia.org/wiki/Fluent_interface fluent API} for mutating instances, as well
 * as a static set of methods for performing operations which create
 * new instances. Mutability is extremely useful for performance reasons,
 * but it can also be a significant source of bugs when used incorrectly.
 * Therefore, a good rule of thumb is to {@link copy} any instance before it is
 * "borrowed" externally. This will ensure that the owner's instance is not
 * mutated unexpectedly. On the other hand, {@link copy} is not necessary if ownership
 * of the instance is explicitly transferred or shared.
 *
 * Note: The currency of an instance is always immutable.
 * @see {@link MoneyModel.withCurrency} to copy a monetary value to a different currency.
 */
// Under the hood, this class uses @dintero/money to represent money. This choice was made
// based on the library's out-the-box support for high-precision arithmetic and most currencies.
// @dintero/money also uses an immutable API, which is makes it very elegant and simple to wrap around.
export default class MoneyModel extends Model<DTO<IMoney>> implements IMoney {
  @observable private _money: Money;

  /** The number of decimal places used by the currency. */
  private readonly _currencyDecimals: number;

  /** The number of decimal places used internally for calculations. */
  private static readonly PRECISION = 10;

  /**
   * Builds a Money model from a Money representation.
   * @param dto - A Money representation.
   */
  public constructor(dto: DTO<IMoney>) {
    super(dto);

    this._money = MoneyModel._createMoney(dto.amount, dto.currency);
    this._currencyDecimals = MoneyModel.getCurrencyDecimals(dto.currency);

    makeObservable(this);
  }

  /**
   * A string representation of a numerical amount.
   *
   * ⚠️ __Implementation detail__ ⚠️.
   *
   * The amount will always have **10 decimal places**. Use {@link MoneyModel.toFixed()} to get a rounded
   * amount with a specified number of decimal places, or use {@link MoneyModel.toString()} to get a
   * string representation of the amount rounded and formatted for the currency.
   *
   * @returns A string representation of a numerical amount.
   * @example '105.2700000000'
   */
  @computed public get amount(): NumericString {
    return this._money.toString() as NumericString;
  }

  /** @inheritdoc */
  @computed public get currency(): Currency {
    return this._money.currency() as Currency;
  }

  // #region Auxiliary Properties
  /**
   * Returns the number of decimal places used by the currency,
   * as defined by {@link https://www.six-group.com/dam/download/financial-information/data-center/iso-currrency/lists/list-one.xml ISO 4217 }.
   * @returns The number of decimal places used by the currency.
   */
  @computed public get currencyDecimals(): number {
    return this._currencyDecimals;
  }

  /**
   * Returns true if the amount of money is positive.
   *
   * Note: $0.00 is neither negative nor positive!
   *
   * @returns True if the amount of money is positive.
   */
  @computed public get isPositive(): boolean {
    return this._money.isPositive();
  }

  /**
   * Returns true if the amount of money is negative.
   *
   * Note: $0.00 is neither negative nor positive!
   *
   * @returns True if the amount of money is negative.
   */
  @computed public get isNegative(): boolean {
    return this._money.isNegative();
  }

  /**
   * Returns true if the amount of money is zero.
   * @returns True if the amount of money is zero.
   */
  @computed public get isZero(): boolean {
    return this._money.isZero();
  }

  /**
   * Returns true if the current amount is an integer, i.e. it has no non-zero decimal places.
   * @returns True if the current amount is an integer.
   */
  @computed public get isIntegral(): boolean {
    return this._money.setDecimals(0).equals(this._money);
  }
  // #endregion

  /**
   * Creates a new MoneyModel instance from the supplied amount.
   * @param amount - The amount and currency to copy.
   * @returns A new MoneyModel.
   */
  public static fromAmount(amount: IMoney): MoneyModel;

  /**
   * Creates a new MoneyModel instance from the supplied amount.
   * @param amount - The amount to create the MoneyModel from.
   * @param [currency] - The currency to use.
   * @returns A new MoneyModel.
   */
  public static fromAmount(
    amount: NumberOrString,
    currency?: Currency
  ): MoneyModel;

  /**
   * Creates a new MoneyModel instance from the supplied amount.
   * @param amount - The amount to create the MoneyModel from.
   * @param [currency] - The fallback currency to use.
   * @returns A new MoneyModel.
   */
  public static fromAmount(
    amount: MoneyRepresentation,
    currency?: Currency
  ): MoneyModel;

  /**
   * Creates a new MoneyModel instance from the supplied amount.
   *
   * @param amount - The amount to create the MoneyModel from.
   * @param [currency] - The currency to use. Defaults to the current locale's currency.
   * @returns A new MoneyModel.
   * @example
   * const money1 = MoneyModel.fromAmount(100); // $100.00
   * const money2 = MoneyModel.fromAmount('200', ); // $200.00
   * const money3 = MoneyModel.fromAmount({amount: '300', currency: Currency.CAD}); // CA$300.00
   */
  public static fromAmount(
    amount: MoneyRepresentation,
    currency?: Currency
  ): MoneyModel {
    const fallbackCurrency = currency ?? I18NService.currency;
    const money = MoneyModel._asMoney(amount, fallbackCurrency);
    return MoneyModel._fromMoney(money);
  }

  private static _asMoney(amount: IMoney): Money;
  private static _asMoney(amount: NumberOrString, currency: Currency): Money;

  private static _asMoney(
    amount: MoneyRepresentation,
    fallbackCurrency: Currency
  ): Money;

  /**
   * Converts the supplied money representation to a Money object.
   * It will reuse existing Money objects if possible.
   * @param amount - The amount to convert.
   * @param fallbackCurrency - The currency to use for numbers and strings.
   * @returns A {@link Money} object.
   * @throws An {@link InvalidArgumentError} if no currency
   * is supplied when the amount is a number or string.
   */
  private static _asMoney(
    amount: MoneyRepresentation,
    fallbackCurrency?: Currency
  ): Money {
    if (amount instanceof MoneyModel) return amount._money;

    if (typeof amount === 'number' || typeof amount === 'string') {
      if (!fallbackCurrency)
        throw new InvalidArgumentError('No currency given');
      return MoneyModel._createMoney(amount, fallbackCurrency);
    }

    return MoneyModel._createMoney(amount.amount, amount.currency);
  }

  // #region Addition
  /**
   * Adds the supplied monetary representations to this money model.
   * @param firstAmount - The first monetary representation to add.
   * @param amounts - The rest of the monetary representations to add.
   * @returns This money model.
   * @throws A {@link MismatchingCurrenciesError} if the amounts have mismatching currencies.
   */
  @action public addAmount(...amounts: Array<MoneyRepresentation>): this {
    if (amounts.length === 0) return this;

    this._money = MoneyModel._add([this, ...amounts], this.currency);

    return this;
  }

  /**
   * Adds the supplied monetary representations.
   * @param this - Void.
   * @param firstAmount - The first monetary representation of the sum.
   * @param amounts - The rest of the monetary representations to add.
   * @returns A new {@link MoneyModel} with the sum of the supplied amounts.
   * @throws A {@link MismatchingCurrenciesError} if the amounts have mismatching currencies.
   * @example
   * const money1 = MoneyModel.fromAmount(100);
   * const money2 = MoneyModel.fromAmount(200);
   * MoneyModel.add(money1, money2); // $300.00
   * MoneyModel.add(0, ...listOfMoney);
   */
  public static add(
    this: void,
    firstAmount: MoneyRepresentation,
    ...amounts: Array<MoneyRepresentation>
  ): MoneyModel {
    const fallbackCurrency = I18NService.currency;

    const resultMoney = MoneyModel._add(
      [firstAmount, ...amounts],
      fallbackCurrency
    );

    return MoneyModel._fromMoney(resultMoney);
  }

  /**
   * Adds the supplied monetary representations.
   * @param amounts - The monetary representations to add.
   * @param fallbackCurrency - The currency to use for numbers and strings.
   * @returns A new {@link MoneyModel} with the sum of the supplied amounts.
   */
  private static _add(
    amounts: Array<MoneyRepresentation>,
    fallbackCurrency: Currency
  ): Money {
    const amountsAsMoney = amounts.map((amount) =>
      MoneyModel._asMoney(amount, fallbackCurrency)
    );

    _assertSameCurrencies(...amountsAsMoney);

    return Money.sum(amountsAsMoney);
  }
  // #endregion

  // #region Subtraction
  /**
   * Subtracts the supplied monetary representations from this money model.
   * @param amounts - The monetary representations to add.
   * @returns This money model.
   * @throws A {@link MismatchingCurrenciesError} if the amounts have mismatching currencies.
   */
  @action public subtractAmount(...amounts: Array<MoneyRepresentation>): this {
    if (amounts.length === 0) return this;

    this._money = MoneyModel._subtract(this, amounts, this.currency);

    return this;
  }

  /**
   * Subtracts the supplied monetary representations.
   * @param this - Void.
   * @param startAmount - The monetary representation to start with.
   * @param amounts - The monetary representations to subtract.
   * @returns A new {@link MoneyModel} with the cumulative difference of the supplied amounts.
   * @throws A {@link MismatchingCurrenciesError} if the amounts have mismatching currencies.
   * @example
   * const money1 = MoneyModel.fromAmount(100);
   * const money2 = MoneyModel.fromAmount(200);
   * MoneyModel.subtract(money1, money2); // -$100.00
   * MoneyModel.subtract(0, ...listOfMoney);
   */
  public static subtract(
    this: void,
    startAmount: MoneyRepresentation,
    ...amounts: Array<MoneyRepresentation>
  ): MoneyModel {
    const fallbackCurrency = I18NService.currency;

    const resultMoney = MoneyModel._subtract(
      startAmount,
      amounts,
      fallbackCurrency
    );

    return MoneyModel._fromMoney(resultMoney);
  }

  /**
   * Subtracts the supplied monetary representations.
   * @param startAmount - The monetary representation to start with.
   * @param amounts - The monetary representations to subtract.
   * @param fallbackCurrency - The currency to use for numbers and strings.
   * @returns A new {@link MoneyModel} with the sum of the supplied amounts.
   */
  private static _subtract(
    startAmount: MoneyRepresentation,
    amounts: Array<MoneyRepresentation>,
    fallbackCurrency: Currency
  ): Money {
    const startAmountAsMoney = MoneyModel._asMoney(
      startAmount,
      fallbackCurrency
    );

    const amountsAsMoney = amounts.map((amount) =>
      MoneyModel._asMoney(amount, fallbackCurrency)
    );

    _assertSameCurrencies(startAmountAsMoney, ...amountsAsMoney);

    return amountsAsMoney.reduce(
      (acc, amount) => acc.subtract(amount),
      startAmountAsMoney
    );
  }
  // #endregion

  // #region Multiplication
  /**
   * Multiply this money model by the supplied multiplier.
   *
   * Tip: When multiplying by values between 0 and 1, use
   * {@link MoneyModel.divideBy} with the reciprocal value instead.
   *
   * @param multiplier - The multiplier to multiply by.
   * @returns This money model.
   * @example
   * const money = MoneyModel.fromAmount(100);
   * money.multiplyBy(2); // $200.00
   *
   * money.multiplyBy(0.5); // ⚠️
   * money.divideBy(2); // ✅ $100.00
   */
  @action public multiplyBy(multiplier: number): this {
    this._money = this._money.multiply(multiplier);
    return this;
  }

  /**
   * Multiply the supplied multiplicand by the supplied multiplier.
   *
   * Tip: When multiplying by values between 0 and 1, use
   * {@link MoneyModel.divide} with the reciprocal value instead.
   *
   * @param this - Void.
   * @param multiplicand - The multiplicand to multiply.
   * @param multiplier - The multiplier to multiply by.
   * @returns A new {@link MoneyModel} with the amount equal
   * to the product of the supplied multiplicand and multiplier.
   * @example
   * const money = MoneyModel.fromAmount(100);
   * MoneyModel.multiply(money, 2); // $200.00
   *
   * MoneyModel.multiply(money, 0.5); // ⚠️
   * MoneyModel.divide(money, 2); // ✅ $50.00
   */
  public static multiply(
    this: void,
    multiplicand: MoneyRepresentation,
    multiplier: number
  ): MoneyModel {
    const fallbackCurrency = I18NService.currency;
    const money = MoneyModel._asMoney(multiplicand, fallbackCurrency);
    return MoneyModel._fromMoney(money.multiply(multiplier));
  }
  // #endregion

  // #region Division
  /**
   * Divide this money model by the supplied divisor.
   *
   * Note that dividing a monetary amount may result in loss of precision.
   * Use with caution.
   *
   * @param divisor - The divisor to divide by.
   * @returns This money model.
   * @throws An {@link InvalidArgumentError} if the divisor is zero.
   */
  @action public divideBy(divisor: number): this {
    if (divisor === 0) throw new InvalidArgumentError('Cannot divide by zero');
    this._money = this._money.divide(divisor);
    return this;
  }

  /**
   * Divide the supplied dividend by the supplied divisor.
   *
   * Note that dividing a monetary amount may result in loss of precision.
   * Use with caution.
   *
   * @param this - Void.
   * @param dividend - The dividend to divide.
   * @param divisor - The divisor to divide by.
   * @returns A new {@link MoneyModel} with the amount equal
   * to the quotient of the supplied dividend and divisor.
   */
  public static divide(
    this: void,
    dividend: MoneyRepresentation,
    divisor: number
  ): MoneyModel {
    const fallbackCurrency = I18NService.currency;
    const money = MoneyModel._asMoney(dividend, fallbackCurrency);
    return MoneyModel._fromMoney(money.divide(divisor));
  }
  // #endregion

  // #region Negation
  /**
   * Negates this money model's amount.
   * @returns This money model.
   */
  @action public negate(): this {
    this._money = this._money.multiply(-1);
    return this;
  }

  /**
   * Negates the supplied money representation's amount.
   * @param this - Void.
   * @param amount - The money representation to negate.
   * @returns A new {@link MoneyModel} with the negated amount.
   */
  public static negate(this: void, amount: MoneyRepresentation): MoneyModel {
    const fallbackCurrency = I18NService.currency;
    const money = MoneyModel._asMoney(amount, fallbackCurrency);
    return MoneyModel._fromMoney(money.multiply(-1));
  }
  // #endregion

  /**
   * Returns a copy of this money model. Equivalent to calling `MoneyModel.fromAmount(this)`.
   * @returns A copy of this money model.
   */
  public copy(): MoneyModel {
    return MoneyModel.fromAmount(this);
  }

  // #region Comparison
  /**
   * Determines if this money model is greater than the supplied money representation.
   * @param amount - The money representation to compare to.
   * @returns True if this money model is greater than the supplied money representation.
   * @throws A {@link MismatchingCurrenciesError} if the amounts have mismatching currencies.
   */
  public isGreaterThan(amount: MoneyRepresentation): boolean {
    const money = MoneyModel._asMoney(amount, this.currency);
    _assertSameCurrencies(this._money, money);

    return this._money.greaterThan(money);
  }

  /**
   * Determines if this money model is greater than or equal to the supplied money representation.
   * @param amount - The money representation to compare to.
   * @returns True if this money model is greater than or equal to the supplied money representation.
   * @throws A {@link MismatchingCurrenciesError} if the amounts have mismatching currencies.
   */
  public isGreaterThanOrEqualTo(amount: MoneyRepresentation): boolean {
    const money = MoneyModel._asMoney(amount, this.currency);
    _assertSameCurrencies(this._money, money);

    return this._money.greaterThanOrEqual(money);
  }

  /**
   * Determines if this money model is less than the supplied money representation.
   * @param amount - The money representation to compare to.
   * @returns True if this money model is less than the supplied money representation.
   * @throws A {@link MismatchingCurrenciesError} if the amounts have mismatching currencies.
   */
  public isLessThan(amount: MoneyRepresentation): boolean {
    const money = MoneyModel._asMoney(amount, this.currency);
    _assertSameCurrencies(this._money, money);

    return this._money.lessThan(money);
  }

  /**
   * Determines if this money model is less than or equal to the supplied money representation.
   * @param amount - The money representation to compare to.
   * @returns True if this money model is less than or equal to the supplied money representation.
   * @throws A {@link MismatchingCurrenciesError} if the amounts have mismatching currencies.
   */
  public isLessThanOrEqualTo(amount: MoneyRepresentation): boolean {
    const money = MoneyModel._asMoney(amount, this.currency);
    _assertSameCurrencies(this._money, money);

    return this._money.lessThanOrEqual(money);
  }

  /**
   * Determines if this money model is equal to the supplied money representation.
   *
   * Note: This method is agnostic of the precision used to represent the amounts
   * e.g., `1.00` is considered equal to `1.0000000000`.
   *
   * @param amount - The money representation to compare to.
   * @returns True if this money model is equal to the supplied money representation.
   * @throws A {@link MismatchingCurrenciesError} if the amounts have mismatching currencies.
   */
  public isEqualTo(amount: MoneyRepresentation): boolean {
    const money = MoneyModel._asMoney(amount, this.currency);
    _assertSameCurrencies(this._money, money);

    return this._money.equals(money);
  }

  /**
   * Compares two money representations. This method can be used as a comparator
   * function for sorting arrays of money representations.
   * @param this - Void.
   * @param amountA - The first money representation to compare.
   * @param amountB - The second money representation to compare.
   * @returns A negative number if `a` is less than `b`, a positive
   * number if `a` is greater than `b`, or 0 if `a` is equal to `b`.
   * @throws A {@link MismatchingCurrenciesError} if the amounts have mismatching currencies.
   * @example
   * const money1 = MoneyModel.fromAmount(100);
   * const money2 = MoneyModel.fromAmount(200);
   * MoneyModel.compare(money1, money2); // -1
   *
   * const moneyArr = [money2, money1];
   * moneyArr.sort(MoneyModel.compare); // [money1, money2]
   */
  public static compare(
    this: void,
    amountA: MoneyRepresentation,
    amountB: MoneyRepresentation
  ): number {
    const fallbackCurrency = I18NService.currency;
    const moneyA = MoneyModel._asMoney(amountA, fallbackCurrency);
    const moneyB = MoneyModel._asMoney(amountB, fallbackCurrency);
    _assertSameCurrencies(moneyA, moneyB);

    return Money.compare(moneyA, moneyB);
  }

  /**
   * Returns the minimum of the supplied money representations.
   *
   * This method makes no guarantees about which instance is returned if
   * multiple instances are equal to the minimum value.
   *
   * @param this - Void.
   * @param firstAmount - The first money representation to compare.
   * @param amounts - The rest of the money representations to compare.
   * @returns The minimum of the supplied money representations.
   * @throws A {@link MismatchingCurrenciesError} if the amounts have mismatching currencies.
   */
  // Unlike most other methods, this one only works with MoneyModel instances because
  // it's expected to return a reference to the original value, and returning
  // something other than a MoneyModel instance would be inconsistent with the API's design.
  public static min(
    this: void,
    firstAmount: MoneyModel,
    ...amounts: Array<MoneyModel>
  ): MoneyModel {
    // not the most efficient implementation, but it's simple and works
    const sortedIncreasing = [firstAmount, ...amounts].sort(MoneyModel.compare);
    return sortedIncreasing[0];
  }

  /**
   * Returns the maximum of the supplied money representations.
   *
   * This method makes no guarantees about which instance is returned if
   * multiple instances are equal to the maximum value.
   *
   * @param this - Void.
   * @param firstAmount - The first money representation to compare.
   * @param amounts - The rest of the money representations to compare.
   * @returns The maximum of the supplied money representations.
   * @throws A {@link MismatchingCurrenciesError} if the amounts have mismatching currencies.
   */
  // Unlike most other methods, this one only works with MoneyModel instances because
  // it's expected to return a reference to the original value, and returning
  // something other than a MoneyModel instance would be inconsistent with the API's design.
  public static max(
    this: void,
    firstAmount: MoneyModel,
    ...amounts: Array<MoneyModel>
  ): MoneyModel {
    // not the most efficient implementation, but it's simple and works
    const sortedIncreasing = [firstAmount, ...amounts].sort(MoneyModel.compare);
    return sortedIncreasing[sortedIncreasing.length - 1];
  }
  // #endregion

  // #region Formatting and Rounding
  /**
   * Returns an immutable IMoney with the amount rounded according
   * to the given options. If omitted, the amount will be rounded
   * {@link Round.HalfAwayFromZero "half-up"} to the currency's default number of decimal places.
   *
   * For all intents and purposes, this method provides a "low-level"
   * behavior. Unless you are calling the default implementation,
   * it should only be used by higher-level wrapper methods.
   *
   * @param [options] - Partial {@link IToFixedOptions} for rounding and formatting the amount.
   * @returns An immutable IMoney with the amount rounded to the specified number of decimal places.
   * @throws An {@link InvalidArgumentError} if the {@link IToFixedOptions.roundingDecimalPlaces} option
   * is less than 0 or greater than {@link MoneyModel.PRECISION}.
   * @throws An {@link InvalidArgumentError} if the {@link IToFixedOptions.roundingDecimalPlaces} option is not an integer.
   * @example
   * const money = MoneyModel.fromAmount(1.567, 'USD');
   * money.toFixed(); // $1.57
   * money.toFixed({ roundingDecimalPlaces: 0 }); // $2
   * money.toFixed({ roundingMode: Round.TowardZero, roundingDecimalPlaces: 0 }); // $1
   * money.toFixed({ roundingMode: Round.AwayFromZero, roundingDecimalPlaces: 1, displayDecimalPlaces: 2 }); // $1.60
   */
  public toFixed(options?: Partial<IToFixedOptions>): Readonly<IMoney> {
    const resolvedOptions = this._resolveToFixedOptions(options);
    return this._toFixed(resolvedOptions);
  }

  /**
   * A version of {@link MoneyModel.toFixed} that skips validations.
   * For internal use only.
   *
   * @param options - Options for rounding the amount.
   * @returns An immutable IMoney with the amount rounded to the specified number of decimal places.
   */
  private _toFixed({
    roundingMode,
    roundingDecimalPlaces,
    displayDecimalPlaces
  }: IToFixedOptions): Readonly<IMoney> {
    return {
      amount: this._money
        .round(roundingDecimalPlaces, roundingMode)
        .setDecimals(displayDecimalPlaces)
        .toString() as NumericString,
      currency: this.currency
    };
  }

  /**
   * Resolves the options for {@link MoneyModel.toFixed}, using defaults as needed.
   * @param [options] - Partial {@link IToFixedOptions} for rounding and formatting the amount.
   * @returns The resolved options.
   * @throws An {@link InvalidArgumentError} if the {@link IToFixedOptions.roundingDecimalPlaces} option
   * is less than 0 or greater than {@link MoneyModel.PRECISION}.
   * @throws An {@link InvalidArgumentError} if the {@link IToFixedOptions.roundingDecimalPlaces} option is not an integer.
   */
  private _resolveToFixedOptions(
    options?: Partial<IToFixedOptions>
  ): IToFixedOptions {
    const defaultOptions: IToFixedOptions = {
      roundingMode: Round.HalfAwayFromZero,
      roundingDecimalPlaces: this._currencyDecimals,
      displayDecimalPlaces: this._currencyDecimals
    };

    if (!options) return defaultOptions;

    const roundingMode = options?.roundingMode ?? defaultOptions.roundingMode;
    const roundingDecimalPlaces =
      options?.roundingDecimalPlaces ?? defaultOptions.roundingDecimalPlaces;
    // by default, this should always match `roundingDecimalPlaces`, hence
    // we don't use `defaultOptions.displayDecimalPlaces` here.
    const displayDecimalPlaces =
      options?.displayDecimalPlaces ?? roundingDecimalPlaces;

    if (!Number.isInteger(roundingDecimalPlaces)) {
      throw new InvalidArgumentError(
        `The decimalPlaces option must be an integer, got ${roundingDecimalPlaces}`
      );
    }

    if (
      roundingDecimalPlaces < 0 ||
      roundingDecimalPlaces > MoneyModel.PRECISION
    ) {
      throw new InvalidArgumentError(
        `The decimalPlaces option must be between 0 and ${MoneyModel.PRECISION}, got ${roundingDecimalPlaces}`
      );
    }

    if (!Number.isInteger(displayDecimalPlaces)) {
      throw new InvalidArgumentError(
        `The finalDecimalPlaces option must be an integer, got ${displayDecimalPlaces}`
      );
    }

    if (displayDecimalPlaces < roundingDecimalPlaces) {
      throw new InvalidArgumentError(
        `The finalDecimalPlaces option must be greater than or equal to decimalPlaces to prevent unintentional loss of precision, got ${displayDecimalPlaces}`
      );
    }

    return {
      roundingMode,
      roundingDecimalPlaces,
      displayDecimalPlaces
    };
  }

  /**
   * Returns a string representation of the monetary value formatted for the currency.
   * This includes the currency symbol and separators.
   * By default, the underlying amount is rounded to the currency's number of
   * decimal places, however this can be overridden with the `options` parameter.
   *
   * @param [options] - Partial {@link ToStringOptions} for rounding and formatting the amount.
   * @returns A string representation of the monetary value formatted for the currency.
   * @example
   * MoneyModel.fromAmount(1.567, 'USD').toString() // '$1.57'
   * MoneyModel.fromAmount(1000, 'USD').toString() // '$1,000.00'
   * MoneyModel.fromAmount(0, 'USD').toString() // '$0.00'
   */
  public override toString(options?: Partial<ToStringOptions>): string {
    const resolvedOptions = this._resolveToFixedOptions(options);
    const fixedMoney = this._toFixed(resolvedOptions);

    return Intl.NumberFormat(I18NService.currentLocale.toString(), {
      style: 'currency',
      currency: this.currency,
      minimumFractionDigits: resolvedOptions.displayDecimalPlaces
    }).format(fixedMoney.amount);
  }

  /**
   * Returns the current amount as a real {@link Number}. Optionally accepts options for
   * rounding the amount. Use an empty object for the default rounding behavior.
   *
   * ⚠️ __Warning__ ⚠️.
   *
   * This method is inherently unsafe because it may result in loss of precision,
   * even with explicit rounding. Use with extreme caution.
   *
   * @param this - Void.
   * @param money - The `IMoney` to convert.
   * @param [options] - Options for rounding the amount.
   * @returns The amount as a real {@link Number}, rounded to the specified number of decimal places.
   * @example
   * const money = MoneyModel.fromAmount(1.567, 'USD');
   * MoneyModel.asUnsafeNumber(money); // 1.567
   * MoneyModel.asUnsafeNumber(money, {}); // 1.57
   * MoneyModel.asUnsafeNumber(money, { roundingDecimalPlaces: 0 }); // 2
   * MoneyModel.asUnsafeNumber(money, { roundingMode: Round.TowardZero, roundingDecimalPlaces: 0 }); // 1
   * MoneyModel.asUnsafeNumber(money, { roundingMode: Round.AwayFromZero, roundingDecimalPlaces: 1 }); // 1.6
   */
  public static asUnsafeNumber(
    this: void,
    money: IMoney,
    options?: Partial<AsUnsafeNumberOptions>
  ): number {
    if (!options) return Number(money.amount);
    return Number(MoneyModel.from(money).toFixed(options).amount);
  }
  // #endregion

  /**
   * Returns a new MoneyModel with the supplied amount and currency.
   *
   * This differs from {@link MoneyModel.fromAmount} in that it will
   * create a new MoneyModel with the supplied currency, rather than using
   * the existing currency of an IMoney.
   *
   * @param this - Void.
   * @param money - The money representation whose amount to copy.
   * @param currency - The currency to use.
   * @returns A new MoneyModel with the supplied amount and currency.
   */
  public static withCurrency(
    this: void,
    money: MoneyRepresentation,
    currency: Currency
  ): MoneyModel {
    let _money: Money;

    if (typeof money === 'object') {
      _money = MoneyModel._createMoney(money.amount, currency);
    } else {
      _money = MoneyModel._createMoney(money, currency);
    }

    return MoneyModel._fromMoney(_money);
  }

  /**
   * Returns the number of decimal places used by the given currency,
   * as defined by {@link https://www.six-group.com/dam/download/financial-information/data-center/iso-currrency/lists/list-one.xml ISO 4217 }.
   * @param this - Void.
   * @param currency - The currency to use.
   * @returns The number of decimal places used by the given currency.
   */
  public static getCurrencyDecimals(this: void, currency: Currency): number {
    /**
     * Unfortunately, it seems deriving this value via {@link Intl.NumberFormat.resolvedOptions}
     * is unreliable in some JS engines (e.g. V8 returns 0 for IQD instead of 3).
     * So we have to rely on @dintero/money's hard-coded mapping instead.
     *
     * @see {@link https://github.com/freeall/currency-codes/blob/master/data.js Hard-coded mapping }
     */

    return Money.of(0, currency).getDecimals();
  }

  /**
   * @inheritDoc
   * @throws A {@link MismatchingCurrenciesError} if the currency of
   * the DTO<IMoney> doesn't match the currency of this model.
   */
  @action public override update(dto: DTO<IMoney>): void {
    const money = MoneyModel._createMoney(dto.amount, dto.currency);
    _assertSameCurrencies(this._money, money);

    this._money = money;
  }

  /**
   * Creates a new Money instance from the supplied amount and currency.
   *
   * This method centralizes how we initialize Money instances so that
   * we can more easily change it in the future, if needed.
   *
   * @param this - Void.
   * @param amount - The amount to use.
   * @param currency - The currency to use.
   * @returns A new Money instance.
   * @throws An {@link InvalidArgumentError} if the amount is an empty string.
   * @throws An {@link InvalidArgumentError} if the amount is `NaN`.
   * @throws An {@link InvalidArgumentError} if the amount is not finite.
   */
  private static _createMoney(
    this: void,
    amount: NumberOrString,
    currency: Currency
  ): Money {
    const amountStr = assertAmountIsValid(amount);
    return Money.of(amountStr, currency, {
      decimals: MoneyModel.PRECISION,
      roundingMode: Round.TowardZero // truncates anything past 10 decimal places
    });
  }

  /**
   * Builds a {@link MoneyModel} from a {@link Money} object.
   * @param this - Void.
   * @param money - The {@link Money} object to use.
   * @returns The {@link MoneyModel}.
   */
  private static _fromMoney(this: void, money: Money): MoneyModel {
    const result = new MoneyModel({
      // default values that will immediately be overwritten
      amount: '0',
      currency: money.currency() as Currency
    });

    result._money = money;

    return result;
  }

  /**
   * Converts this model to a JSON representation.
   *
   * Note: This method is automatically called when using `JSON.stringify()`, and
   * ensures the model is properly serialized even if the developer forgets to call
   * {@link MoneyModel.toDTO} explicitly before sending it to the server.
   *
   * @returns A JSON representation of this model.
   */
  public toJSON(): DTO<IMoney> {
    return this.toDTO();
  }

  /** @inheritDoc */
  public override toDTO(): DTO<IMoney> {
    return {
      amount: this.amount,
      currency: this.currency
    } as DTO<IMoney>;
  }
}

/**
 * Asserts that all supplied amounts have the same currency.
 * @param amounts - The amounts to check.
 * @throws A {@link InvalidArgumentError} if no amounts are given.
 * @throws A {@link MismatchingCurrenciesError} if the amounts have mismatching currencies.
 */
function _assertSameCurrencies(...amounts: Array<Money>): void {
  if (amounts.length === 0) throw new InvalidArgumentError('No amounts given');

  const firstCurrency = amounts[0].currency();
  if (amounts.some((amount) => amount.currency() !== firstCurrency)) {
    throw new MismatchingCurrenciesError(
      'Cannot perform operation on money with mismatching currencies'
    );
  }
}

/**
 * Asserts that the supplied amount is represents a real monetary value.
 * @param amount - The amount to check.
 * @returns The amount as a numeric string.
 * @throws An {@link InvalidArgumentError} if the amount is an empty string.
 * @throws An {@link InvalidArgumentError} if the amount is `NaN` or not finite.
 */
function assertAmountIsValid(amount: string | number): NumericString {
  if (typeof amount === 'string') {
    amount = amount.trim(); // we use trim() to ensure that the string is not empty

    if (amount === '')
      throw new InvalidArgumentError('Amount cannot be an empty string');
  }

  // we use Number() instead of Number.parseFloat() because the former
  // will reject strings that contain any invalid characters, while the
  // latter will parse as much as it can and ignore the rest.
  const amountAsNumber = Number(amount);

  // checks for `Infinity` or `NaN`
  if (!Number.isFinite(amountAsNumber)) {
    throw new InvalidArgumentError(
      `Amount must be a finite number, got ${amount}`
    );
  }

  return amount as NumericString;
}
