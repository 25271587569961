import config from '../config';
import { SAFETECH_API_PASSWORD } from '../env/server';

const safetech = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      baseURL: {
        default: 'https://orbitalvar1.chasepaymentech.com/gwapi/v4/gateway',
        prod: 'https://orbital1.chasepaymentech.com/gwapi/v4/gateway'
      },
      encryptionURL: {
        default:
          'https://safetechpageencryptionvar.chasepaymentech.com/pie/v1/encryption.js',
        prod: 'https://safetechpageencryption.chasepaymentech.com/pie/v1/encryption.js'
      },
      keyURL: {
        default:
          'https://safetechpageencryptionvar.chasepaymentech.com/pie/v1/64100000000114/getkey.js',
        prod: 'https://safetechpageencryption.chasepaymentech.com/pie/v1/64100000000114/getkey.js'
      },
      headers: {
        'Content-Type': 'application/json',
        OrbitalConnectionUsername: {
          default: 'TEST8335DECK',
          prod: 'DECKERSCO7581610728'
        },
        OrbitalConnectionPassword: SAFETECH_API_PASSWORD as string,
        /**
         * This value is set by Currency code.
         *  "EUR": "174566",
         *  "GBP": "058448",
         *  "USD": "078335",
         *  "CAD": "198550",.
         */
        MerchantID: '078335'
      },
      subscriberId: '100000000114'
    }
  }
});

export default safetech;
