import { mocked } from '@/configs';
import { Locale } from '@/constructs/Locale';
import ShippingMethod from '@/constructs/ShippingMethod';
import type { CartModel, ICart } from '@/services/models/Cart';
import {
  IShippingMethod,
  ShippingMethodModel
} from '@/services/models/ShippingMethod';
import type { Constructor, DTO } from '@/type-utils';
import { InvalidStateError } from '@/utils/errors';
import { isPOBoxLine } from '@/utils/string-utils';
import { IAddress } from '@/services/models/Address';
import { Brand } from '@/constructs/Brand';
import MockService, { MockState, ServiceMock } from '../MockService';
import type { ShippingMethodService } from './ShippingMethodService';
import { ShippingMethodNotFoundError } from './errors';
import I18NService from '../I18NService';

const initialState = {};

const MOCK_METHODS = [
  {
    id: 'ST2',
    uid: 'a12zqsvev8iycpljra8umfkf',
    country: 'US',
    brand: 'AHNU',
    name: 'Expedited Shipping',
    description: 'Standard 2 Day',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '17',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0,
    earliestDeliveryDays: 2,
    latestDeliveryDays: 3
  },
  {
    id: 'SFS',
    uid: 'dfwtr9mv8odhdv55b1g4rzfq',
    country: 'US',
    site: 'AHNU',
    name: 'SFS',
    description: 'SFS',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '0',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0
  },
  {
    id: 'Ontrac2Day',
    uid: 'ffnzxcl4t42nvkiw7vfoas42',
    country: 'US',
    site: 'AHNU',
    name: 'Ontrac2Day',
    description: 'Ontrac2Day',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '0',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0
  },
  {
    id: 'Same Day Ship Methods (SDD)',
    uid: 'g338ghat15wpqweta2omq53r',
    country: 'US',
    site: 'AHNU',
    name: 'Same Day Ship Methods (SDD)',
    description: 'Same Day Ship Methods (SDD)',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '0',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0
  },
  {
    id: 'USPS Express',
    uid: 'h13zkbhec0yi20h11efcrauy',
    country: 'US',
    site: 'AHNU',
    name: 'USPS Express',
    description: 'USPS Express',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '0',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0
  },
  {
    id: 'OntracSunrise',
    uid: 'i13n7lrkf0uai46j179jja46',
    country: 'US',
    site: 'AHNU',
    name: 'OntracSunrise',
    description: 'OntracSunrise',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '0',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0
  },
  {
    id: 'U43',
    uid: 'loxa3jpaktgig6arqpx7qksg',
    country: 'US',
    site: 'AHNU',
    name: 'U43',
    description: 'U43',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '0',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0
  },
  {
    id: 'UPSGround',
    uid: 'm11bs4bp8ye3gl4s7d3w8owx',
    country: 'US',
    site: 'AHNU',
    name: 'UPS Ground',
    description: 'UPSGround',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '0',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0
  },
  {
    id: 'S2S',
    uid: 'nj98m2plpzd101acmftr3ga5',
    country: 'US',
    site: 'AHNU',
    name: 'Ship to Store',
    description: 'S2S',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '0',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0
  },
  {
    id: 'USPS Ground',
    uid: 'ofewdejr4tt7q0zosp81iz5c',
    country: 'US',
    site: 'AHNU',
    name: 'USPS Ground',
    description: 'USPS Ground',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '0',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0
  },
  {
    id: 'USPS Parcel Select',
    uid: 'p5s837kb1ndfvwpu074ac0x1',
    country: 'US',
    site: 'AHNU',
    name: 'USPS Parcel Select',
    description: 'USPS Parcel Select',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '0',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0
  },
  {
    id: 'STG2',
    uid: 'q67jp4s33gn60oglnsuczgsh',
    country: 'US',
    site: 'AHNU',
    name: 'Standard Shipping',
    description: 'Standard Ground',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '10',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0,
    earliestDeliveryDays: 3,
    latestDeliveryDays: 6
  },
  {
    id: 'STO',
    uid: 'r14c7nztzmusds06lagst6j4',
    country: 'US',
    site: 'AHNU',
    name: 'Fastest Shipping',
    description: 'Standard Overnight',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '28',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0,
    earliestDeliveryDays: 1,
    latestDeliveryDays: 2
  },
  {
    id: 'USPS Priority',
    uid: 's44mtsjtnyjgcwlnx6xcgktg',
    country: 'US',
    site: 'AHNU',
    name: 'USPS Priority',
    description: 'USPS Priority',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '0',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0
  },
  {
    id: 'STG',
    uid: 't9ahyc85zyxa2vxixvzc9aug',
    country: 'US',
    site: 'AHNU',
    name: 'Standard Shipping',
    description: 'Standard Ground',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '0',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0,
    earliestDeliveryDays: 3,
    latestDeliveryDays: 6
  },
  {
    id: 'SPU',
    uid: 'u13bh7cxacxn3xkz1o7r6aw1',
    country: 'US',
    site: 'AHNU',
    name: 'SPU',
    description: 'SPU',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '0',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0
  },
  {
    id: 'UPS2Day',
    uid: 'ur7u7rlkso2qb63fka2fndof',
    country: 'US',
    site: 'AHNU',
    name: 'UPS2Day',
    description: 'UPS2Day',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '0',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0
  },
  {
    id: 'OntracGold',
    uid: 'vlfgq2xqfydh4quwzsc5msop',
    country: 'US',
    site: 'AHNU',
    name: 'OntracGold',
    description: 'OntracGold',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '0',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0
  },
  {
    id: 'UPS3DaySelect',
    uid: 'x37c8npfqo7nwv4i2ixxltn3',
    country: 'US',
    site: 'AHNU',
    name: 'UPS3DaySelect',
    description: 'UPS3DaySelect',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '0',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0
  },
  {
    id: 'SFS Standard International delivery',
    uid: 'z3rzyggduxgse14lgqqqjyyl',
    country: 'US',
    site: 'AHNU',
    name: 'SFS Standard International delivery',
    description: 'SFS Standard International delivery',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '75',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0
  },
  {
    id: 'Ontrac1Day',
    uid: 'zamzzb0oqdk4jb9iwgn0qrm8',
    country: 'US',
    site: 'AHNU',
    name: 'Ontrac1Day',
    description: 'Ontrac1Day',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '0',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0
  },
  {
    id: 'UPR',
    uid: 'zzuuyevzl514m016oznbef0o',
    country: 'US',
    site: 'AHNU',
    name: 'UPR',
    description: 'UPR',
    cutoffTime: '20:00:00',
    shippingCost: {
      amount: '0',
      currency: 'USD'
    },
    tax: {
      amount: '0',
      currency: 'USD'
    },
    taxRate: 0
  }
] as Array<DTO<IShippingMethod>>;

/**
 * Mock for {@link ShippingMethodService}.
 */
export default class ShippingMethodServiceMock extends ServiceMock<ShippingMethodService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): ShippingMethodService {
    return MockService.getMockOf(this.service) as ShippingMethodService;
  }

  /**
   * The constructor to initialize the mocks.
   *  @param service - The service for this mock, used to remove a circular dependency.
   */
  public constructor(private service: Constructor<ShippingMethodService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(this.service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(
    service: Constructor<ShippingMethodService>
  ): void {
    const mockEnabled: boolean = mocked.ShippingMethodService;

    MockService.mockService(
      mockEnabled,
      service,
      {
        getShippingMethodByUID: (uid: string) => {
          const foundMethod = MOCK_METHODS.find((method) => method.uid === uid);

          if (!foundMethod) {
            throw new ShippingMethodNotFoundError(
              `Shipping method "${uid}" was not found.`
            );
          }

          return Promise.resolve(ShippingMethodModel.from(foundMethod));
        },

        getShippingMethodsByBrandAndLocale: (
          brand: Brand = Brand.Ahnu,
          locale: Locale = Locale.US
        ) => {
          const filteredMethods = MOCK_METHODS.filter(
            (shippingMethod) =>
              shippingMethod.brand === brand && shippingMethod.country === locale
          );

          return Promise.resolve(
            filteredMethods.map((method) => ShippingMethodModel.from(method))
          );
        },

        getApplicableShippingMethodsForCart: (cart: ICart) => {
          // Return the default set of methods
          const applicableMethodIDs = [
            ShippingMethod.ST2,
            ShippingMethod.STO,
            ShippingMethod.STG
          ];

          const applicableMethods = MOCK_METHODS.filter((method) =>
            applicableMethodIDs.includes(method.id as ShippingMethod)
          );

          return Promise.resolve(
            applicableMethods.map((method) => ShippingMethodModel.from(method))
          );
        },

        getBestShippingMethod: (methods: Array<ShippingMethodModel>) => {
          const stgMethod = MOCK_METHODS.find(
            (method) => (method.id as ShippingMethod) === ShippingMethod.STG
          );

          if (!stgMethod) {
            throw new InvalidStateError(
              `Shipping method "${ShippingMethod.STG}" does not exist in` +
                ' the current mock, but it is required.'
            );
          }

          return ShippingMethodModel.from(stgMethod);
        },

        isAddressPoBox: (address: IAddress): boolean => {
          const { addressLine1, addressLine2 } = address;

          if (isPOBoxLine(addressLine1)) {
            return true;
          }

          if (addressLine2 && isPOBoxLine(addressLine2)) {
            return true;
          }

          return false;
        },

        isAddressWithinCurrentCountry: (address: IAddress): boolean => {
          const currentLocale = I18NService.currentLocale.locale;
          return address.country === currentLocale;
        },

        cartHasAllItemsDiscounted: (cart: CartModel): boolean => {
          // If there are no items, return `false`.
          // This is to avoid having STG2 on first render and then changing to STG.
          if (cart.items.length === 0) {
            return false;
          }

          for (const lineItem of cart.items) {
            // If there is at least one item at full price...
            if (lineItem.netTotal.isGreaterThanOrEqualTo(lineItem.subtotal)) {
              return false;
            }
          }

          return true;
        }
      },
      {},
      this.state
    );
  }
}
