/* eslint-disable filenames/match-exported -- Needs to alias the default export */
import { Language } from '@/constructs/Language';
import { Locale } from '@/constructs/Locale';
import {
  SiteLocale,
  regionMappings,
  type ILangLocale,
  type LangLocale
} from '@/constructs/LocaleSchema';
import { Region } from '@/constructs/Region';
import type lang from '@/lang';
import type { Resource } from '@/lang';
import ResourceStrings from '@/lang/ResourceStrings';
import { Nullable, PartialRecord, Primitive } from '@/type-utils';
import type { Path as PathType, PathValue } from '@/type-utils/Path';
import MemoryCache, { GetMethod } from '@/utils/MemoryCache';
import StaleWhileRevalidate from '@/utils/StaleWhileRevalidate';
import { LikelyMisconfigurationError } from '@/utils/errors';
import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';
import IntlMessageFormat from 'intl-messageformat';
import type { NextRouter } from 'next/router';
import type { ReactElement } from 'react';
import type { Currency } from '../../models/Money';
import { getHook } from '../../utils/react-utils/hook-utils';
import CurrentRequestService from '../CurrentRequestService';
import { EnvironmentService } from '../EnvironmentService';

/**
 * A type alias for the {@link lang} object.
 */
export type StringContainer = typeof lang;

/**
 * Provides data relating to internationalization and localization within the context of the
 * current site and locale.
 */
class I18NService {
  private _resourceStringCache: Nullable<MemoryCache<ResourceStrings>>;
  private _currency: Currency = 'USD' as Currency;

  /** Effectively a cache for ILangLocale objects. */
  private _langLocaleRecord: PartialRecord<
    LangLocale | Language | Locale,
    ILangLocale
  > = {};

  /**
   * Sets up the resource string cache when it is requested.
   * This keeps it from running too early.
   * @returns The memory cache associated with the service.
   */
  private get resourceStringCache(): MemoryCache<ResourceStrings> {
    if (!this._resourceStringCache) {
      this._resourceStringCache = new MemoryCache<ResourceStrings>();
    }

    return this._resourceStringCache;
  }

  /**
   * The site currency hard coded to USD.
   * @returns The currency that is currently used on the site.
   */
  public get currency(): Currency {
    return this._currency;
  }

  /**
   * Gets the current locale and language as an object.
   * @returns The current language and locale for the current request.
   * `toString()` can be called on this value to get a string representation.
   */
  public get currentLocale(): ILangLocale {
    // If we're in an isolated environment, such as Storybook, do not attempt to
    // obtain the locale from any of the normal sources. Instead, use `en-US` as the default.
    if ((typeof __isolatedContext__ !== "undefined")) {
      return this.getLangLocaleFromString('en-US' as LangLocale);
    }

    // If we're on the server, get the locale from the current request.
    if ((typeof window === "undefined") && CurrentRequestService.inRequest) {
      const [requestObject] = CurrentRequestService.tryGet();
      if (requestObject) {
        const { locale } = requestObject;

        if (locale) {
          return this.getLangLocaleFromString(locale as LangLocale);
        }
      }
    }

    // Attempt to get the locale from `useRouter` - this will not work in some environments.
    try {
      const Router = getHook<NextRouter>('Router');
      return this.getLangLocaleFromString(Router.locale as LangLocale);
    } catch (e) {
      // If `useRouter` failed, then we're unable to determine the locale.
      throw new Error(
        `Could not determine the locale for the current environment.`,
        { cause: e as Error }
      );
    }
  }

  /**
   * Gets the current {@link Region} based on the current {@see Locale}.
   * @returns The current {@link Region}.
   * @example
   * I18NService.currentRegion; // 'NA' when the current locale is 'en-US'.
   */
  public get currentRegion(): Region {
    return this.getRegionOfLocale(this.currentLocale.locale);
  }

  /**
   * Gets the current Site ID.
   * @returns A string of the current brand hyphenated with the current locale.
   * @example
   * 'SANUK-US'
   * 'HOKA-UK'
   */
  public get siteID(): SiteLocale {
    const { brand } = EnvironmentService;
    const { locale } = this.currentLocale;
    return `${brand}-${locale}`;
  }

  /**
   * Gets an object representing both the language and locale given a `LangLocale`
   * formatted string.
   * @param str - A string in the `${Language}-${Locale}` format.
   * @returns An object with a `language` and `locale` members.
   * @throws `InvalidArgumentError` if the passed in `str` is not a valid locale string.
   */
  public getLangLocaleFromString(
    str: LangLocale | Language | Locale
  ): ILangLocale {
    // Bad type casts can allow non-strings to come in. In those cases, throw an error
    // explaining what went wrong.
    if (typeof str !== 'string') {
      throw new InvalidArgumentError(
        `Value passed to parameter \`str\` must be type \`string\`, got value "${str}".`
      );
    }

    // Re-use the object if possible since we'll likely read the value more often than we'll rewrite it.
    const cachedILangLocale = this._langLocaleRecord[str];
    if (cachedILangLocale) {
      return cachedILangLocale;
    }

    let [language, locale] = str.split('-');
    const [defaultLang, defaultLocale] = ['en', 'US'];

    // If locale is undefined, the string was only one part, rather than two.
    if (!locale) {
      // If the `language` variable was indeed a language, fallback to the default locale.
      if (Object.values(Language).includes(language as Language)) {
        locale = defaultLocale;
      }
      // Otherwise, see if the `language` variable is a locale instead.
      else if (Object.values(Locale).includes(language as Locale)) {
        // Fix the variable assignments to be the correct values.
        locale = language;
        language = defaultLang;
      }
      // Else, this string is bogus. Throw.
      else {
        throw new InvalidArgumentError(
          `\`getLangLocaleFromString\` was passed string "${str}" as a locale string, but this is not a valid locale string.`
        );
      }
    }

    // Create the ILangLocale result object.
    const result: ILangLocale = {
      language: language as Language,
      locale: locale as Locale,

      /** @inheritdoc */
      toString() {
        // This is cast to `unknown` first because it is technically possible that
        // this is not actually a valid combination of language and locale at runtime.
        // This scenario is very unlikely though, and if necessary should use runtime
        // checks instead.
        return `${language as Language}-${
          locale as Locale
        }` as unknown as LangLocale;
      }
    };

    // Add the result to the ILangLocale object cache.
    this._langLocaleRecord[str] = result;

    return result;
  }

  /**
   * Gets the {@link Region} associated with a given {@link Locale}.
   * @param locale - The locale to get the region for.
   * @returns The region associated with the provided locale.
   * @throws {}
   * @example
   * getRegionOfLocale(Locale.US); // 'NA'
   */
  public getRegionOfLocale(locale: Locale): Region {
    const region = Object.entries(regionMappings).find(([, locales]) =>
      (locales as ReadonlyArray<Locale>).includes(locale)
    ) as [Region, ReadonlyArray<Locale>] | undefined;

    if (!region) {
      throw new LikelyMisconfigurationError(
        `Could not find a region for locale "${locale}". This is likely an indication of misconfiguration.`
      );
    }

    return region[0];
  }

  /**
   * Retrieves a localized resource string for the current locale given a resource string
   * object containing lower-case locale keys with localized string values.
   * @param messageObj - The resource string object.
   * @returns The localized resource string.
   */
  private getMsgFromResourceObject(messageObj: Resource): string {
    const locale = this.currentLocale.toString();
    const lowerCaseLocale = locale.toLowerCase() as Lowercase<typeof locale>;
    const localizedString = messageObj[lowerCaseLocale];

    // If the locale is not found, fallback to the default locale.
    if (!localizedString) {
      return messageObj.default;
    }

    // If the localized string was found for the locale, return it.
    return localizedString;
  }

  /**
   * Retrieves a localized resource string for the current locale given a path to the
   * string. Resource strings are defined in the `lang` directory. Subdirectories represent
   * the brand and then the locale. For example, the string `lang/sanuk/en-US/general.ts`.
   * @param msgID - The resource string identifier for this resource. Will be a dot
   * notation string in the form of `file.key.nestedKey.etc` where `file` is the name of
   * the file in the `lang` directory, `key` is the key in the exported object, and
   * `nestedKey` could be a nested key in the object, and so on.
   * @returns The localized resource string.
   * @throws An error when in production and the argument is not an inline resource string
   * container object.
   */
  public msg<
    Path extends PathType<T>,
    Value extends PathValue<T, Path>,
    T = StringContainer
  >(msgID: Path | Resource): Value {
    const locale = this.currentLocale.toString();

    // If the `msgID` is an object, then it is a resource string that has been inlined
    // by the static optimizations. In this case, we can just return the string directly
    // from the resource object of locale strings.
    if (msgID instanceof Object) {
      return this.getMsgFromResourceObject(msgID as Resource) as Value;
    }

    // If we're in production and we've come this far, it means that this method was
    // called, but not passed an inline resource string. This is a problem because
    // the static optimizations should have inlined all resource strings. We throw here
    // because if we allow it to go unchecked, we will suffer performance bloat from
    // always including all resource strings - something not acceptable for production.
    //
    // In storybook we load these strings dynamically. In the long run we will want to
    // run static generation and optimization for storybook as well.
    if (
      process.env.NODE_ENV === 'production' &&
      !(typeof __isolatedContext__ !== "undefined")
    ) {
      throw new Error(
        'For production builds, all resource strings should be inlined per our static optimizations. ' +
          `Something went wrong and the resource string for ID "${
            msgID as string
          }" was not inlined.`
      );
    }

    // Otherwise, we're in the development environment. In this case, we need to
    // dynamically load the resource strings for the current locale from the pre-built
    // objects that declare all of our resource strings. This method works best with hot-reloading.
    // Get the resource strings from the cache, or create a new one for this
    // locale.
    const resourceStrings = this.resourceStringCache.get(
      locale,
      () => {
        return new ResourceStrings(locale);
      },
      GetMethod.Sync
    );

    return resourceStrings.msg<Path, Value, T>(msgID as Path);
  }

  /**
   * Retrieves a localized resource string for the current locale given a path to the
   * string. Resource strings are defined in the `lang` directory. Subdirectories represent
   * the brand and then the locale. For example, the string `lang/sanuk/en-US/general.ts`.
   * @param msgID - The resource string identifier for this resource. Will be a dot
   * notation string in the form of `file.key.nestedKey.etc` where `file` is the name of
   * the file in the `lang` directory, `key` is the key in the exported object, and
   * `nestedKey` could be a nested key in the object, and so on.
   * @param values - A record of values to be interpolated into the string. The possible
   * values are declared in the resource string itself.
   * @returns The localized resource string formatted with the passed in values in
   * accordance with the Intl MessageFormat spec.
   *
   * @see {@link https://formatjs.io/docs/core-concepts/icu-syntax ICU Message syntax}
   * @see {@link https://formatjs.io/docs/intl-messageformat/#common-usage-example Common Usage Example}
   */
  public msgf<
    Path extends PathType<T>,
    Value extends PathValue<T, Path>,
    T = StringContainer
  >(
    msgID: Path,
    values: Record<string, Nullable<Primitive | ReactElement | JSX.Element>>
  ): Value {
    const string = this.msg<Path, Value, T>(msgID);

    return new IntlMessageFormat(
      string as string,
      this.currentLocale.toString()
    ).format(
      values as Record<
        string,
        | string
        | number
        | boolean
        | null
        | undefined
        | ReactElement
        | JSX.Element
      >
    ) as unknown as Value;
  }

  /**
   * Dynamically retrieves a localized resource string for the current locale given a path
   * to the string. Resource strings are defined in the `lang` directory. Subdirectories
   * represent the brand and then the locale. For example, the string
   * `lang/sanuk/en-US/general.ts`. The difference between this method and `msg` is that
   * this method will return a {@link StaleWhileRevalidate} object that will automatically
   * update the string if the resource ID changes.
   *
   * **Note:** This method is _not_ type safe. It is recommended to use `msg` or `msgf`
   * instead for type safety. Furthermore, this method should only be used when the
   * resource ID is not known at compile time. The method necessarily requires that all
   * resource strings be loaded at runtime, which can have a sizeable impact on the
   * perceived performance of the application.
   *
   * @param msgID - The resource string identifier for this resource. Will be a dot
   * notation string in the form of `file.key.nestedKey.etc` where `file` is the name of
   * the file in the `lang` directory, `key` is the key in the exported object, and
   * `nestedKey` could be a nested key in the object, and so on.
   * @returns The localized resource string.
   * @deprecated Use `msg` or `msgf` instead for type safety and better performance.
   */
  public msgDynamic(msgID: string): StaleWhileRevalidate<string> {
    const locale = this.currentLocale.toString();

    // Get the resource strings from the cache, or create a new one for this
    // locale.
    const resourceStrings = this.resourceStringCache.get(
      locale,
      () => {
        return new ResourceStrings(locale);
      },
      GetMethod.Sync
    );

    return resourceStrings.msgDynamic(msgID);
  }

  /**
   * Formats a number as currency based on the current locale
   * and options provided.
   * @param amount - The amount to format.
   * @param options - Options to pass to the formatter.
   * @returns The current currency code.
   */
  public formatCurrency(
    amount: number,
    options?: Intl.NumberFormatOptions
  ): string {
    const defaultCurrency = options?.currency ?? ('USD' as Currency);
    return new Intl.NumberFormat(this.currentLocale.toString(), {
      style: 'currency',
      currency: defaultCurrency,
      ...options
    }).format(amount);
  }

  /**
   * Formats a number as currency based on the current locale
   * and options provided, but without the currency symbol.
   * @param amount - The amount to format.
   * @param options - Options to pass to the formatter.
   * @returns The current currency code.
   * @example
   * I18NService.formatCurrencyNoSymbol(0); // '0.00' (if the current currency is USD)
   * I18NService.formatCurrencyNoSymbol(
   *  1234.56,
   *  { currency: 'JPY' }
   * ); // '1,235'
   */
  public formatCurrencyNoSymbol(
    amount: number,
    options?: Omit<Intl.NumberFormatOptions, 'style'>
  ): string {
    const defaultCurrency = options?.currency ?? this.currency;
    const currentLocale = this.currentLocale.toString();

    const { minimumFractionDigits, maximumFractionDigits } =
      new Intl.NumberFormat(currentLocale, {
        style: 'currency',
        currency: defaultCurrency,
        ...options
      }).resolvedOptions();

    return amount.toLocaleString(currentLocale, {
      minimumFractionDigits,
      maximumFractionDigits
    });
  }

  /**
   * Takes a date and formats it based on the current locale and options provided.
   * @param date - The date to format.
   * @param options - Options to pass to the formatter.
   * @returns The date formatted.
   */
  public formatDate(date: Date, options?: Intl.DateTimeFormatOptions): string {
    return new Intl.DateTimeFormat(
      this.currentLocale.toString(),
      options
    ).format(date);
  }

  /**
   * Retrieves the currency symbol based in current locale.
   * @returns - The current currency symbol.
   */
  public currentCurrencySymbol(): string {
    return new Intl.NumberFormat(this.currentLocale.toString(), {
      style: 'currency',
      currency: this.currency
    })
      .formatToParts(1)
      .find((x) => x.type === 'currency')?.value as string;
  }

  /**
   * Given an arrival date or date range, returns a pretty formatted string for
   * display purposes. For each date, the format will be determined by the rules
   * of the specified language and locale. A hyphen will separate both dates
   * if two dates are provided.
   *
   * @param latestArrival - The latest date by which the shipment should be
   * delivered. If an `earliestArrival` date is provided, this will be the upper
   * bound in the delivery date range.
   *
   * @param earliestArrival - The earliest date at which the shipment could be
   * delivered. This value will be the lower bound in the delivery date range.
   *
   * @param langLocale - The language and locale to format for. Defaults to the
   * app's current language and locale.
   *
   * @returns The formatted string.
   *
   * @example `Monday, 4/11`, `Monday, 4/11 - Wednesday, 4/14`
   */
  public formatDeliveryDate(
    latestArrival: Date,
    earliestArrival?: Nullable<Date>,
    langLocale: LangLocale = this.currentLocale.toString()
  ): string {
    const formattedLatestArrival = new Intl.DateTimeFormat(langLocale, {
      weekday: 'long',
      month: 'numeric',
      day: 'numeric'
    }).format(latestArrival);

    const formattedEarliestArrival = earliestArrival
      ? new Intl.DateTimeFormat(langLocale, {
          weekday: 'long',
          month: 'numeric',
          day: 'numeric'
        }).format(earliestArrival)
      : null;

    return formattedEarliestArrival
      ? `${formattedEarliestArrival} - ${formattedLatestArrival}`
      : formattedLatestArrival;
  }
}

export default new I18NService();
