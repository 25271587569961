'use client';

import { CookieModel } from '@/services/models/Cookie';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import CookieService from '@/services/isomorphic/CookieService';
import { FunctionComponent, useEffect, useState } from 'react';
import ForterSnippet from './ForterSnippet';

/**
 * This component provides the JavaScript integration for Forter's API. In order to collect
 * complete and accurate user behavior, this snippet needs to be on EVERY page of the site.
 * As a result, this component only needs to be included once in _app.
 *
 * This component also implements Forter's Token Trigger Integration (TTI) which allows Forter
 * to work, without having to rely on its ability to write a cookie.
 * @see {@link https://portal.forter.com/app/developer/api/api/services-and-apis/javascript-integration Forter JavaScript Integration}.
 */
const Forter: FunctionComponent = () => {
  // Per the documentation, the 'ftr:tokenReady' event listener must be attached before the snippet executes.
  const [isEventHandlerAttached, setIsEventHandlerAttached] = useState(false);

  const forterConfig = ConfigurationService.getConfig('forter');
  const enableForter = forterConfig.getSetting('enabled').value;
  const tokenCookieName = forterConfig.getSetting('tokenCookieName').value;
  const siteId = forterConfig.getSetting('siteId').value;

  useEffect(() => {
    const EVENT_NAME = 'ftr:tokenReady';
    const forterTokenReadyHandler = (evt: Event): void => {
      const token = (evt as CustomEvent).detail;

      /**
       * We set this cookie to be able to
       * extract it later from the back-end.
       */
      CookieService.set(
        CookieModel.from({
          key: tokenCookieName,
          value: token,
          secure: true,
          path: '/'
        })
      );
    };

    if (enableForter) {
      document.addEventListener(EVENT_NAME, forterTokenReadyHandler);
      setIsEventHandlerAttached(true);
    }

    return () => {
      if (enableForter) {
        document.removeEventListener(EVENT_NAME, forterTokenReadyHandler);
        setIsEventHandlerAttached(false);
      }
    };
  }, []);

  return (
    <>
      {enableForter && isEventHandlerAttached && (
        <ForterSnippet siteId={siteId} />
      )}
    </>
  );
};

export default Forter;
