import { action, computed, makeObservable, observable } from 'mobx';

import type { DTO, Nullable } from '@/type-utils';

import { CartCalculationService } from '@/services/isomorphic/CartCalculationService';

import type { IImage } from '../../Media/Image';
import Model from '../../Model';
import { MoneyModel } from '../../Money';
import { PriceModel } from '../../Price';

import TaxClass from '../../TaxClass';
import type { ILineItemPromotion } from '../Promotion/LineItem';
import type ILineItem from './ILineItem';
import LineItemType from './LineItemType';

/** Represents a cart item. */
export default class LineItemModel<
    DTOType extends DTO<ILineItem> = DTO<ILineItem>
  >
  extends Model<DTOType>
  implements ILineItem
{
  /** @inheritDoc */
  public readonly uuid: string;

  /** @inheritDoc */
  public readonly cartID: string;

  /** @inheritDoc */
  public readonly type: LineItemType;

  /** @inheritDoc */
  public readonly sku: string;

  /** @inheritDoc */
  public readonly upc: string;

  /** @inheritDoc */
  public readonly name: string;

  /** @inheritDoc */
  public readonly group: Nullable<string>;

  /** @inheritDoc */
  public readonly image: Nullable<IImage>;

  /** @inheritDoc */
  public readonly unitPrice: PriceModel;

  /** @inheritDoc */
  public readonly tax: MoneyModel;

  /** @inheritDoc */
  public readonly taxClass: TaxClass;

  protected _taxRate: number;
  @observable protected _quantity: number;
  @observable protected _promotions: ReadonlyArray<ILineItemPromotion>;

  /** @inheritDoc  */
  public constructor(dto: DTOType) {
    super(dto);

    this.uuid = dto.uuid;
    this.cartID = dto.cartID;
    this.type = dto.type;
    this.sku = dto.sku;
    this.upc = dto.upc;
    this.name = dto.name;
    this.group = dto.group;
    this.image = dto.image;

    this.unitPrice = PriceModel.from(dto.unitPrice);
    this._quantity = dto.quantity;
    this._promotions = dto.promotions;

    this._taxRate = dto.taxRate;
    this.tax = MoneyModel.from(dto.tax);
    this.taxClass = dto.taxClass;

    makeObservable(this);
  }

  /**
   * Updates the model with a DTO representation.
   * @param dto - A Line Item DTO representation.
   */
  @action public update(dto: Partial<DTO<ILineItem>>): void {
    if (dto.quantity) this._quantity = dto.quantity;
    if (dto.promotions) this._promotions = dto.promotions;
    if (dto.tax) this.tax.update(dto.tax);
    if (dto.taxRate !== undefined && Number.isFinite(dto.taxRate))
      this._taxRate = dto.taxRate;
  }

  /** @inheritDoc */
  public get quantity(): number {
    return this._quantity;
  }

  /** @inheritDoc */
  public set quantity(newQuantity: number) {
    this._quantity = newQuantity;
  }

  /** @inheritDoc */
  public get promotions(): ReadonlyArray<ILineItemPromotion> {
    return this._promotions;
  }

  /** @inheritDoc */
  public get taxRate(): number {
    return this._taxRate;
  }

  /** @inheritdoc */
  @computed public get subtotal(): MoneyModel {
    return CartCalculationService.getLineItemSubtotal(this);
  }

  /** @inheritdoc */
  @computed public get netTotal(): MoneyModel {
    return CartCalculationService.getLineItemNetTotal(this);
  }

  /** @inheritdoc */
  @computed public get total(): MoneyModel {
    return MoneyModel.add(this.netTotal, this.tax);
  }

  /**
   * Net price per unit of product line item.
   * @returns Net price per unit.
   */
  @computed public get netUnitPrice(): MoneyModel {
    if (this.quantity === 0) {
      return MoneyModel.fromAmount(0);
    }

    return MoneyModel.divide(this.netTotal, this.quantity);
  }

  /** @inheritDoc */
  public toDTO(): DTOType {
    // this ensures we include all the properties of DTO<ILineItem>
    // before casting it to DTOType
    const dto: DTO<ILineItem> = {
      uuid: this.uuid,
      cartID: this.cartID,
      type: this.type,
      sku: this.sku,
      upc: this.upc,
      name: this.name,
      image: this.image,
      quantity: this.quantity,
      unitPrice: this.unitPrice.toDTO(),
      group: this.group,
      subtotal: this.subtotal.toDTO(),
      netTotal: this.netTotal.toDTO(),
      total: this.total.toDTO(),
      promotions: this.promotions,
      tax: this.tax.toDTO(),
      taxRate: this.taxRate,
      taxClass: this.taxClass
    };

    return dto as DTOType;
  }
}
