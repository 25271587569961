// Imported for documentation purposes only. Should be removed by the build process for
// being unused.
import { ECOMM_OVERRIDE_COOKIE_NAME } from './public';

/* eslint-disable prefer-destructuring -- Trying to destructure process.env
variables won't work due to the nature of webpack DefinePlugin. */
/** @see {@link https://nextjs.org/docs/pages/api-reference/next-config-js/env Next.js Environment Variables}. */

/**
 * The value that the {@link ECOMM_OVERRIDE_COOKIE_NAME} cookie must have in order to
 * force-enable ecommerce functionality.
 *
 * If this environment variable is not set, then ecommerce functionality enablement cannot
 * be overridden on the server-side. The value of this environment variable is intended to
 * be some manner of unguessable secret string. This value is irrelevant on the
 * client-side which relies only on the presence of the cookie, not its value.
 */
export const ECOMM_OVERRIDE_COOKIE_VALUE =
  process.env.ECOMM_OVERRIDE_COOKIE_VALUE;

export const AWS_AUTH = process.env.AWS_AUTH;
export const AWS_AUTH_DEV = process.env.AWS_AUTH_DEV;
export const AWS_AUTH_QA = process.env.AWS_AUTH_QA;
export const AWS_AUTH_UAT = process.env.AWS_AUTH_UAT;
export const AWS_AUTH_PROD = process.env.AWS_AUTH_PROD;

export const ELASTIC_PATH_CLIENT_SECRET =
  process.env.ELASTIC_PATH_CLIENT_SECRET;

export const SENDGRID_API_KEY = process.env.SENDGRID_API_KEY;
export const SENDGRID_SENDER = process.env.SENDGRID_SENDER;
export const SENDGRID_PASSWORD_RESET_TEMPLATE =
  process.env.SENDGRID_PASSWORD_RESET_TEMPLATE;

export const DATADOG_API_KEY = process.env.NEXT_PRIVATE_DATADOG_API_KEY;
export const DATADOG_APP_KEY = process.env.NEXT_PRIVATE_DATADOG_APP_KEY;

export const CHASE_BASE_URL = process.env.CHASE_BASE_URL;
export const CHASE_MERCHANT_ID = process.env.CHASE_MERCHANT_ID;
export const CHASE_CONNECTION_USERNAME = process.env.CHASE_CONNECTION_USERNAME;
export const CHASE_CONNECTION_PASSWORD = process.env.CHASE_CONNECTION_PASSWORD;

export const DOMS_OAUTH_CLIENT_ID = process.env.DOMS_OAUTH_CLIENT_ID;
export const DOMS_OAUTH_CLIENT_SECRET = process.env.DOMS_OAUTH_CLIENT_SECRET;
export const DOMS_MAIL_BASE_URL = process.env.DOMS_MAIL_BASE_URL;

export const HEADLESS_SITE_ID = process.env.HEADLESS_SITE_ID;

export const YOTPO_API_URL = process.env.YOTPO_API_URL;
export const YOTPO_API_KEY = process.env.YOTPO_API_KEY;
export const YOTPO_API_SECRET = process.env.YOTPO_API_SECRET;

export const DYNAMIC_YIELD_API_KEY = process.env.DYNAMIC_YIELD_API_KEY;
export const DYNAMIC_YIELD_API_KEY_AHNU =
  process.env.DYNAMIC_YIELD_API_KEY_AHNU ?? process.env.DYNAMIC_YIELD_API_KEY;

export const DYNAMIC_YIELD_API_KEY_TEVA =
  process.env.DYNAMIC_YIELD_API_KEY_TEVA ?? process.env.DYNAMIC_YIELD_API_KEY;

export const DYNAMIC_YIELD_API_KEY_HOKA =
  process.env.DYNAMIC_YIELD_API_KEY_HOKA ?? process.env.DYNAMIC_YIELD_API_KEY;

export const DYNAMIC_YIELD_API_KEY_UGG =
  process.env.DYNAMIC_YIELD_API_KEY_UGG ?? process.env.DYNAMIC_YIELD_API_KEY;

export const DYNAMIC_YIELD_API_KEY_UGGCOLLAB =
  process.env.DYNAMIC_YIELD_API_KEY_UGGCOLLAB ??
  process.env.DYNAMIC_YIELD_API_KEY;

export const SAFETECH_API_PASSWORD = process.env.SAFETECH_API_PASSWORD;

/** The private User Pool ID for the Next server-side Cognito client. */
export const COGNITO_USER_POOL_ID = process.env.COGNITO_USER_POOL_ID;

/** The private Client ID for the Next server-side Cognito client. */
export const COGNITO_CLIENT_ID = process.env.COGNITO_CLIENT_ID;

// AvaTax configs
export const AVATAX_USERNAME = process.env.AVATAX_USERNAME;
export const AVATAX_PASSWORD = process.env.AVATAX_PASSWORD;

export const AVATAX_COMPANY_CODE = process.env.AVATAX_COMPANY_CODE;

export const UPS_API_PASSWORD = process.env.UPS_API_PASSWORD;

export const PITNEYBOWES_API_KEY = process.env.PITNEYBOWES_API_KEY;

export const FORTER_API_KEY = process.env.FORTER_API_KEY;

export const EASYPOST_API_KEY = process.env.EASYPOST_API_KEY;

/**
 * Will have a value of `'true'` if app is being built.
 * Passed via the `build` command in `package.json`.
 */
export const BUILD_MODE_ENABLED = process.env.BUILD_MODE_ENABLED;

/**
 * Will have a value of `'1'` if code is running in a Continuous Integration environment.
 * Also exposed by Vercel during builds.
 *
 * @see [System Environment Variables | Vercel Docs](https://vercel.com/docs/projects/environment-variables/system-environment-variables#system-environment-variables)
 */
export const CI = process.env.CI;

/** The API key to use with the Sitemap Getter API. */
export const SITEMAP_GETTER_API_KEY = process.env.SITEMAP_GETTER_API_KEY;

export const VERCEL = process.env.VERCEL;

export const APPLE_PAY_CERT = !!process.env.APPLE_PAY_CERT_B64
  ? Buffer.from(process.env.APPLE_PAY_CERT_B64, 'base64')
  : undefined;
