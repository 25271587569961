import {
  CountryCode as LibCountryCode,
  ParseError as LibParseError,
  formatIncompletePhoneNumber as libFormatIncompletePhoneNumber,
  isValidPhoneNumber as libIsValidPhoneNumber,
  parseIncompletePhoneNumber as libParseIncompletePhoneNumber,
  parsePhoneNumberWithError as libParsePhoneNumber,
  validatePhoneNumberLength as libValidatePhoneNumberLength
} from 'libphonenumber-js';

import I18NService, { Locale } from '@/services/isomorphic/I18NService';
import type { IPhoneNumber } from '@/services/models/PhoneNumber';

import { DTO } from '@/type-utils';

import {
  InvalidPhoneNumberCountryError,
  InvalidPhoneNumberLengthError,
  InvalidPhoneNumberValueError,
  PhoneNumberTooLongError,
  PhoneNumberTooShortError,
  UnableToParsePhoneNumberError
} from './errors';

/**
 * Parses a {@link IPhoneNumber phone number object} from a string.
 *
 * This method will try to determine the locale of the number automatically,
 * but it will fall back to the specified default locale.
 *
 * @param number - The phone number string to parse.
 * @param defaultLocale - The locale to assume when the phone's locale is not obvious.
 *
 * @returns The phone number, as a {@link IPhoneNumber} DTO.
 *
 * @throws An {@link UnableToParsePhoneNumberError} or one of it variants on parse failure.
 * @see {@link getParseError} for possible parse errors.
 */
export const parsePhoneNumber = (
  number: string,
  defaultLocale: Locale = I18NService.currentLocale.locale
): DTO<IPhoneNumber> => {
  try {
    const locale = defaultLocale;
    const parsed = libParsePhoneNumber(
      number,
      // This is safe since both LibCountryCode and Locale are ISO 3166-1 alpha-2 codes.
      locale as LibCountryCode
    );

    return {
      number: parsed.number,
      locale: parsed.country as Locale,
      isPossible: parsed.isPossible(),
      isValid: parsed.isValid(),
      e164Formatted: parsed.format('E.164'),
      internationalFormatted: parsed.format('INTERNATIONAL'),
      localeFormatted: parsed.format('NATIONAL'),
      uri: parsed.format('RFC3966')
    };
  } catch (error) {
    if (error instanceof LibParseError) {
      throw getParseError(error.message);
    }

    throw error;
  }
};

/**
 * Sanitizes an incomplete phone number by removing non-digit characters.
 *
 * @param number - The phone number string to parse.
 * @returns The sanitized phone number.
 */
export const sanitizeIncompletePhoneNumber = (number: string): string => {
  return libParseIncompletePhoneNumber(number);
};

/**
 * Formats an incomplete phone number according to its locale.
 *
 * This method will try to determine the locale of the number automatically,
 * but it will fall back to the specified default locale.
 *
 * @param number - The phone number string to format.
 * @param defaultLocale - The locale to assume when the phone's locale is not obvious.
 *
 * @returns A locale-formatted phone number string.
 */
export const formatIncompletePhoneNumber = (
  number: string,
  defaultLocale: Locale
): string => {
  const sanitized = sanitizeIncompletePhoneNumber(number);
  const locale = defaultLocale;

  return libFormatIncompletePhoneNumber(
    sanitized, // This is safe since both LibCountryCode and Locale are ISO 3166-1 alpha-2 codes.
    locale as LibCountryCode
  );
};

/**
 * Validates the specified phone number string.
 *
 * A phone number will be considered valid if it has the right length
 * AND it passes all the regular expressions for its locale.
 *
 * @param number - The phone number string to validate.
 * @param defaultLocale - The locale to assume when the phone's locale is not obvious.
 * @returns A `true` value if the phone number is valid.
 *
 * @throws An {@link UnableToParsePhoneNumberError} or one of it variants on parse failure.
 * @see {@link getParseError} for possible parse errors.
 */
export const validatePhoneNumber = (
  number: string,
  defaultLocale: Locale
): boolean => {
  const lengthValidationResult = libValidatePhoneNumberLength(
    number,
    defaultLocale as LibCountryCode
  );

  if (lengthValidationResult) {
    throw getParseError(lengthValidationResult);
  }

  return libIsValidPhoneNumber(number, defaultLocale as LibCountryCode);
};

/**
 * Validates the specified phone number string.
 *
 * A phone number will be considered valid if it has the right length
 * AND it passes all the regular expressions for its locale.
 *
 * Unlike {@link validatePhoneNumber}, this method will return `false` instead
 * of throwing if it gets passed an invalid phone number.
 *
 * @param number - The phone number string to validate.
 * @param locale - The locale to assume when the phone's locale is not obvious.
 * @returns A `true` value if the phone number is valid, or `false` if otherwise.
 */
export const safeValidatePhoneNumber = (
  number: string,
  locale?: Locale
): boolean => {
  return libIsValidPhoneNumber(number, locale as LibCountryCode);
};

/**
 * Given a `libphonenumber-js` error message, retrieve an equivalent
 * first-party error.
 *
 * @param errorMessage - The error message.
 * @param cause - An error to include as the cause.
 *
 * @returns An appropiate first-party error.
 */
const getParseError = (errorMessage: string, cause?: Error): Error => {
  switch (errorMessage) {
    case 'INVALID_COUNTRY': {
      return new InvalidPhoneNumberCountryError(
        'The phone number has an invalid country or area code.',
        { cause }
      );
    }

    case 'NOT_A_NUMBER': {
      return new InvalidPhoneNumberValueError(
        'The supplied string is not a phone number.',
        { cause }
      );
    }

    case 'TOO_SHORT': {
      return new PhoneNumberTooShortError(
        'The supplied phone number is too short.',
        { cause }
      );
    }

    case 'TOO_LONG': {
      return new PhoneNumberTooLongError(
        'The supplied phone number is too long.',
        { cause }
      );
    }

    case 'INVALID_LENGTH': {
      return new InvalidPhoneNumberLengthError(
        'The supplied phone number has an invalid length.',
        { cause }
      );
    }

    default: {
      return new UnableToParsePhoneNumberError(
        'An error occured when trying to parse phone number.',
        { cause }
      );
    }
  }
};
