import { AsyncLocalStorage } from 'node:async_hooks';
import { v4 as uuidv4 } from 'uuid';

import {
  InvalidArgumentError,
  InvalidStateError,
  ServerCodeInClientError
} from '@/utils/errors';

import { Nullable } from '@/type-utils';
import MemoryCache from '@/utils/MemoryCache';
import { TimeScale } from '@/utils/time-utils';

import { APP_ENV } from '@/configs/env/public';

import { types } from 'node:util';
import {
  AnyRoute,
  NormalizedRequestModel,
  NormalizedResponseModel,
  RouteType
} from '../../models/Http';

import { NoRequestIDError } from './NoRequestIDError';

/**
 * Used to obtain the current request context on the server-side. While this is
 * intended to only be practically used on the server-side, it is an isomorphic
 * service because other isomorphic services may use this and handle any returned
 * `null` values.
 */
class CurrentRequestService {
  private _requestCache: Nullable<MemoryCache<NormalizedRequestModel>>;
  private _asyncLocalStorage: Nullable<AsyncLocalStorage<string>>;
  private _appInstanceID: Nullable<string>;

  /**
   * Gets the memory cache for the request or creates a new one.
   * @returns The memory cache for the service.
   */
  private get requestCache(): MemoryCache<NormalizedRequestModel> {
    if (!this._requestCache) {
      this._requestCache = new MemoryCache<NormalizedRequestModel>(
        // Never expire the request cache in development, because we may have a long
        // running debugger. Not using the `EnvironmentService` here as it would create a
        // circular dependency.
        APP_ENV === 'dev' ? Infinity : 1,
        TimeScale.Minutes
      );
    }

    return this._requestCache;
  }

  /** @returns The existing or a new instance of `AsyncLocalStorage`. */
  private get asyncLocalStorage(): AsyncLocalStorage<string> {
    // Not using the `EnvironmentService` here as it would create a circular
    // dependency. Uses Webpack's dead branch detection to always throw on
    // the client, since no code on the client should be attempting to use Node APIs,
    // if we correctly handled client detection elsewhere in this file..
    if (typeof window !== 'undefined') {
      throw new ServerCodeInClientError(
        `There was an attempt to use \`CurrentRequestService\`'s \`AsyncLocalStorage\` ` +
          `in the client. This indicates server code leaking into the client. All public ` +
          `properties and methods on \`CurrentRequestService\` should preemptively detect` +
          ` whether the code is executing on the client prior to accessing this property.`
      );
    }
    if (this._asyncLocalStorage) return this._asyncLocalStorage;

    this._asyncLocalStorage = new AsyncLocalStorage<string>();
    return this._asyncLocalStorage;
  }

  /**
   * The application instance ID, used to identify the specific serverless function being
   * used to make a call. In deployments, Next runs in a serverless environment and each
   * request is potentially executed in a new lambda that has no state in memory from
   * previous requests.
   *
   * This is unlike a traditional web server where each request is executed in the same
   * process. When a request is being made with a "warm" lambda, the `appInstanceID` will
   * be the same for all requests made within that same lambda. When a request is being
   * handled with a "cold" lambda, the `appInstanceID` will be different from previous
   * requests.
   *
   * @returns A unique identifier for this instantiation of the the request service.
   * @see https://docs.aws.amazon.com/lambda/latest/dg/runtimes-context.html
   * @see https://vercel.com/docs/concepts/functions/serverless-functions
   */
  public get appInstanceID(): string {
    if (!this._appInstanceID) {
      this._appInstanceID = uuidv4();
    }

    return this._appInstanceID;
  }

  /**
   * `inRequest` is used to determine if the current code is excuting with the
   * context of a request. **IMPORTANT:** This is _not_ a replacement for
   * `EnvironmentService.onServer` as that value is known at compile time and
   * this value can only be known at runtime. To allow dead server code to be
   * eliminated on the client, this should always be paired with a check to see
   * if we're executing on the server first - either together or in an outer
   * closure.
   * @returns `true` if the current scope is executing within the context of a
   * request.
   * @example ```ts
   * if (EnvironmentService.onServer && CurrentRequest.inRequest) {
   *  // The code is running on the server within a request.
   * }
   * ```
   */
  public get inRequest(): boolean {
    // Not using the `EnvironmentService` here as it would create a circular
    // dependency. Uses Webpack's dead branch detection to always return `false` on
    // the client.
    if (typeof window !== 'undefined') return false;
    return Boolean(
      this.requestCache.length &&
        this.asyncLocalStorage.getStore() !== undefined
    );
  }

  /**
   * Returns the current request/response in scope as a tuple.
   *
   * @returns The `NormalizedRequestModel`/`NormalizedResponseModel` tuple for the current request in scope.
   * @throws `InvalidStateError` if we're on the server and the request object cannot
   * be retrieved.
   * @throws `ServerCodeInClientError` if we're on the client.
   */
  public get<T extends RouteType = AnyRoute>(): [
    NormalizedRequestModel<T>,
    NormalizedResponseModel<T>
  ] {
    // Not using the `EnvironmentService` here as it would create a circular
    // dependency.
    if (typeof window !== 'undefined') {
      throw new ServerCodeInClientError(
        'CurrentRequestService.get() called on the client'
      );
    }

    const requestID = this.asyncLocalStorage.getStore();
    if (requestID) {
      try {
        const request = this.requestCache.get(requestID);
        return [request, request.response];
      } catch (e) {
        throw new InvalidStateError(
          `Could not get current request from cache for request ID "${requestID}"`,
          { cause: e as Error }
        );
      }
    }
    throw new NoRequestIDError(
      `Could not get current request ID. Did you forget to wrap a route in \`middleware\`?`
    );
  }

  /**
   * Returns the current request/response in scope as a tuple.
   *
   * @returns The `NormalizedRequestModel`/`NormalizedResponseModel` tuple for the current
   * request in scope or `null` if we're unable to retrieve the current request object.
   */
  public tryGet<T extends RouteType = AnyRoute>():
    | [null, null]
    | [NormalizedRequestModel<T>, NormalizedResponseModel<T>] {
    // Try to get the current request/response
    try {
      return this.get<T>();
    } catch (e) {
      // Would be nice to log this error but can't use
      // `LoggerService` here :(
      return [null, null];
    }
  }

  /**
   * Wraps a function so that it may call `CurrentRequest.get()` to obtain
   * information about the current request.
   * @param request - The `IMiddlewareRequest` request object to represent this
   * request in the current scope.
   * @param callback - The function to call to which this request will belong.
   * @returns The return value of the provided function.
   * @throws `ServerCodeInClientError` when the method is called from within the
   * browser.
   */
  public use<
    T extends NormalizedRequestModel,
    ReturnValue extends Promise<unknown>,
    Callback extends () => ReturnValue
  >(request: T, callback: Callback): ReturnValue {
    // Not using the `EnvironmentService` here as it would create a circular dependency.
    // Throw an error if there is an attempt to call this method on the client.
    if (typeof window !== 'undefined') {
      throw new ServerCodeInClientError(
        'There was an attempt to call `CurrentRequest.use` in the browser. ' +
          'This method may only be used server-side as it is intended for server-side request ' +
          'handling and uses Node JS APIs.'
      );
    }

    this.requestCache.add(request.id, request);
    return this.asyncLocalStorage.run(request.id, async () => {
      try {
        if (types.isAsyncFunction(callback)) {
          // Call the function to obtain its promise.
          const returnValue = callback() as ReturnValue;

          // If the callback returns a promise, we need to wait for it to resolve
          // before removing the request from the cache.
          if (returnValue instanceof Promise) {
            return returnValue.finally(() => {
              if (this.requestCache.has(request.id)) {
                this.requestCache.remove(request.id);
              }
            }) as ReturnValue;
          }
        }

        // If the callback does not return a promise, we need to remove the request
        // from the cache before returning the value. The callback function should not be
        // synchronous because we won't be able to know when to remove the request from
        // the cache otherwise.
        this.requestCache.remove(request.id);
        throw new InvalidArgumentError(
          '`CurrentRequest.use` was passed a synchronous callback function whose ' +
            ' `CurrentRequest.use` callback function argument must be asynchronous.'
        );
      } catch (e) {
        // If the callback throws an error, we need to remove the request from the
        // cache before re-throwing the error. This is done to prevent a memory leak
        // when an error occurs in a synchronous callback.
        if (this.requestCache.has(request.id)) {
          this.requestCache.remove(request.id);
        }

        // Now that we've removed the request from the cache, re-throw the error.
        throw e;
      }
    }) as ReturnValue;
  }
}

export default new CurrentRequestService();
