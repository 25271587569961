import { errorFactory } from '@/utils/error-utils';

/** Generic error to use when a phone number parse operation fails. */
export const { UnableToParsePhoneNumberError } = errorFactory(
  'UnableToParsePhoneNumberError'
);

/**
 * Error to use when an a phone number has an invalid country code.
 */
export const { InvalidPhoneNumberCountryError } = errorFactory(
  'InvalidPhoneNumberCountryError',
  UnableToParsePhoneNumberError
);

/**
 * Error to use when an unparsable value is passed to the phone number parse function.
 * (e.g. A string with letters or symbols other than parentheses, dashes, pluses, and/or periods).
 */
export const { InvalidPhoneNumberValueError } = errorFactory(
  'InvalidPhoneNumberValueError',
  UnableToParsePhoneNumberError
);

/**
 * Error to use when a phone number is of an invalid length.
 */
export const { InvalidPhoneNumberLengthError } = errorFactory(
  'InvalidPhoneNumberLengthError',
  UnableToParsePhoneNumberError
);

/** Error to use when a phone number is too short. */
export const { PhoneNumberTooShortError } = errorFactory(
  'PhoneNumberTooShortError',
  InvalidPhoneNumberLengthError
);

/** Error to use when a phone number is too long. */
export const { PhoneNumberTooLongError } = errorFactory(
  'PhoneNumberTooLongError',
  InvalidPhoneNumberLengthError
);
