import type StaleWhileRevalidate from './StaleWhileRevalidate';

/**
 * `LazyValue` is a wrapper around a value that is lazily evaluated. This is useful for
 * values that we wish to declare in one place but not evaluate until they are needed. For
 * instance, we may wish to declare a value that depends on a configuration value that is
 * not available at the time of declaration.
 *
 * You can think of this class like a functional approach to getters in a class where the
 * value is stored in a "backing field".
 *
 * This class is similar to the {@link StaleWhileRevalidate} utility class, but it does
 * have a stale value nor does it attempt to retrieve a new value. The `LazyValue` class's
 * `value` property will only evaluate the value once and then cache it for future calls.
 *
 * The value is evaluated as soon as the `value` property is accessed.
 *
 * @example
 * ```ts
 *  const config = new LazyValue(() => ConfigurationService.getConfig(
 *    'talonOne'
 *  ));
 *  // ...elsewhere...
 *  const talonOneConfig = config.value; // `getConfig` is called here.
 * ```
 */
export default class LazyValue<T> {
  /**
   * The cached value. This is `undefined` until the `value` property is accessed. This
   * will be set the the value returned by the `valueGetter` function that is passed to
   * this class's constructor. We use `undefined` as a sentinel value to indicate that the
   * value has not yet been evaluated. We don't use our typicaly `Nullable<T>` type here
   * because we want to be able to distinguish between a `null` value and a value that has
   * not yet been evaluated as `null` is a valid value for the `valueGetter` function to
   * return.
   */
  private cachedValue: T | undefined = undefined;

  /**
   * The value of the lazy value. This will execute the `valueGetter` function passed to
   * this class's constructor the first time it is called and then cache the result for
   * future calls. As soon as this property is accessed, the value will be evaluated. So
   * be sure not to reference the `value` property until you wish to both evaluate the
   * value and retrieve it.
   * @returns The value of the lazy value per the `valueGetter` function passed to this
   * class's constructor.
   */
  public get value(): T {
    if (this.neverCache || this.cachedValue === undefined) {
      this.cachedValue = this.valueGetter();
    }
    return this.cachedValue;
  }

  /**
   * @inheritdoc
   * @param valueGetter - A function used to retrieve the value of the lazy value. The
   * function may be executed by accessing the {@link LazyValue.value} property. This
   * function will only be called once and the result will be cached for future calls to
   * the {@link LazyValue.value} property.
   * @param neverCache - If `true`, the value will be retrieved every time using the value
   * getter function. If `false`, the value will be cached after the first call to the
   * {@link LazyValue.value} property. It can be useful to set this to `true` if the
   * value getter function returns a value that is contextual and may change over time.
   */
  public constructor(
    private readonly valueGetter: () => T,
    private readonly neverCache = false
  ) {}
}
