import { action, computed, makeObservable, observable } from 'mobx';

import { DTO, Nullable, WithRequired } from '@/type-utils';
import StaleWhileRevalidate from '@/utils/StaleWhileRevalidate';

import { CartCalculationService } from '@/services/isomorphic/CartCalculationService';
import ConfigurationService, {
  Config
} from '@/services/isomorphic/ConfigurationService';
import ShippingMethodService from '@/services/isomorphic/ShippingMethodService';
import { CannotShipToPOBoxError } from '@/services/isomorphic/ShippingMethodService/errors';
import { filterAsync } from '@/utils/async-utils';
import { InvalidArgumentError, InvalidStateError } from '@/utils/errors';
import CartService from '../../isomorphic/CartService';
import PromotionsService from '../../isomorphic/PromotionsService';

import Model from '../Model';
import { IProduct } from '../Product';

import { GiftCardAlreadyAppliedError } from './errors/GiftCardAlreadyAppliedError';
import { LineItemNotFoundError } from './errors/LineItemNotFoundError';

import type { ICart } from '.';
import { IInitialProduct } from '../../serverless/integrations/AWS/AWSCartService';
import type { IAddress } from '../Address';
import { GiftCardModel, IGiftCardBase } from '../GiftCard';
import { MoneyModel } from '../Money';
import { ShippingMethodModel } from '../ShippingMethod';
import type { ICoupon } from './Coupon';
import { ILineItem, LineItemModel, ProductLineItemModel } from './LineItem';
import type { ICartPromotionUpdates } from './Promotion';
import type { ICartPromotion } from './Promotion/Cart';
import { ICartTax } from './Tax';
import { CartTotalQuantityExceededError } from './errors/CartTotalQuantityExceededError';
import { LineItemQuantityExceededError } from './errors/LineItemQuantityExceededError';

/**
 * Represents a Cart.
 *
 * When a cart is created, it is recommended to call {@link CartModel.revalidate}
 * to calculate various values that aren't immediately initialized.
 */
export default class CartModel extends Model<DTO<ICart>> implements ICart {
  /** @inheritDoc */
  public readonly uuid: string;

  /** @inheritDoc */
  public readonly ownerID: string;

  /**
   * The cart config.
   * @returns A `Config<'cart'>`.
   */
  private get cartConfig(): Config<'cart'> {
    return ConfigurationService.getConfig('cart');
  }

  /**
   * The ID of the most recently initiated {@link revalidate} call.
   *
   * This ID is used to minimize race conditions that may occur
   * between updates to the cart state by the {@link revalidate} call and
   * by the user. The {@link revalidate} function requires that some of
   * the cart's state does not change while it is running, so this
   * ID is used to essentially check if the cart has been modified
   * since the revalidation started. If it has, then the revalidation
   * is considered stale and is aborted.
   */
  private _revalidationID: number = 0;

  @observable private _coupons: Array<ICoupon>;
  @observable private _giftCards: Array<GiftCardModel>;
  @observable private _items: Array<LineItemModel>;
  @observable private _promotions: Array<ICartPromotion>;

  @observable private _shipToAddress?: IAddress;
  @observable private _selectedShippingMethod?: Nullable<ShippingMethodModel>;
  @observable private _applicableShippingMethods: StaleWhileRevalidate<
    Array<ShippingMethodModel>
  >;

  @observable private _linesDiscount: StaleWhileRevalidate<MoneyModel>;
  @observable private _cartDiscount: StaleWhileRevalidate<MoneyModel>;
  @observable private _discount: StaleWhileRevalidate<MoneyModel>;

  @observable private _tax: StaleWhileRevalidate<MoneyModel>;
  @observable private _total: StaleWhileRevalidate<MoneyModel>;
  @observable private _shippingCost: StaleWhileRevalidate<MoneyModel>;

  /**
   * Builds a Cart model from a DTO representation.
   * @param dto - A Cart DTO representation.
   */
  public constructor(dto: DTO<ICart>) {
    super(dto);

    this.uuid = dto.uuid;
    this.ownerID = dto.ownerID;

    this._coupons = [...dto.coupons];
    this._giftCards = dto.giftCards.map((i) => GiftCardModel.from(i));
    this._items = dto.items.map((i) => LineItemModel.from(i));
    this._promotions = [...dto.promotions];

    this._shipToAddress = dto.shipToAddress ?? undefined;
    this._selectedShippingMethod = dto.selectedShippingMethod
      ? ShippingMethodModel.from(dto.selectedShippingMethod)
      : null;
    this._applicableShippingMethods = new StaleWhileRevalidate(
      [],
      ShippingMethodService.getApplicableShippingMethodsForCart(this)
    );

    /**
     * Although 'subtotal' is a property of the DTO<ICart>, it is mainly provided as a
     * convenience for other API consumers. The real subtotal value is calculated by the
     * CartModel via the line items. As a result, do not rely on `dto.subtotal` here.
     */

    /**
     * Since constructors cannot be async, it would be impossible to calculate the
     * cart totals here. Thus, we initialize them with dummy values which won't be properly
     * calculated until {@link CartModel.revalidate} is called separately.
     */

    this._linesDiscount = new StaleWhileRevalidate<MoneyModel>(
      dto.linesDiscount
        ? MoneyModel.from(dto.linesDiscount)
        : MoneyModel.fromAmount(0)
    );

    this._cartDiscount = new StaleWhileRevalidate<MoneyModel>(
      dto.cartDiscount
        ? MoneyModel.from(dto.cartDiscount)
        : MoneyModel.fromAmount(0)
    );

    this._discount = new StaleWhileRevalidate<MoneyModel>(
      dto.discount ? MoneyModel.from(dto.discount) : MoneyModel.fromAmount(0)
    );

    this._tax = new StaleWhileRevalidate<MoneyModel>(
      dto.tax ? MoneyModel.from(dto.tax) : MoneyModel.fromAmount(0)
    );

    this._total = new StaleWhileRevalidate<MoneyModel>(
      dto.total ? MoneyModel.from(dto.total) : MoneyModel.fromAmount(0)
    );

    this._shippingCost = new StaleWhileRevalidate<MoneyModel>(
      dto.shippingCost
        ? MoneyModel.from(dto.shippingCost)
        : MoneyModel.fromAmount(0)
    );

    makeObservable(this);
  }

  /** @inheritDoc */
  @computed public get items(): Array<LineItemModel> {
    return this._items;
  }

  /** @inheritDoc */
  @computed public get promotions(): Array<ICartPromotion> {
    return this._promotions;
  }

  /** @inheritDoc */
  @computed public get coupons(): Array<ICoupon> {
    return this._coupons;
  }

  /** @inheritDoc */
  @computed public get shipToAddress(): IAddress | undefined {
    return this._shipToAddress;
  }

  /** @inheritDoc */
  @computed public get selectedShippingMethod(): Nullable<ShippingMethodModel> {
    return this._selectedShippingMethod;
  }

  /** @inheritDoc */
  @computed public get subtotal(): MoneyModel {
    const itemSubtotals = this.items.map(({ subtotal }) => subtotal);
    return MoneyModel.add(0, ...itemSubtotals);
  }

  /** @inheritDoc */
  @computed public get linesDiscount(): StaleWhileRevalidate<MoneyModel> {
    return this._linesDiscount;
  }

  /** @inheritDoc */
  @computed public get cartDiscount(): StaleWhileRevalidate<MoneyModel> {
    return this._cartDiscount;
  }

  /** @inheritDoc */
  @computed public get discount(): StaleWhileRevalidate<MoneyModel> {
    return this._discount;
  }

  /** @inheritDoc */
  @computed public get tax(): StaleWhileRevalidate<MoneyModel> {
    return this._tax;
  }

  /** @inheritDoc */
  @computed public get shippingCost(): StaleWhileRevalidate<MoneyModel> {
    return this._shippingCost;
  }

  /** @inheritDoc */
  @computed public get total(): StaleWhileRevalidate<MoneyModel> {
    return this._total;
  }

  /** @inheritdoc */
  @computed public get giftCards(): Array<GiftCardModel> {
    return this._giftCards.map((card) => GiftCardModel.from(card));
  }

  /**
   * The total number of items in the cart, that is,
   * the sum of the quantities of all line items.
   * @returns The total number of items in the cart.
   */
  @computed public get totalItemQuantity(): number {
    return this.items.reduce((count, item) => count + item.quantity, 0);
  }

  /**
   * The maximum quantity (inclusive) per line item that is allowed in this cart.
   * @returns The maximum quantity per line item.
   */
  private get maxQuantityPerLine(): number {
    return this.cartConfig.getSetting('maxQuantityPerLine').value;
  }

  /**
   * The maximum total quantity (inclusive) of items that is allowed in this cart.
   * @returns The maximum quantity per cart.
   */
  private get maxQuantityPerCart(): number {
    return this.cartConfig.getSetting('maxQuantityPerCart').value;
  }

  /**
   * The shipping methods that are currently applicable to this cart.
   * @returns A {@link StaleWhileRevalidate} that resolves to an array of applicable shipping methods.
   */
  @computed public get applicableShippingMethods(): StaleWhileRevalidate<
    Array<ShippingMethodModel>
  > {
    return this._applicableShippingMethods;
  }

  /**
   * Internal implementation of {@link revalidate `revalidate`}, including internal
   * options not exposed in the public method.
   *
   * Recalculates the cart totals, promotions, and applied gift card amounts.
   * This can be used to "refresh" the cart after any change such as adding a line
   * item or applying a coupon. This will also automatically select the best
   * applicable shipping method for the cart.
   *
   * This method is necessary because the cart totals are not persisted on the server,
   * and must be calculated on the client. Thus, it's recommended to call this method
   * after constructing a new CartModel from a DTO.
   *
   * Calling this method will synchronously put the cart totals into a pending state,
   * but will asynchronously resolve once the cart has completely updated.
   *
   * @param options - Options to pass to underlying methods.
   * @param internalOptions - Internal options not meant to be exposed in the
   * public method.
   */
  @action private async _revalidateInternal(
    options?: {
      promotionUpdates?: ICartPromotionUpdates;
    },
    internalOptions?: {
      /**
       * An array containing the shipping method UIDs seen in the current call
       * stack.
       *
       * This is needed to prevent cycles, since some promotions can alter
       * shipping method selection or availability; which would warrant another
       * cart revalidation to calculate how the totals should change.
       *
       * This, however, can potentially cause cycles if promotions are
       * configured incorrectly; where the revalidation stack goes between
       * two or more shipping methods cyclically.
       *
       * So by keeping track of the shipping methods visited in the call stack
       * we can detect and report cycles.
       *
       * **NOTE:** `null` values are allowed so we can record if there was no
       * method selected after a round of revalidations. This allows us to catch
       * cycles that include states in which no methods are applicable to the
       * cart. This, however, feels a bit weird. So...
       *
       * TODO: Check if tweaking the order of operations can help us catch
       * these states without allowing `null` in the array.
       */
      visitedShippingMethodIDs?: Array<string | null>;
    }
  ): Promise<void> {
    const revalidationID = ++this._revalidationID;

    const { promotionUpdates } = options ?? {};
    const { visitedShippingMethodIDs = [] } = internalOptions ?? {};

    /**
     * The following promises aren't awaited because we want to synchronously
     * update the cart totals with the stale values first.
     */

    const selectDefaultShippingMethodPromise = !this._selectedShippingMethod
      ? this._selectBestShippingMethod()
      : Promise.resolve();

    /**
     * **Note**: The order of these promises is deliberate. Changing this order or adding/removing
     * steps from the chain must be done with great care, or the calculated totals may be incorrect.
     *
     * Since the totals depend on the promotions, the promotions must be updated first. And since there may
     * be promotions related to shipping, the shipping method must be selected before the promotions are updated.
     */
    const totalsPromise = selectDefaultShippingMethodPromise
      .then(() => this.revalidatePromotions(promotionUpdates))
      .then(() => CartCalculationService.calculateCartTotals(this));

    this._linesDiscount = new StaleWhileRevalidate(
      this._linesDiscount.value,
      totalsPromise.then(
        (totals) => MoneyModel.from(totals.linesDiscount),
        () => this._linesDiscount.value
      )
    );

    this._cartDiscount = new StaleWhileRevalidate(
      this._cartDiscount.value,
      totalsPromise.then(
        (totals) => MoneyModel.from(totals.cartDiscount),
        () => this._cartDiscount.value
      )
    );

    this._discount = new StaleWhileRevalidate(
      this._discount.value,
      totalsPromise.then(
        (totals) => MoneyModel.from(totals.discount),
        () => this._discount.value
      )
    );

    this._tax = new StaleWhileRevalidate(
      this._tax.value,
      totalsPromise.then(
        (totals) => MoneyModel.from(totals.tax.total),
        () => this._tax.value
      )
    );

    this._total = new StaleWhileRevalidate(
      this._total.value,
      totalsPromise.then(
        (totals) => MoneyModel.from(totals.total),
        () => this._total.value
      )
    );

    this._shippingCost = new StaleWhileRevalidate(
      this._shippingCost.value,
      totalsPromise.then(
        (totals) => MoneyModel.from(totals.shippingCost),
        () => this._shippingCost.value
      )
    );

    const { tax } = await totalsPromise;

    if (this._revalidationID !== revalidationID) {
      // If the revalidation ID has changed, then a new one has been started, and this one is stale.
      // So bail out early.
      return;
    }

    this.updateInternalTaxData(tax);

    // only recalculate gift cards if the cart total has changed
    // if (!lastCartTotal.isEqualTo(total))
    // TODO HP-2832: This does not ever revalidate with the above code after the user
    // changes pages. With this in place we were seeing the gift card balance stuck at 0 after
    // leaving the checkout flow. This would also disrupt place order if the user does not
    // remove the gift card.
    if (this._giftCards?.length > 0) {
      await this.reapplyGiftCards();
    }

    // Check if shipping method availability/selection changed after the
    // promotions have been applied

    const previousMethodId = this.selectedShippingMethod?.uid ?? null;

    // The fetch part of this call is cached, so running it on every
    // revalidate call is passable.
    await this._revalidateApplicableShippingMethods();
    const newMethodId = this.selectedShippingMethod?.uid ?? null;

    // If the new shipping method is different...
    if (previousMethodId !== newMethodId) {
      const newVisitedMethods = [...visitedShippingMethodIDs, newMethodId];

      // First, detect cycles by checking if the new method has already been
      // seen in the current call stack.
      if (visitedShippingMethodIDs.includes(newMethodId)) {
        throw new InvalidStateError(
          'Cannot revalidate cart: A shipping method selection cycle has' +
            ` been detected (${newVisitedMethods.join(' -> ')}). Please make` +
            ' sure that current promotion rules are not changing shipping' +
            ' method selection or availability in a cyclical way.'
        );
      }

      // If there are no cycles, revalidate promotions once more.
      await this._revalidateInternal(options, {
        ...internalOptions,
        visitedShippingMethodIDs: newVisitedMethods
      });
    }
  }

  /**
   * Recalculates the cart totals, promotions, and applied gift card amounts.
   * This can be used to "refresh" the cart after any change such as adding a line
   * item or applying a coupon. This will also automatically select the best
   * applicable shipping method for the cart.
   *
   * This method is necessary because the cart totals are not persisted on the server,
   * and must be calculated on the client. Thus, it's recommended to call this method
   * after constructing a new CartModel from a DTO.
   *
   * Calling this method will synchronously put the cart totals into a pending state,
   * but will asynchronously resolve once the cart has completely updated.
   *
   * @param options - Options to pass to underlying methods.
   *
   * @example
   * const cart = CartModel.from(cartDTO);
   * await cart.revalidate();
   */
  @action public async revalidate(options?: {
    promotionUpdates?: ICartPromotionUpdates;
  }): Promise<void> {
    await this._revalidateInternal(options);
  }

  /**
   * A helper method for updating the internal tax data of this cart, i.e.
   * line item tax and shipping tax. This method should only be called within
   * {@link CartModel.revalidate}.
   * @param tax - The cart tax data to update with.
   * @throws An {@link InvalidStateError} if the line tax data is not found for
   * any of the cart's line items or shipping method.
   */
  @action private updateInternalTaxData(tax: ICartTax): void {
    // If there are no line items, then there is no tax to update.
    // in practice, we don't even really have a shipping method either.
    if (this._items.length === 0) {
      return;
    }

    /** A map of line item UUIDs to their tax data. */
    const lineTaxMap = new Map(
      tax.lines.map((lineTax) => [lineTax.uuid, lineTax])
    );

    this._items.forEach((item) => {
      const lineTax = lineTaxMap.get(item.uuid);

      if (!lineTax) {
        throw new InvalidStateError(
          `Line tax not found for ${item.sku} (${item.uuid}).`
        );
      }

      item.update({ tax: lineTax.tax, taxRate: lineTax.rate });
    });

    const shippingMethod = this.selectedShippingMethod;

    // Only update shipping tax if a shipping method is selected.
    if (shippingMethod) {
      const shippingTax = lineTaxMap.get(shippingMethod.uid);

      if (!shippingTax) {
        throw new InvalidStateError(
          `Shipping tax not found for ${shippingMethod.name} (${shippingMethod.uid}).`
        );
      }

      shippingMethod.update({
        tax: shippingTax.tax,
        taxRate: shippingTax.rate
      });
    }
  }

  /**
   * Updates the promotions of this cart. This will also include coupons and
   * the promotions of the cart's line items.
   *
   * **Note: this method does not automatically recalculate the cart totals**.
   *
   * @param prefetch - If present, the method will use the provided updates
   * instead of fetching promotions from the {@link PromotionsService}. Useful
   * to prevent multiple unnecessary calls when the data is already available
   * from previous promotions-related operations such as modifying coupons.
   */
  @action private async revalidatePromotions(
    prefetch?: ICartPromotionUpdates
  ): Promise<void> {
    const {
      coupons: acceptedCoupons,
      cartPromotions,
      lineItemPromotions
    } = prefetch ?? (await PromotionsService.getCartPromotions(this.toDTO()));

    // For every key (line item SKU) in the line item promotions map...
    for (const itemSKU of Object.keys(lineItemPromotions)) {
      const lineItem = this._items.find((item) => item.sku === itemSKU);
      // If an item of this cart matches the SKU...
      if (lineItem) {
        // Update it with the promotions in the map.
        lineItem.update({ promotions: lineItemPromotions[itemSKU] });
      }
    }

    // TODO: do we need async filtering here as well?
    this._promotions = cartPromotions;

    // update the coupons on the cart to only be those accepted by the promotions service
    this._coupons = await filterAsync(this._coupons, async (coupon) => {
      const isCouponAccepted = acceptedCoupons.find(
        (acceptedCoupon) =>
          acceptedCoupon.code.toUpperCase() === coupon.code.toUpperCase()
      );

      if (isCouponAccepted) return true;

      await CartService.removeCoupon(coupon.id, this.uuid);
      return false;
    });
  }

  /**
   * Applies all gift cards so that their applied amounts can be known.
   *
   * By default, this method will apply the gift cards in the order they were added,
   * and will apply the full balance of each gift card, until the order total is reached.
   */
  @action private async reapplyGiftCards(): Promise<void> {
    const cartAmountLeft = (await this.total).copy();

    // apply the gift cards in the order they were added
    // and apply the full balance of each gift card,
    // until the order total is reached
    this._giftCards.forEach((card) => {
      const { balance } = card;
      card.resetAppliedAmount();

      if (!cartAmountLeft.isZero && !balance.isZero) {
        const amountToApply = MoneyModel.min(cartAmountLeft, balance);
        card.applyAmount(amountToApply);
        cartAmountLeft.subtractAmount(amountToApply);
      }
    });

    /**
     * Removes unused gift cards.
     * Don't use {@link removeGiftCard this.removeGiftCard} because it calls into this method again.
     */
    this._giftCards = await filterAsync(this._giftCards, async (card) => {
      if (card.amountApplied.isZero) {
        await CartService.removeGiftCard(card.id, this.uuid);
        return false;
      }
      return true;
    });
  }

  /**
   * Adds a line item to the cart, or updates a matching item, and starts cart {@link revalidate revalidation}.
   *
   * @param product - The product to be added to this cart.
   * @param [quantity] - The quantity to add to the product.
   * @returns A `Promise` that resolves to the line item that was added or updated.
   * @throws {@link InvalidArgumentError} If the quantity is less than or equal to 0.
   * @throws {@link LineItemQuantityExceededError} If the quantity added exceeds the maximum allowed per line.
   * @throws {@link CartTotalQuantityExceededError} If the cart quantity exceeds the maximum allowed.
   */
  @action public async addItem(
    product: IProduct,
    quantity: number = 1
  ): Promise<ILineItem> {
    // check if the item is already in the cart
    const item = this._items.find((item) => {
      return product.sku === item.sku;
    });

    if (item) {
      // If the item is already in the cart then increment its quantity.
      await this.updateLineItem(item, item.quantity + quantity);
      return item;
    }

    if (quantity <= 0)
      throw new InvalidArgumentError('Quantity must be greater than 0.');

    if (quantity > this.maxQuantityPerLine) {
      throw new LineItemQuantityExceededError(
        `Product "${product.sku}" quantity exceeds the maximum allowed per line (${this.maxQuantityPerLine}). Received: ${quantity}.`
      );
    }

    if (this.totalItemQuantity + quantity > this.maxQuantityPerCart) {
      throw new CartTotalQuantityExceededError(
        `Cart quantity exceeds the maximum allowed (${this.maxQuantityPerCart}).`
      );
    }

    const lineItem = await this._addItem(product, quantity);
    this.revalidate();

    return lineItem;
  }

  /**
   * Adds a line item to the cart. This method skips argument
   * validation and does not automatically {@link revalidate} the cart.
   *
   * @param product - The product to be added to this cart.
   * @param quantity - The quantity to add to the product.
   * @returns A `Promise` that resolves to the line item that was added.
   * @throws {@link LineItemQuantityExceededError} If the quantity added exceeds the maximum allowed per line.
   * @throws {@link CartQuantityExceededError} If the cart quantity exceeds the maximum allowed.
   */
  @action private async _addItem(
    product: IProduct,
    quantity: number
  ): Promise<ILineItem> {
    const initialProduct: IInitialProduct = {
      pid: product.sku,
      sku: product.sku,
      quantity
    };

    // If not, then add the item to the cart.
    const id = await CartService.addItem(initialProduct, this.uuid);

    const lineItemModel = ProductLineItemModel.fromProduct(
      product,
      id,
      this.uuid,
      quantity
    );
    this._items.push(lineItemModel);

    return lineItemModel;
  }

  /**
   * Updates a line item's quantity in this cart and starts cart {@link revalidate revalidation}.
   *
   * @param lineItem - The `ILineItem` representation or line item UUID to be updated.
   * @param quantity - The new quantity.
   * @throws {@link InvalidArgumentError} If the quantity is less than 0.
   * @throws {@link LineItemNotFoundError} If the line item is not found in the cart.
   * @throws {@link LineItemQuantityExceededError} If the quantity exceeds the maximum allowed per line.
   * @throws {@link CartTotalQuantityExceededError} If the cart quantity exceeds the maximum allowed.
   */
  @action public async updateLineItem(
    lineItem: ILineItem | string,
    quantity: number
  ): Promise<void> {
    if (quantity < 0)
      throw new InvalidArgumentError('Quantity must be nonnegative.');

    const uuid = typeof lineItem === 'string' ? lineItem : lineItem.uuid;

    if (quantity > this.maxQuantityPerLine) {
      throw new LineItemQuantityExceededError(
        `Line item "${uuid}" quantity exceeds the maximum allowed per line (${this.maxQuantityPerLine}). Received: ${quantity}.`
      );
    }

    const item = this._items.find((item) => {
      return item.uuid === uuid;
    });

    if (!item) {
      throw new LineItemNotFoundError(
        `Cart tried to update line item "${uuid}" but it was not found in the cart.`
      );
    }

    const quantityDiff = quantity - item.quantity;

    if (quantityDiff === 0) {
      // If the quantity is the same as the current quantity, then don't do anything.
      return;
    }

    if (this.totalItemQuantity + quantityDiff > this.maxQuantityPerCart) {
      throw new CartTotalQuantityExceededError(
        `Cart quantity exceeds the maximum allowed (${this.maxQuantityPerCart}).`
      );
    }

    if (quantity === 0) {
      await this._removeItem(uuid);
    } else {
      await this._updateLineItem(item, quantity);
    }

    this.revalidate();
  }

  /**
   * Updates a line item's quantity in this cart. This method skips argument
   * validation and does not automatically {@link revalidate} the cart.
   *
   * @param lineItem - A _reference_ to a line item in the cart {@link items}.
   * @param quantity - The new quantity.
   * @throws {@link LineItemNotFoundError} If the line item is not found in the cart.
   */
  @action private async _updateLineItem(
    lineItem: ILineItem,
    quantity: number
  ): Promise<void> {
    // Actually make the change to the cart on the server.
    await CartService.updateItem(lineItem.uuid, quantity, this.uuid);
    // updates the line quantity locally.
    lineItem.quantity = quantity;
  }

  /**
   * Deletes a line item in this cart and starts cart {@link revalidate revalidation}.
   *
   * @param lineItem - The `ILineItem` representation or line item UUID to be deleted.
   */
  @action public async removeItem(lineItem: ILineItem | string): Promise<void> {
    const uuid = typeof lineItem === 'string' ? lineItem : lineItem.uuid;
    const didRemoveItem = await this._removeItem(uuid);
    if (didRemoveItem) this.revalidate();
  }

  /**
   * Deletes a line item in this cart. This method skips argument
   * validation and does not automatically {@link revalidate} the cart.
   *
   * @param lineItemUUID - The UUID of the line item to be removed.
   * @returns A `Promise` that resolves to a boolean indicating whether the item was removed.
   */
  @action private async _removeItem(lineItemUUID: string): Promise<boolean> {
    await CartService.removeItem(lineItemUUID, this.uuid);

    const updatedItems = this._items.filter((item) => {
      return item.uuid !== lineItemUUID;
    });

    if (updatedItems.length !== this._items.length) {
      this._items = updatedItems;
      return true;
    }
    return false;
  }

  /**
   * Replaces a line item in the cart with a different product (with the same quantity)
   * and starts cart {@link revalidate revalidation}. This method also ensures that the new line item
   * takes the same position in the cart as the previous line item.
   *
   * @param previousLineItem - The {@link ILineItem} or line item UUID to be replaced.
   * @param newProduct - The new product to be added to the cart.
   * @throws {@link LineItemNotFoundError} If the previous line item is not found in the cart.
   */
  @action public async replaceLineItem(
    previousLineItem: ILineItem | string,
    newProduct: IProduct
  ): Promise<void> {
    const previousLineItemUUID =
      typeof previousLineItem === 'string'
        ? previousLineItem
        : previousLineItem.uuid;

    const previousItem = this._items.find((item) => {
      return item.uuid === previousLineItemUUID;
    });

    if (!previousItem) {
      throw new LineItemNotFoundError(
        `Cart tried to replace line item "${previousLineItemUUID}" but it was not found in the cart.`
      );
    }

    if (previousItem.sku === newProduct.sku) {
      // if the new product is the same as the previous product,
      // then there is no need to replace the line item.
      return;
    }

    // check if the new product is already in the cart.
    const maybeExistingItem = this._items.find((item) => {
      return item.sku === newProduct.sku;
    });

    if (maybeExistingItem) {
      // if the new product is already in the cart, then don't do anything.
      // TODO: in the future, we may want to display a warning to the user
      // that the product is already in the cart, or perhaps even perform some
      // kind of merging logic.
      return;
    }

    // otherwise, replace the line item with the new product.
    await this._replaceLineItemByAdd(previousItem, newProduct);

    this.revalidate();
  }

  /**
   * Replaces a line item in the cart with an entirely new product (with the same quantity).
   * This method also ensures that the new line item takes the same position in
   * the cart as the previous line item. This method skips argument validation
   * and does not automatically {@link revalidate} the cart.
   *
   * @param previousLineItem - A _reference_ to a line item in the cart {@link items}.
   * @param newProduct - The new product to be added to the cart.
   */
  @action private async _replaceLineItemByAdd(
    previousLineItem: ILineItem,
    newProduct: IProduct
  ): Promise<void> {
    // To ensure the UI updates properly and doesn't flash in-between states, rather
    // than re - using existing methods, we re - implement the logic here as "one" step.

    // Note: there is no reason to implement cart quantity checks here, as this
    // operation is effectively a swap of one product for another, and does
    // not affect any quantities.

    const { quantity, uuid } = previousLineItem;

    // First, create the initial product representation for the new product.
    const initialProduct: IInitialProduct = {
      pid: newProduct.sku,
      sku: newProduct.sku,
      quantity
    };

    // Next, update the cart on the server.
    const newUUID = await CartService.replaceItem(
      uuid,
      initialProduct,
      this.uuid
    );

    // Then, find the index of the previous line item in the cart.
    const previousLineItemIndex = this._items.findIndex(
      (item) => item.uuid === uuid
    );

    // Finally, update the line item locally in one step.
    this._items.splice(
      previousLineItemIndex,
      1,
      ProductLineItemModel.fromProduct(newProduct, newUUID, this.uuid, quantity)
    );
  }

  /**
   * Apply a coupon code to the cart.
   * @param code - Coupon code to apply.
   * @returns A promise that resolves on success.
   * @throws An Error if the supplied coupon code is not applicable.
   */
  @action public async applyCoupon(code: string): Promise<ICoupon> {
    // normalize the code to uppercase.
    code = code.toUpperCase();

    /**
     * This following call will throw an error if the coupon is not applicable.
     */
    const updates = await PromotionsService.applyCoupon(this, code);

    const id = await CartService.addCoupon(code, this.uuid);
    const coupon = { id, code };
    this._coupons.push(coupon);

    this.revalidate({ promotionUpdates: updates });

    return coupon;
  }

  /**
   * Remove a coupon code from the cart.
   * @param couponID - Coupon id to remove.
   * @returns A promise that resolves on success.
   */
  @action public async removeCoupon(couponID: string): Promise<void> {
    await CartService.removeCoupon(couponID, this.uuid);

    const couponToRemove = this._coupons.find((coupon) => {
      return couponID === coupon.id;
    });

    if (couponToRemove) {
      this._coupons = this._coupons.filter((coupon) => {
        return coupon.id !== couponID;
      });

      const updates = await PromotionsService.removeCoupon(
        this,
        couponToRemove.code
      );

      this.revalidate({ promotionUpdates: updates });
    }
  }

  /**
   * The total gift card amount **applied**. This is a **POSITIVE** value.
   * Multiply this by `-1` to get the difference on the cart total.
   * @returns The total gift card amount applied.
   */
  public get giftCardAmount(): MoneyModel {
    const giftCardAppliedAmounts = this._giftCards.map(
      (card) => card.amountApplied
    );

    return MoneyModel.add(0, ...giftCardAppliedAmounts);
  }

  /**
   * Adds a gift card to the carts gift card array.
   * @param giftCard - The gift card you want to add to the cart.
   */
  @action public async addGiftCard(giftCard: IGiftCardBase): Promise<void> {
    const isCardAlreadyAdded = this._giftCards.find((card) => {
      return card.giftCardNumber === giftCard.giftCardNumber;
    });

    if (isCardAlreadyAdded) {
      throw new GiftCardAlreadyAppliedError(
        `The gift card with the number ${giftCard.giftCardNumber} is already added to this cart.`
      );
    }

    const id = await CartService.addGiftCard(giftCard, this.uuid);

    const { giftCardNumber, cvd, balance } = giftCard;

    const giftCardModel = GiftCardModel.from({
      id,
      giftCardNumber,
      cvd,
      balance: MoneyModel.fromAmount(balance),
      amountApplied: MoneyModel.fromAmount(0)
    });
    this._giftCards = [...this._giftCards, giftCardModel];

    // since we are only changing the gift cards, we don't need to revalidate the whole cart
    await this.reapplyGiftCards();
  }

  /**
   * Removes a gift card from the giftCards array by its number.
   * @param giftCardID - The gift card id of the card to remove.
   */
  @action public async removeGiftCard(giftCardID: string): Promise<void> {
    await CartService.removeGiftCard(giftCardID, this.uuid);

    const updatedGiftCards = this.giftCards.filter((card) => {
      return card.id !== giftCardID;
    });

    // only update the gift cards if the card was actually removed
    if (updatedGiftCards.length !== this.giftCards.length) {
      this._giftCards = updatedGiftCards;

      // since we are only changing the gift cards,
      // we don't need to revalidate the whole cart
      await this.reapplyGiftCards();
    }
  }

  /**
   * Much like when a CartModel is first created, it is best practice to call
   * {@link CartModel.revalidate} after this method to recalculate various values.
   * @inheritdoc
   */
  public override update(dto: WithRequired<Partial<DTO<ICart>>, 'uuid'>): void {
    if (dto.uuid !== this.uuid) {
      throw new InvalidArgumentError(`Cannot update cart with different UUID.`);
    }

    if (dto.ownerID !== undefined && dto.ownerID !== this.ownerID) {
      throw new InvalidArgumentError(
        `Cannot update cart with different owner ID.`
      );
    }

    if (dto.coupons) {
      this._coupons = [...dto.coupons];
    }

    if (dto.giftCards) {
      this._giftCards = dto.giftCards.map((i) => GiftCardModel.from(i));
    }

    if (dto.items) {
      this._items = dto.items
        .map((i) => LineItemModel.from(i))
        // sort to match previous order, adding any new items to the end
        .sort((itemA, itemB) => {
          const itemAIdx = this._items.findIndex(
            (item) => item.uuid === itemA.uuid
          );
          const itemBIdx = this._items.findIndex(
            (item) => item.uuid === itemB.uuid
          );

          // If one of the items is not found in the previous array, move it to the end
          if (itemAIdx === -1) return 1;
          if (itemBIdx === -1) return -1;

          return itemAIdx - itemBIdx;
        });
    }

    if (dto.promotions) {
      this._promotions = [...dto.promotions];
    }

    if (dto.linesDiscount) {
      this._linesDiscount = new StaleWhileRevalidate<MoneyModel>(
        MoneyModel.from(dto.linesDiscount)
      );
    }

    if (dto.cartDiscount) {
      this._cartDiscount = new StaleWhileRevalidate<MoneyModel>(
        MoneyModel.from(dto.cartDiscount)
      );
    }

    if (dto.discount) {
      this._discount = new StaleWhileRevalidate<MoneyModel>(
        MoneyModel.from(dto.discount)
      );
    }

    if (dto.tax) {
      this._tax = new StaleWhileRevalidate<MoneyModel>(
        MoneyModel.from(dto.tax)
      );
    }

    if (dto.total) {
      this._total = new StaleWhileRevalidate<MoneyModel>(
        MoneyModel.from(dto.total)
      );
    }

    if (dto.shippingCost) {
      this._shippingCost = new StaleWhileRevalidate<MoneyModel>(
        MoneyModel.from(dto.shippingCost)
      );
    }

    if (dto.shipToAddress) {
      this._shipToAddress = dto.shipToAddress;
    }

    if (dto.selectedShippingMethod) {
      this._selectedShippingMethod = ShippingMethodModel.from(
        dto.selectedShippingMethod
      );
    }
  }

  /**
   * Selects a shipping method and revalidates the cart.
   *
   * @param shippingMethod - The shipping method to select. Should be either a
   * {@link ShippingMethodModel} or a shipping method UID.
   *
   * @throws An {@link InvalidArgumentError} if the provided shipping method is
   * not applicable to this cart.
   */
  @action public async selectShippingMethod(
    shippingMethod: ShippingMethodModel | string
  ): Promise<void> {
    const methodID =
      typeof shippingMethod === 'string' ? shippingMethod : shippingMethod.uid;

    const applicableMethods = await this._applicableShippingMethods;
    const selectedMethod = applicableMethods.find(
      (method) => method.uid === methodID
    );

    if (!selectedMethod) {
      throw new InvalidArgumentError(
        `Cannot select shipping method ${methodID}:` +
          ' \nThe method is not applicable to the current cart.'
      );
    }

    this._selectedShippingMethod = selectedMethod;

    await this.revalidate();
  }

  /**
   * Determines the best applicable shipping method for this cart and
   * selects it. This does not revalidate the cart.
   */
  @action private async _selectBestShippingMethod(): Promise<void> {
    const bestShippingMethod = ShippingMethodService.getBestShippingMethod(
      await this._applicableShippingMethods
    );

    // since this result comes from the service, we can skip validation
    // and set the shipping method directly
    this._selectedShippingMethod = bestShippingMethod;
  }

  /**
   * Updates the applicable shipping methods and selects the best one available
   * if either there is no selected method, or the previously selected method is
   * no longer applicable.
   */
  @action private async _revalidateApplicableShippingMethods(): Promise<void> {
    this._applicableShippingMethods = new StaleWhileRevalidate(
      this._applicableShippingMethods.value,
      async () => {
        try {
          const applicableMethods =
            await ShippingMethodService.getApplicableShippingMethodsForCart(
              this
            );

          return applicableMethods;
        } catch (error) {
          if (error instanceof CannotShipToPOBoxError) {
            // Do not throw, but return an empty methods array if shipping to
            // PO Boxes is disabled and the user entered one as their shipping
            // address.
            return [];
          }

          throw error;
        }
      }
    );

    const applicableMethods = await this._applicableShippingMethods;
    const selectedMethod = this.selectedShippingMethod;

    if (
      // If there's no selected shipping method...
      !selectedMethod ||
      // OR there IS a selected method but it is not
      // applicable (not in the applicable methods list)...
      !applicableMethods.find((method) => method.id === selectedMethod.id)
    ) {
      // Select the best shipping method.
      await this._selectBestShippingMethod();
    }
  }

  /**
   * Sets the address the cart will be shipped to if checked out, and
   * revalidates the cart. Useful for calculating taxes and determining
   * available shipping methods.
   *
   * @param address - The address to set.
   */
  @action public async setShipToAddress(address: IAddress): Promise<void> {
    this._shipToAddress = address;

    await this._revalidateApplicableShippingMethods();

    // we must always revalidate the cart after setting the ship-to address
    // because it affects the tax calculation.
    // we explicitly await this because the address may cause revalidation
    // to fail if it is not valid.
    await this.revalidate();
  }

  /** @inheritDoc  */
  public toDTO(): DTO<ICart> {
    return {
      uuid: this.uuid,
      ownerID: this.ownerID,
      items: this.items.map((i) => i.toDTO()),
      promotions: [...this.promotions],
      coupons: [...this.coupons],
      giftCards: this.giftCards.map((i) => i.toDTO()),

      tax: this.tax.value.toDTO(),
      linesDiscount: this.linesDiscount.value.toDTO(),
      cartDiscount: this.cartDiscount.value.toDTO(),
      discount: this.discount.value.toDTO(),
      subtotal: this.subtotal.toDTO(),
      total: this.total.value.toDTO(),

      shipToAddress: this.shipToAddress,
      selectedShippingMethod: this.selectedShippingMethod?.toDTO(),
      shippingCost: this.shippingCost.value.toDTO()
    };
  }
}
