import { Language } from './Language';
import { Locale } from './Locale';
import { Region } from './Region';
import { Site } from './Site';

/**
 * This object represents all valid locales for each region. The purpose
 * of this object is both to support type checking that only valid region and locale
 * combinations are used in the application, and to provide more useful Intellisense in
 * TypeScript supported IDEs.
 */
export const regionMappings = {
  NA: [Locale.US, Locale.CA],
  EMEA: [Locale.GB],
  APAC: [],
  LATAM: []
} as const satisfies RegionMappings;

/**
 * This object represents all valid languages for each locale we support. The purpose
 * of this object is both to support type checking that only valid language and locale
 * combinations are used in the application, and to provide more useful Intellisense in
 * TypeScript supported IDEs.
 */
export const localeMappings = {
  US: {
    languages: [Language.EN]
  },

  GB: {
    languages: [Language.EN]
  },

  CA: {
    languages: [Language.EN, Language.FR]
  },

  UK: {
    aliasFor: Locale.GB
  },

  DE: {
    languages: [Language.DE]
  },

  FR: {
    languages: [Language.FR]
  },

  IT: {
    languages: [Language.IT]
  },

  JP: {
    languages: [Language.JP]
  },

  NL: {
    languages: [Language.NL]
  }
} as const satisfies LocaleMappings;

/**
 * This object represents all valid locales for each of our brands. The purpose
 * of this object is both to support type checking that only valid brand and locale
 * combinations are used in the application, and to provide more useful Intellisense in
 * TypeScript supported IDEs.
 */
export const siteMappings = {
  SANUK: [Locale.US, Locale.GB],
  UGG: [Locale.US, Locale.CA, Locale.UK],
  'UGG-COLLAB': [Locale.US],
  TEVA: [Locale.US],
  HOKA: [Locale.US, Locale.CA, Locale.UK],
  KOOLABURRA: [Locale.US],
  AHNU: [Locale.US],
  DECKERSX: [Locale.US],
  CONDUCTOR: [Locale.US]
} as const satisfies SiteMappings;

/**
 * Represents all valid IETF language tags for our sites in the format
 * of `${Language}-${Locale}`.
 * @see https://en.wikipedia.org/wiki/IETF_language_tag
 * @example 'en-US'
 */
export type LangLocale = LangLocaleForBrand<Site>;

/**
 * Represents a IETF language tag as an object.
 * @see https://en.wikipedia.org/wiki/IETF_language_tag
 */
export interface ILangLocale {
  /**
   * Represents a ISO 639-1 language identifier.
   * @example 'en'
   */
  readonly language: Language;

  /**
   * Represent a ISO 3166-1 alpha-2 country code identifier.
   * @example 'US'
   */
  readonly locale: Locale;

  /**
   * A `.toString()` implementation for the locale object to convert to a
   * `LangLocale` string.
   * @see {@link LangLocale}
   * @returns The locale object as a string.
   * @example 'en-US'
   */
  toString(): LangLocale;
}

/**
 * Represents the schema to which the narrower type literal `regionMappings` must adhere.
 * @see {@link regionMappings}
 */
type RegionMappings = {
  readonly [key in Region]: ReadonlyArray<Locale>;
};

/**
 * Represents the schema to which the narrower type literal `localeMappings` must adhere.
 * @see {@link localeMappings}
 */
type LocaleMappings = {
  // Keys must be valid, known locale strings.
  readonly [key in Locale]:  // Values must either be an object representing a locale or an alias thereof.
    | {
        readonly languages: ReadonlyArray<Language>;
      }
    | { readonly aliasFor: keyof LocaleMappings };
};

/**
 * Represents the schema to which the narrower type literal `siteMappings` must adhere.
 * @see {@link siteMappings}
 */
type SiteMappings = {
  // Keys must be a be valid, known brand. Values must be a tuple of valid locales for that brand.
  readonly [key in Site]: ReadonlyArray<Locale>;
};

/**
 * Utility type to get a tuple of valid language strings for a given locale.
 * Tuple will be a conceptual array of valid ISO 639-1 language identifiers.
 * @see {@link localeMappings}
 */
export type AllowedLanguagesForLocale<
  // Represents an object for any given key in the `localeMappings` type literal.
  Locale extends Mappings[keyof Mappings],
  // Represents an alias for the `localeMappings` type literal.
  Mappings = typeof localeMappings
> =
  // If the `Locale` represents an alias for another locale, resolve that locale.
  Locale extends { aliasFor: any }
    ? ResolveAliasForLocale<Locale, Mappings>
    : // Otherwise, get the languages for this locale.
      Locale extends { languages: ReadonlyArray<Language> }
      ? Locale['languages']
      : never;

/**
 * Utility type to resolve to the actual underlying locale for a given locale alias. For
 * example mapping `UK` to `GB` (United Kingdom to Great Britain).
 */
export type ResolveAliasForLocale<
  // Represents an object for any given key in the `localeMappings` type literal.
  Locale extends { aliasFor: keyof Mappings },
  // Represents an alias for the `localeMappings` type literal.
  Mappings = typeof localeMappings
> = Mappings[Locale['aliasFor']] extends {
  languages: ReadonlyArray<Language>;
}
  ? Mappings[Locale['aliasFor']]['languages']
  : never;

/**
 * Utility type to get a tuple of valid locale strings for a given brand.
 * Tuple will be a conceptual array of valid ISO 3166-1 alpha-2 country code
 * identifiers.
 * @see {@link siteMappings}
 */
export type AllowedLocalesForBrand<
  // Represents a key in the `siteMappings` type literal.
  Site extends keyof Mappings,
  // Represents an alias for the `siteMappings` type literal.
  Mappings = typeof siteMappings
> = Mappings[Site];

/**
 * Represents the valid IETF language tags for a given brand in the format
 * of `${Language}-${Locale}`.
 * @see {@link siteMappings}
 * @see {@link localeMappings}
 * @see https://en.wikipedia.org/wiki/IETF_language_tag
 */
export type LangLocaleForBrand<
  // Represents a key in the `siteMappings` type literal.
  Site extends keyof typeof siteMappings,
  // Represents a key in the `localeMappings` type literal.
  LocaleMappings = typeof localeMappings,
  // Represents all of the valid locales for a brand as specified in `siteMappings`.
  Locales extends ReadonlyArray<
    keyof LocaleMappings
    // If the valid locales for the given brand are valid keys of `localeMappings`, use those.
  > = AllowedLocalesForBrand<Site> extends ReadonlyArray<keyof LocaleMappings>
    ? AllowedLocalesForBrand<Site>
    : never
  // For the valid locales for the given brand, get the lang locale strings.
> = `${LangLocaleForLocale<Locales, LocaleMappings>}`;

/**
 * Utility type to get a union of valid lang locale strings for a given locale.
 * Strings will be union of a IETF language tags in the format of `${Language}-${Locale}`.
 * @see {@link localeMappings}
 * @see https://en.wikipedia.org/wiki/IETF_language_tag
 */
export type LangLocaleForLocale<
  // Represents a tuple type of keys of the `localeMappings` type literal.
  Locale extends ReadonlyArray<keyof LocaleMappings>,
  // Represents an alias for the `localeMappings` type literal.
  LocaleMappings = typeof localeMappings
> = {
  // For every index position in the `Locale` tuple (a constant array of keys
  // of the `localeMappings` type literal), produce a lang locale string. Prove that the
  // indexes are numbers for this type mapping (For instance, don't count `.length`).
  [Index in keyof Locale]: Locale[Index] extends Locale[number]
    ? // Get the allowed languages for the locale key at this index.
      `${`${AllowedLanguagesForLocale<
        LocaleMappings[Locale[Index]],
        LocaleMappings
        // Resolve the locale string given the locale key at this index.
      >[number]}-${LocaleForLocale<Locale[Index], LocaleMappings>}`}`
    : never;
}[number];

/**
 * Utility type which will resolve a locale string from a key of the type literal `localeMappings`.
 * @see {@link localeMappings}
 * @see https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
 */
export type LocaleForLocale<
  // Represents a tuple type of keys of the `localeMappings` type literal.
  Locale extends keyof LocaleMappings,
  // Represents an alias for the `localeMappings` type literal.
  LocaleMappings = typeof localeMappings
> = `${`${Locale extends string
  ? // If `Locale` is a string and is the key to an object that has a `languages` property, output
    // this `Locale`.
    LocaleMappings[Locale] extends {
      languages: ReadonlyArray<Language>;
    }
    ? Locale
    : // Else if, `Locale` is a string and is the key to an object that has an `aliasFor` property,
      // use that alias key instead of the `Locale`.
      LocaleMappings[Locale] extends {
          aliasFor: any;
        }
      ? `${LocaleMappings[Locale]['aliasFor']}`
      : never
  : never}`}`;

/**
 * A type that represents a combination of a brand and
 * a locale in the format of `${Site}-${Locale}`.
 */
export type SiteLocale = `${Site}-${Locale}`;
